import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import moment from 'moment';

import {
    IconButton
  } from "@mui/material";
import { GetDepartmentService } from '../../services/DepartmentServices';
import { Select } from 'antd';
import { DatePicker } from 'antd'
import DashboardMenu from './DashboardMenu';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';

import { GetAllFidelitusLeadsBasedDateService,  } from '../../services/AssignLeadServices';

import { BiFilterAlt } from "react-icons/bi";
import { AiOutlineEdit } from 'react-icons/ai';
import { RxReset } from "react-icons/rx";
import { GetSearchService } from '../../services/AuthServices';
import { useSelector } from 'react-redux';

function FidelitusVerticalLeads() {

  const location = useLocation();
  const roles = useSelector(state=>state.Auth.roles)

  console.log("roles",roles)


  const navigate = useNavigate();

  const [leads,setleads]  = useState([])


 

  const [selected_department,setselected_department] = useState(null)
  const [created_department,setcreated_department] = useState(null)
  const [selected_user,setselected_user] = useState(null)




  const [departments,setdepartments] = useState([])
  const [users,setusers] = useState([])
  const [page,setpage] = useState(1)


  const [filter_drawer,setfilter_drawer] = useState(false)

  const [search,setsearch] = useState({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false,zone:''})




 
  
  useEffect(()=>{
    getleads(1)
  },[location.pathname,filter_drawer])

  useEffect(()=>{
    if(search?.activate){
      applyfilterfunction(page)
    }else{
      getleads(page)
    }  
  },[page,selected_user,created_department,selected_department])

  useEffect(()=>{
      getdepartment()
  },[])

  async function getdepartment(){
      const response = await GetDepartmentService();
      let arr = []
      response?.data?.datas.forEach((d)=>{
          if(d?.department_name !== 'Finance Team'){
              arr.push({label:d?.department_name,value:d?.id})
          }
      })
      setdepartments(arr)
  }


  async function applyfilterfunction(page){
      setsearch({...search,activate:true})
      const response = await GetAllFidelitusLeadsBasedDateService(
        selected_department?.value !== undefined ? selected_department?.value : null,
        selected_user?.value !== undefined ? selected_user?.value : null,
        created_department?.value !== undefined ? created_department?.value : null,
        search?.text,
        search?.from_date1,
        search?.to_date1,
        page
      )
      setleads(response.data)   
  }

  async function getleads(page){
      const response =  await GetAllFidelitusLeadsBasedDateService(
        selected_department?.value !== undefined ? selected_department?.value : null,
        selected_user?.value !== undefined ? selected_user?.value : null,
        created_department?.value !== undefined ? created_department?.value : null,
        search?.text,
        search?.from_date1,
        search?.to_date1,
        page
      );
      setleads(response?.data)
  }

  async function handlechange(val,type){

  
      if(type === 'department'){
            let department = departments.find(d=>d.value === val)
            if(selected_department !== null && selected_department?.value !== undefined ){
                setpage(1)
                setselected_department(department)
            }else{
                setpage(1)
                setselected_department(department)
            }
      }else if(type === 'department1'){
          let department = departments.find(d=>d.value === val)
          if(created_department !== null && created_department?.value !== undefined ){
              setpage(1)
              setcreated_department(department)
          }else{
              setpage(1)
              setcreated_department(department)
          }
      }else if(type === 'user'){
          setpage(1)
          setselected_user(users.find(d=>d.value === val))
      }
  }

 async function resetfilter(){
        setselected_user(null)
        setselected_department(null)
        setcreated_department(null)
        setselected_user(null)
        setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false,zone:''})
        setfilter_drawer(false)
        setpage(1)

        const response = await GetAllFidelitusLeadsBasedDateService(
          null,
          null,
          null,
          '',
          '',
          '',
          1
        )
        setleads(response.data)   
  }


  const options = [{label:'North',value:'North'},{label:'South',value:'South'},{label:'East',value:'East'},{label:'West',value:'West'}]


  console.log("leads",leads)

  async function searchUser(v){
    const response =  await GetSearchService(v,1)
          let arr = []
          let d = response?.data?.datas
          d.forEach((d1)=>{
            arr.push({label:d1.name,value:d1?._id})
          })
          setusers(arr)
          return arr
  }




  return (
    <div style={{width:'100%',minHeight:'100vh',maxHeight:'100vh',height:'100%',overflow:'hidden',position:'relative'}}>

  

  
     
   
    
    
    <div > 
        <div className='flex'>
        <div className='w-44 border-r'>
        <DashboardMenu />
        </div>  
        <div className='px-3 w-[87%] pt-3'>
        <div  >
          <div >
            <div className='flex flex-col  border-b pb-2 mb-5'>

          <h6 className='font-[700] text-[14px] '>Fidelitus Vertical Master Leads ({leads?.pagination?.total})</h6>


          
          <div className='flex items-center mt-2' >
            <div className='flex items-center  text-[12px] mr-2'>
                <h6 className='mr-2 font-[600]'>{page === 1 ? leads?.datas?.length > 0 ? 1 : 0 : (page - 1) * leads?.pagination?.limit } - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
                <IconButton  onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < leads?.pagination?.totalPages  ? setpage(page+1) : console.log('')}} ><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                </div>
            </div>

            
                <DatePicker allowClear={true} onClear={()=>setsearch({...search,from_date:'',from_date1:''})} size='small'  ampm={false} placeholder='From Date' className='text-[11px] py-[4px]   w-28 border-slate-300 mr-1'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 

                <DatePicker allowClear={true} onClear={()=>setsearch({...search,to_date:'',to_date1:''})} size='small'  ampm={false} placeholder='To Date' className='text-[11px] py-[4px]  w-28 border-slate-300 mr-1'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 
                <input id="search_text" type='text' value={search.text} placeholder='Search text' onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[4px] focus:ring-0 mr-1 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />


            <div className='border border-slate-300 rounded-md py-[2px] mr-1'>
            <Select
                placeholder='Create Department'
                bordered={false}
                size='small'
                allowClear={true}
                onClear={()=>setcreated_department(null)}
                defaultValue={created_department}
                value={created_department}
                style={{ width: 110 }}
                onChange={(v)=>handlechange(v,'department1')}
                options={departments}
            />
          </div>
          

            <div className='border border-slate-300  mr-1 rounded-md py-[2px]'>
            <Select
                placeholder='Select User'
                bordered={false}
                size='small'
                filterOption={false}
                showSearch={true}
                allowClear={true}
                onClear={()=>setselected_user(null)}
                defaultValue={selected_user}
                value={selected_user}
                style={{ width: 110 }}
                onSearch={(e)=>searchUser(e,'user')}
                options={users}
                onChange={(v)=>handlechange(v,'user')}
            />
          </div>

          <div className='border border-slate-300 rounded-md py-[2px]'>
            <Select
                placeholder='Assigned Department'
                bordered={false}
                allowClear={true}
                onClear={()=>setselected_department(null)}
                size='small'
                defaultValue={selected_department}
                value={selected_department}
                style={{ width: 110 }}
                onChange={(v)=>handlechange(v,'department')}
                options={departments}
            />
          </div>

       
        

           

          
            <div className='ml-1 mr-1'>
            <BiFilterAlt  onClick={()=>applyfilterfunction(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
            </div> 

            <RxReset  onClick={resetfilter} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />

            </div>
            </div>

          <>
          {leads?.datas?.length > 0 && 
          <div className='max-h-[85vh] border-t border-l border-r overflow-y-scroll'>
          <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
              <h6 className='sticky top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Contact Person Info</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Created Department</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Assigned Department</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600  border-r'>Remarks</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600  border-r'>Stage</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Status</h6>
          </div>
        
          {leads?.datas?.map((d,i)=>(
          <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
              <h6 className='text-[12px] font-[500] w-[5%]  px-2 py-1  border-r border-slate-200 '>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
              <h6 className='text-[12px] font-[500] w-[15%] px-2 py-1 font-[800] border-r border-slate-200'>{d?.fidelitus_lead?.company_name}
              {d?.zone !== undefined && <h6 className='text-[10px]'>Zone : {d?.fidelitus_lead?.zone}</h6>}</h6>
              <h6 className='text-[12px] font-[500] w-[15%] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
                <div>
                <h6>{d?.fidelitus_lead?.contact_name}</h6>  
                <span>{d?.fidelitus_lead?.phone} / {d?.fidelitus_lead?.email}  </span>
                </div>
              </h6>
              <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
                {d?.fidelitus_lead?.department_id?.department_name}
                <h6 className='text-[10px]'>Created By : {d?.fidelitus_lead?.lead_owner?.name}</h6>
                <h6 className='text-[10px] font-[500]'>{d?.fidelitus_lead?.previously_handled_by}</h6>
              </h6>
              <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
              {d?.department_id?.department_name}
              </h6>
              <h6 className='text-[11px]  w-[15%]  px-2 py-1  border-r border-slate-200'>
                 Requirment :  <span className='font-[800]'>{d?.requirment}</span>  /
                 Remarks :  <span className='font-[800]'>{d?.remarks}</span>

                 {d?.converted == '1' && <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.lead_id?._id})} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>}

              </h6>   
              <h6 className='text-[12px]  flex items-start break-word  font-[500] w-[10%] border-r px-2 py-1 border-slate-200 '>
                {d?.lead_id?._id !== undefined && <span className='bg-slate-100 px-2 py-1 rounded'>{d?.lead_id?.stage?.name}</span>}
              </h6>

             
              <h6 className='text-[12px]   font-[500] w-[10%]  px-2 py-1 truncate border-slate-200 '>
                 {d?.converted == '3' && <span className='bg-blue-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-blue-600 font-[700]'>Pending</span>}
                 {d?.converted == '2' && <span className='bg-red-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-red-600 font-[700]'>Rejected</span>}
                 {d?.converted == '1' && <span className='bg-green-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-green-600 font-[700]'>Converted</span>}
               {roles?.includes('admin') &&
               <AiOutlineEdit className='mt-1 ml-1' onClick={()=>navigate('/assign_leads_remark/list/edit',{state:d})} />}
              </h6>
          </div>))}
          </div>}
          </>

       
          {leads?.datas?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
            <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' /> 
            <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
            <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
          </div>
          }


          </div>

        


        </div>

        
      
        </div>
        </div>
    </div>
    </div>
  )
}

export default FidelitusVerticalLeads