import React, { useEffect, useState } from 'react'
import FinanceMenu from './FinanceMenu'
import { GetFinanceDashboardService } from '../../services/FinanceServices'
import {FcMoneyTransfer} from 'react-icons/fc';
import {RiBillLine} from 'react-icons/ri';
import ReactApexChart from 'react-apexcharts';


function FinanceDashboardMain() {

  const [data,setdata] = useState({})
  // const [series,setseries] = useState([])

  const chartOptions = {
    plotOptions: {
      color:['#000','#000'],
      size:'40%',
      radialBar: {
          inverseOrder: false,
          startAngle: 0,
          endAngle: 360,
          offsetX: 0,
          offsetY: 0,
          hollow: {
              margin: 5,
              size: '50%',
              color: '#f2f2f2',
              image: undefined,
              imageWidth: 150,
              imageHeight: 150,
              imageOffsetX: 0,
              imageOffsetY: 0,
              imageClipped: true,
              position: 'front',
             
          },
          track: {
              show: true,
              startAngle: undefined,
              endAngle: undefined,
              background: '#f2f2f2',
              strokeWidth: '50%',
              opacity: 1,
              margin: 5, 
              dropShadow: {
                  enabled: false,
                  top: 0,
                  left: 0,
                  opacity: 1
              }
          },
          dataLabels: {
              show: true,
              name: {
                  show: true,
                  fontSize: '12px',
                  fontFamily: undefined,
                  fontWeight: 400,
                  color: undefined,
                  color:'#000',
                },
                value: {
                  show: true,
                  fontSize: '14px',
                  fontFamily: undefined,
                  fontWeight: 900,
                  color: undefined,
                  offsetY: 1,
                  formatter: function (val) {
                    return val + '%'
                  }
                },
                
          },
          
      },
    },
    labels: ['Amount Collected'],
    fill:{
      colors: ['#008ef2'],
    }
  }

  const [chartOptions1,setchartOptions1] = useState({
  series:[],
  chart: {
    toolbar: {
      show: false
    }
  },
    plotOptions: {
      
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        
      },
    },
    dataLabels: {
      enabled: false
    },
    grid:{
      show: true,
      borderColor: '#fafafa',
    },
  
    stroke: {
      width: 1,
      
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Invoice Pending', 'Invoice Revise', 'Invoice Raised', 'Approval Pending ', 'Payment Deleted'],
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          console.log("val",val)
          return  val
        }
      }
    },
    fill:['#027cd1']
  })



  useEffect(()=>{
    getData()
  },[])

  async function getData(){
     const response = await GetFinanceDashboardService()
     let d = response?.data?.datas
     setdata(response?.data?.datas)
     let form = [
      {
        name:'count',
        data:[d?.invoice_pending,d?.invoice_revise,d?.invoice_raised,d?.payment_approval_pending,d?.payment_deleted]
      }]  
     setchartOptions1({...chartOptions1,series:[...form]})
  }

  let rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });



  return (
    <div>
      <div className='flex'>
        <FinanceMenu />
        <div className='mx-4 mt-5'>
           <div className='grid grid-cols-5 items-center justify-evenly border-t border-b border-l border-r border-slate-100'>
                <div className='border-r  border-slate-100 px-2 py-2 w-full'>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiBillLine size={23} className='mr-2 bg-slate-100 p-1 text-slate-800' /> Invoice Pending</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.invoice_pending))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r  border-slate-100 px-2 py-2 w-full'>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiBillLine size={23} className='mr-2 bg-slate-100 p-1 text-slate-800' />Invoice Revise</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.invoice_revise))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r  border-slate-100 px-2 py-2 w-full'>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiBillLine size={23} className='mr-2 bg-slate-100 p-1 text-slate-800' />Invoice Raised</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.invoice_raised))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r  border-slate-100 px-2 py-2 w-full'>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><FcMoneyTransfer size={23} className='mr-2 bg-slate-100 p-1 text-slate-800' />Invoice Amount with GST</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.invoice_amount_generated_with_gst))?.split('.')[0].slice(0)}</h6>
                </div>

                <div className='px-2 py-2 w-full'>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><FcMoneyTransfer size={23} className='mr-2 bg-slate-100 p-1 text-slate-800' />Invoice Amount without GST</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(data?.invoice_amount_generated_without_gst)?.split('.')[0].slice(0)}</h6>
                </div>

               
           </div>

           <div className='grid grid-cols-5 items-center justify-evenly border-l border-r border-b border-slate-100'>
           <div className='px-2 py-2 w-full border-r  border-slate-100 '>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><FcMoneyTransfer size={23} className='mr-2 bg-slate-100 p-1 text-slate-800' />Revenue recieved with GST </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(data?.revenue_recieved_with_gst)?.split('.')[0].slice(0)}</h6>
                </div>
                <div className='px-2 py-2 w-full border-r  border-slate-100 '>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><FcMoneyTransfer size={23} className='mr-2 bg-slate-100 p-1 text-slate-800' />Revenue recieved without GST </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(data?.revenue_recieved_without_gst)?.split('.')[0].slice(0)}</h6>
                </div>

                <div className='px-2 py-2 w-full border-r  border-slate-100 '>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiBillLine size={23} className='mr-2 bg-slate-100 p-1 text-slate-800' />Payment Approval Pending </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(data?.payment_approval_pending)?.split('.')[0].slice(0)}</h6>
                </div>

                <div className='px-2 py-2 w-full border-r  border-slate-100 '>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><FcMoneyTransfer size={23} className='mr-2 bg-slate-100 p-1 text-slate-800' />Invoice Raised Amt Incl GST </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(data?.invoice_payment_amount_approved_with_gst)?.split('.')[0].slice(0)}</h6>
                </div>
                <div className='px-2 py-2 w-full border-r  border-slate-100 '>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><FcMoneyTransfer size={23} className='mr-2 bg-slate-100 p-1 text-slate-800' />Invoice Raised Amt Excl GST </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(data?.invoice_payment_amount_approved_without_gst)?.split('.')[0].slice(0)}</h6>
                </div>
           </div>

           
           <h6 className='text-[14px] font-[700] ml-2 pt-4'>Finance Statistics</h6>
           <div className='flex h-[45%]'>
           <div className='border-b border-t border-l mr-2 border-r pr-2  w-[40%] flex-0.4  mt-2 border-slate-100 px-3 py-2 relative'>

            <h6 className='text-[13px] font-[700]'>Revenue Forecast</h6>
              <div className='flex '>
              <div className='-ml-10'>
              <ReactApexChart options={chartOptions} size={'10'} series={[Math.round((data?.revenue_recieved_without_gst/data?.invoice_amount_generated_without_gst)*100)]} type="radialBar" width={240} height={240} />
              </div>
              <div className='w-full '>



               <div className='ml-0 mt-8 w-full relative'>
                <h6 className='text-[11px] font-[400] min-w-[70%] max-w-[70%]'>Invoice Generated Amt Incl GST </h6>
                  <div className="w-full bg-gray-200 mt-1 rounded-full h-2.5 mb-2 dark:bg-gray-700">
                    <div className={`bg-gray-700  rounded-full h-2.5 flex items-center justify-center dark:bg-gray-300 font-[900  text-[8px] text-white text-center leading-none`} style={{width: data?.invoice_amount_generated_with_gst > 0 ? '100%' : '0%'}} >{data?.invoice_amount_generated_with_gst > 0 ? 100 : 0 }%</div>
                  </div>
                  <h6 className='text-[11px] font-[700] absolute top-0 right-0'>{rupeeIndian?.format(JSON.stringify(data?.invoice_amount_generated_with_gst))?.split('.')[0]}</h6>

              </div>

              <div className='ml-0  w-full relative w-[30%]'>
                <h6 className='text-[11px] font-[400] min-w-[70%] max-w-[70%]'>Invoice Generated Amt Excl GST </h6>
                  <div className="w-full bg-gray-200 mt-1 rounded-full h-2.5 mb-2 dark:bg-gray-700">
                    <div className={`bg-gray-700  rounded-full h-2.5 flex items-center justify-center dark:bg-gray-300 font-[900  text-[8px] text-white text-center leading-none`} style={{width: data?.invoice_amount_generated_without_gst > 0 ? '100%' : '0%'}}  >{data?.invoice_amount_generated_with_gst > 0 ? 100 : 0 }%</div>
                  </div>
                  <h6 className='text-[11px] font-[700] absolute top-0 right-0'>{rupeeIndian?.format(JSON.stringify(data?.invoice_amount_generated_without_gst))?.split('.')[0]}</h6>

              </div>

              <div className='ml-0  w-full relative'>
                <h6 className='text-[11px] font-[400] min-w-[70%] max-w-[70%]'>Revenue recieved  Amt Incl GST </h6>
                  <div className="w-full bg-gray-200 mt-1 rounded-full h-2.5 mb-2 dark:bg-gray-700">
                    <div className={`bg-gray-700  rounded-full h-2.5 flex items-center justify-center dark:bg-gray-300 font-[900  text-[8px] text-white text-center leading-none`} style={{width:data?.invoice_amount_generated_with_gst > 0 ? Math.round(Math.abs(data?.revenue_recieved_with_gst/data?.invoice_amount_generated_with_gst)*100)   > 100 ? '100%' : `${ Math.round(Math.abs(data?.revenue_recieved_with_gst/data?.invoice_amount_generated_with_gst)*100)}%`: '0%'}}  >{data?.invoice_amount_generated_with_gst ?  Math.round(Math.abs(data?.revenue_recieved_with_gst/data?.invoice_amount_generated_with_gst)*100) : 0}%</div>
                  </div>
                  <h6 className='text-[11px] font-[700] absolute top-0 right-0'>{rupeeIndian?.format(JSON.stringify(data?.revenue_recieved_with_gst))?.split('.')[0]}</h6>

              </div>


              <div className='ml-0  w-full relative'>
                <h6 className='text-[11px] font-[400] min-w-[70%] max-w-[70%]'>Revenue recieved  Amt Excl GST </h6>
                  <div className="w-full bg-gray-200 mt-1 rounded-full h-2.5 mb-2 dark:bg-gray-700">
                    <div className={`bg-gray-700  rounded-full h-2.5 flex items-center justify-center dark:bg-gray-300 font-[900  text-[8px] text-white text-center leading-none `} style={{width:data?.invoice_amount_generated_with_gst > 0 ? Math.round(Math.abs(data?.revenue_recieved_without_gst/data?.invoice_amount_generated_without_gst)*100) > 100 ? '100%' :  `${Math.round(Math.abs(data?.revenue_recieved_without_gst/data?.invoice_amount_generated_without_gst)*100)}%`: '0%'}}   >{data?.invoice_amount_generated_without_gst > 0 ? Math.round(Math.abs(data?.revenue_recieved_without_gst/data?.invoice_amount_generated_without_gst)*100) : 0}%</div>
                  </div>
                  <h6 className='text-[11px] font-[700] absolute top-0 right-0'>{rupeeIndian?.format(JSON.stringify(data?.revenue_recieved_without_gst))?.split('.')[0]}</h6>

              </div>

              {/* {Math.round(Math.abs(data.revenue_recieved_without_gst/data.invoice_amount_generated_without_gst)*100) > 100 ? '100%' : '1%'} */}

              {/* <div className='ml-0 mt-4 w-full relative'>
                <h6 className='text-[11px] font-[400]'>Yet To Achieve</h6>
                  <div className="w-full bg-gray-200 mt-1 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                    <div className={`bg-gray-700  rounded-full h-2.5 flex items-center justify-center dark:bg-gray-300 font-[900  text-[8px] text-white text-center leading-none `} style={{width:`${(revenue?.target_achieved/revenue?.employee_target_current_period)*100}%`}} >{(revenue?.target_achieved/revenue?.employee_target_current_period)*100}%</div>
                  </div>
                  <h6 className='text-[11px] font-[700] absolute top-0 right-0'>{rupeeIndian?.format(JSON.stringify(revenue?.employee_target_current_period - revenue?.target_achieved))?.split('.')[0]}</h6>

              </div>
              {(revenue?.target_achieved - revenue?.employee_target_current_period) > 0 && 
              <div className='ml-0 w-full relative -mt-1'>
                <h6 className='text-[11px] font-[400]'>Incentive Amount</h6>
                  <div className="w-full bg-gray-200 mt-1 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                    <div className="bg-gray-700 rounded-full h-2.5 flex items-center justify-center dark:bg-gray-300 font-[900]  text-[8px] text-white text-center leading-none " >100%</div>
                  </div>
                  <h6 className='text-[11px] font-[700] absolute top-0 right-0'>{rupeeIndian?.format(JSON.stringify(17000))?.split('.')[0]}</h6>

              </div>} */}
              </div>
              </div>

           </div>
           <div className='border  w-[60%] flex-0.6  mt-2 border-slate-100 px-3 py-2 relative'>
                <h1 className='text-[13px] font-[700]'>Total Report</h1>
                <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1?.series} type="bar" width={'100%'}  height={240} />
           </div>

           
           </div>
           {/* <h6>Finance Dashboard</h6> */}
        </div>
      </div>
    </div>
  )
}

export default FinanceDashboardMain