import React, { useEffect, useState } from 'react'
import DatabaseMenu from './DatabaseMenu'
import { GetDBDashboardService, GetDBRequirmentinfoDashboardService } from '../../services/database/mainoptions/DBDashboardServices'
import { MdOutlineEventSeat } from "react-icons/md";
import { RiBuilding4Line } from "react-icons/ri";
import { CgToday } from "react-icons/cg";
import { MdOutlinePendingActions } from "react-icons/md";
import { LuCalendarCheck } from "react-icons/lu";
import { TbBrandBitbucket } from "react-icons/tb";
import moment from 'moment';
import { AiOutlineDownload, AiOutlineEdit } from 'react-icons/ai';
import fileDownload from "js-file-download";
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function DBDashboard() {

    const [data,setdata] = useState({})
    const [requirment,setrequirment] = useState({datas:[],pagination:{total:0}})
    

    const [search,setsearch] = useState({from_date:'',to_date:'',text:'',limit:100,step:''})

    const navigate = useNavigate()

    async function getdata(){
        const response = await GetDBDashboardService()
        setdata(response?.data?.data)
    }

    async function getrequirmentInfo() {
        const response = await GetDBRequirmentinfoDashboardService('','',search?.from_date,search?.to_date,search?.text,search?.limit)
        setrequirment(response?.data?.data)
    }

    useEffect(()=>{
        getdata()
        getrequirmentInfo()
    },[])

    let rupeeIndian = Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
    });


    const handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
                toast.success("Attachment Downloaded") 
            })
      }

    return (
    <div className='flex'>
        <div className='min-w-[180px] w-[180px] max-w-[180px]'>
        <DatabaseMenu /> 
        </div>
        <div className='ml-4 w-[100%] mt-4 pr-5 min-h-screen max-h-screen h-screen overflow-y-scroll'>
        <div>


            <div className='grid border-l border-r grid-cols-6 items-center justify-evenly border-t border-b border-slate-200'>
                <div className='border-r border-b bg-white  border-slate-200 px-2 py-2 w-full' onClick={()=>navigator('/leads/contacts')}>
                    <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineEventSeat size={23} className='mr-2 text-[#4070cf] p-1 bg-slate-100' />Managed Office</h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.manage_office))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r border-b bg-white  border-slate-200 px-2 py-2 w-full' onClick={()=>navigator('/leads/contacts')}>
                    <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiBuilding4Line size={23} className='mr-2 text-[#8a50b3] p-1 bg-slate-100' /> Office Space</h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.office_space))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r border-b bg-white  border-slate-200 px-2 py-2 w-full' onClick={()=>navigator('/leads/contacts')}>
                    <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><CgToday size={23} className='mr-2 text-yellow-500 p-1 bg-slate-100' /> Total Data</h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.office_space + data?.manage_office))?.split('.')[0].slice(1)}</h6>
                </div>
                <div className='border-r border-b bg-white  border-slate-200 px-2 py-2 w-full' onClick={()=>navigator('/leads/contacts')}>
                    <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><TbBrandBitbucket size={23} className='mr-2 text-orange-500 p-1 bg-slate-100' /> Requirment Recieved</h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.total_requirment_pending + data?.total_requirment_recieved + data?.total_requirment_resolved))?.split('.')[0].slice(1)}</h6>
                </div>
                <div className='border-r border-b bg-white  border-slate-200 px-2 py-2 w-full' onClick={()=>navigator('/leads/contacts')}>
                    <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlinePendingActions size={23} className='mr-2 text-blue-500 p-1 bg-slate-100' /> Requirment Pending</h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.total_requirment_pending))?.split('.')[0].slice(1)}</h6>
                </div>
                <div className='bg-white border-b border-slate-200 px-2 py-2 w-full' onClick={()=>navigator('/leads/contacts')}>
                    <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><LuCalendarCheck size={23} className='mr-2 text-green-500 p-1 bg-slate-100' /> Requirment Resolved</h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.total_requirment_resolved))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r   bg-white  border-slate-200 px-2 py-2 w-full' onClick={()=>navigator('/leads/contacts')}>
                    <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><CgToday size={23} className='mr-2 text-yellow-500 p-1 bg-slate-100' /> Today Data</h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.today_data))?.split('.')[0].slice(1)}</h6>
                </div>
                <div className='border-r   bg-white  border-slate-200 px-2 py-2 w-full' onClick={()=>navigator('/leads/contacts')}>
                    <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><TbBrandBitbucket size={23} className='mr-2 text-orange-500 p-1 bg-slate-100' /> Today Reqr Recieved</h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.today_requirment_recieved))?.split('.')[0].slice(1)}</h6>
                </div>
                <div className='border-r   bg-white  border-slate-200 px-2 py-2 w-full' onClick={()=>navigator('/leads/contacts')}>
                    <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlinePendingActions size={23} className='mr-2 text-blue-500 p-1 bg-slate-100' /> Today Reqr Pending</h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.today_requirment_pending ))?.split('.')[0].slice(1)}</h6>
                </div>
                <div className='bg-white  border-r  border-slate-200 px-2 py-2 w-full' onClick={()=>navigator('/leads/contacts')}>
                    <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><LuCalendarCheck size={23} className='mr-2 text-green-500 p-1 bg-slate-100' /> Today Reqr Resolved</h6>
                    <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(data?.today_requirment_resolved))?.split('.')[0].slice(1)}</h6>
                </div>
            </div>


            <div className='mt-2 w-[100%] border p-2'>
                <h6 className='text-slate-700 font-[800] text-[12px]'>Today Requirment ({requirment?.pagination?.total})</h6>



                <div className='border mt-2'>
                    <div className='flex text-[12px] font-[600] text-slate-600 z-50 stickt top-0  border-slate-200'>
                        <h6 className='min-w-[10%] p-1 max-w-[10%] border-r'>SL NO</h6>
                        <h6 className='min-w-[15%] p-1 max-w-[15%] border-r'>User Info</h6>
                        <h6 className='min-w-[25%] p-1 max-w-[25%] border-r'>Requirement</h6>
                        <h6 className='min-w-[15%] p-1 max-w-[15%] border-r'>Status</h6>
                        <h6 className='min-w-[15%] p-1 max-w-[15%] border-r'>Remarks / Handled By</h6>
                        <h6 className='min-w-[10%] p-1 max-w-[10%] border-r'>Created At</h6>
                        <h6 className='min-w-[10%] p-1 max-w-[10%] '>Actions</h6>
                    </div>
                    {requirment?.datas?.map((r,i)=>(
                        <div className='flex text-[12px] z-50 stickt top-0 border-t border-slate-200'>
                            <h6 className='min-w-[10%] p-1 max-w-[10%] border-r'>{i+1}</h6>
                            <h6 className='min-w-[15%] p-1 max-w-[15%] border-r font-[600]'>{r?.created_by?.name}</h6>
                            <h6 className='min-w-[25%] p-1 max-w-[25%] border-r'>{r?.requirment} <br></br>
                    <h6  onClick={()=>navigate('/leads/contacts/detail',{state:r?.lead_id?._id})} className='text-blue-500 underline font-[600] cursor-pointer'>View Lead Info</h6>

                            </h6>
                            <h6 className='min-w-[15%] p-1 max-w-[15%] border-r'>{r?.status}</h6>
                            <h6 className='min-w-[15%] p-1 max-w-[15%] border-r '>
                            {r?.remarks !== '' && `Remarks :`} <span className='font-[600]'>{r?.remarks}</span> <br></br>
                               Handled By :
                               <span className='font-[600]'>{r?.handled_by?.name}</span> 
                               <br></br>
                                {r?.file !== '' &&
                                <div className='flex relative mt-1'>
                                    <img src="https://cdn-icons-png.flaticon.com/512/179/179483.png" className='w-5 h-5 object-contain z-0' />
                                    <h6 className='text-[9px] font-[800] ml-1 w-[65%]  overflow-hidden break-all line-clamp-2'>{r?.file?.split('/')[r?.file?.split('/')?.length - 1]}</h6>
                                    <AiOutlineDownload size={13} className='cursor-pointer absolute right-1 bg-slate-700 text-white p-1 text-[50px] rounded ' onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${r?.file}`,r?.file?.split('/')[r?.file?.split('/')?.length - 1])} />
                                </div>}
                             </h6>
                            <h6 className='min-w-[10%] p-1 max-w-[10%] border-r'>{moment(r?.createdAt)?.format('LLL')}</h6>
                            <h6 className='min-w-[10%] p-1 max-w-[10%] '>
                                <AiOutlineEdit className='mr-2' onClick={()=>navigate('/database/requirment_info/edit',{state:r})} />
                            </h6>
                        </div>
                    ))}

                </div>
            </div>


        </div>
        </div>    
    </div>
  )
}

export default DBDashboard