import { IconButton, Tooltip, setRef } from '@mui/material'
import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import { CreateLeadsListBasedonIdService, GetLeadNoteService, GetLeadReminderService, GetLeadsService } from '../../services/LeadServices';
import moment from 'moment';
import {AiOutlineEdit,AiOutlineCloudDownload,AiOutlineCalendar,AiOutlineFileExcel} from 'react-icons/ai';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';

import {IoMdClose} from 'react-icons/io';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import {Drawer, Modal, Select} from 'antd';
import { TextInput } from '../../components/input';
import { DatePicker } from 'antd';
// import DailyTask from './dailyTask/DailyTask';
import {AiOutlineMail,AiOutlinePhone,AiOutlineDelete} from 'react-icons/ai';
import {HiLogout} from 'react-icons/hi';
import {IoMdArrowForward} from 'react-icons/io';
import axios from 'axios';
import fileDownload from "js-file-download";
import { toast } from 'react-hot-toast';
import {CiStickyNote} from 'react-icons/ci';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { DeleteAssignLeadService, DeleteFidelitusLead, GetAllAssignedLeadsToMeService, GetAssignedLeadsService, UploadFidelitusLeadExcel, SearchAssignLeadsService, SearchAssignLeadsService1 } from '../../services/AssignLeadServices';
import Uploader from '../../components/Uploader';
import { GetDepartmentService } from '../../services/DepartmentServices';
import FTSLeadMenu from './FTSLeadMenu';
import { DeleteFTSLeadService, GetFTSLeadsService, SearchFTSLeadService, SearchFTSLeadsService1 } from '../../services/FTSLeadServices';

function FTSLeadlist() {

  const location = useLocation();
  const user = useSelector(state=>state.Auth)
  const user_department = user.department_id[0]
  const navigate = useNavigate();
  const [excel,setexcel] = useState({file:'',error:''})

  let path = location?.pathname?.split('/')[2]

  const [step,setstep] = useState(1)
  const [modal,setmodal] = useState(false)

  const [leads,setleads]  = useState([])
  const [leads_selected_list,setleads_selected_list] = useState([])
  

  const [type,settype] = useState('')
  const [page,setpage] = useState(1)
  const [filter_drawer,setfilter_drawer] = useState(false)
  const [preview,setpreview] = useState(false)

  const [selected_lead,setselected_lead] = useState({})
  const [delete_modal,setdelete_modal] = useState(false)
  const [attachment,setattachment] = useState([])
  const [note,setnote] = useState([])
  const [departments,setdepartments] = useState([])

  const [search,setsearch] = useState({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',zone:'',country:'',activate:false,department:''})

 
 

  useEffect(()=>{
      getdepartments()
      setpage(1)
      getleads(1)
  },[location.pathname,step])


  useEffect(()=>{
      if(search.activate){
        applyfilterfunction(page)
      }else{
        getleads(page)
      }
  },[page])


  const stage = path === 'hold' ? [{name:'contacts',value:'Contact',index:0},{name:'hold',value:'Hold',index:1},{name:'options_shared',value:'Options',index:2},{name:'inspections',value:'Inspection',index:3},{name:'loi_agreement',value:'Agreement',index:4},{name:'invoice_raised',value:'Invoice',index:5}] : [{name:'contacts',value:'Contact',index:0},{name:'options_shared',value:'Options',index:1},{name:'inspections',value:'Inspection',index:2},{name:'loi_agreement',value:'Agreement',index:3},{name:'invoice_raised',value:'Invoice',index:4}]
  
  async function getleads(page){
    const response =  await GetFTSLeadsService(page,step);
    setleads(response.data)
  }

  async function getdepartments() {
    const response = await GetDepartmentService()
    let d = response?.data?.datas
    let arr = []
    d?.forEach((d1)=>{
      if(!['Finance Team']?.includes(d1?.department_name)){
        arr.push({value:d1.id,label:d1.department_name})
      }
    })
    setdepartments(arr)
  }

  async function applyfilterfunction(page){
    setsearch({...search,activate:true})
    const response = await SearchFTSLeadsService1({search_text:search.text,status:type,from_date:search?.from_date1,to_date:search?.to_date1,zone:search?.zone,page:page,department:search?.department,step})
    setleads(response.data)
    setfilter_drawer(false)
  }

  async function resetfunc(){
    setsearch({text:'',from_date:'',to_date:'',activate:false,zone:'',department:''})
    getleads(1)
    setpage(1)
  }

  
  function returnfilename(v){
    let string = v?.split("/")[v?.split("/")?.length - 1]
    return string;
  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
            toast.success("Attachment Downloaded") 
        })
  }

  function getFileSizeString(fileSizeInBytes) {
      var i = -1;
      var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
        fileSizeInBytes /= 1024;
        i++;
      } while (fileSizeInBytes > 1024);
      return Math.max(fileSizeInBytes, 0.1).toFixed(1).slice(0,4) + byteUnits[i];
  }

  function selected_lead_check_to_group(v){
      if(leads_selected_list?.includes(v)){
        setleads_selected_list([...leads_selected_list.filter(e => e!== v)])
      }else{
        if(leads_selected_list.length < 10){
           setleads_selected_list([...leads_selected_list,v])
        }else{
          toast.error('You can select upto max 10 items')
        }
      }      
  }

  async function createManyContactBasedOnlIST(){
    try{
     const response = await CreateLeadsListBasedonIdService({data:leads_selected_list})
     if(response?.status === 200){
        setleads_selected_list([])
        toast.success(response?.data?.data)
        getleads(page)
     }
    }catch(e){
      // console.log("err",e)
    }
  }

  async function deleteData(d){
    const response = await DeleteFTSLeadService(d)
    if(response.status === 200){
      toast.success("Deleted Successfully")
      setdelete_modal(false)
      setselected_lead({})
      getleads(page)
    }
  }

  async function uploadExcel(){
    if(!excel?.file){
      setexcel({...excel,error:'This Field is required!'})
    }else{
      const response = await UploadFidelitusLeadExcel(excel?.file)
      if(response?.status === 201){
        toast.success("Excel Uploaded Successfully")
        setexcel({file:'',error:''})
        setmodal(false)
      }
    }
  }

  async function deleteAddMDData(){
    const response = await DeleteFidelitusLead()
    if(response?.status === 200){
      toast.success('Data Deleted')
    }
  }


  return (
    <div className='h-screen max-h-screen '>

      <Modal open={modal} width={350} closable={false} footer={false} className='absolute top-0 left-[40%]'>
          <h6 className="font-bold text-[13px]  mb-2 w-full">Upload Excel</h6>
          <Uploader image={excel?.file}  setimagefunc={(e)=>{setexcel({...excel,file:e,error:''})}}  removeimageuploadfunc = {()=>setexcel({...excel,file:'',error:''})} />

          <div className='mt-2 flex items-center'>
            <ButtonOutlinedAutoWidth btnName="Close" onClick={()=>setmodal(false)}/>
            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName="Save" onClick={()=>uploadExcel()} />
            </div>
          </div>
      </Modal> 

      <Modal open={delete_modal} width={350} closable={false} footer={false} className='absolute top-0 left-[40%]'>
          <h6 className="font-bold text-[13px]  mb-2 w-full">Delete Data</h6>
          <h6 className="text-[11px]  mb-2 w-full">Are you sure want to delete the selected data which has company name : <span className='font-[800]'>{selected_lead?.company_name}</span></h6>

          <div className='mt-2 flex items-center'>
            <ButtonOutlinedAutoWidth btnName="Close" onClick={()=>setdelete_modal(false)}/>
            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName="Save" onClick={()=>deleteData(selected_lead?._id)} />
            </div>
          </div>
      </Modal> 

      <Drawer
        anchor={'right'}
        open={filter_drawer}
        closable={false}
        width={260}
      >
        <div className=' w-52 -mt-3'>
          <span onClick={()=>setfilter_drawer(!filter_drawer)} ><IoMdClose className='absolute right-2 cursor-pointer' /></span>
          <h6 className='text-[13px]'>Use the below form to apply filter for your leads</h6>


          <TextInput 
                mandatory={false}
                label={'Search Text'}  
                variant="standard"
                name="text"
                type="text"
                value={search.text}
                handlechange={e=>setsearch({...search,text:e.target.value})}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>


          
            
            <div>
            <h6 className='text-[12px] font-[600] mb-1 mt-2' >{'From Date'}</h6>
            <div>
          <DatePicker ampm={false} placeholder='' className='w-full'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 
          </div>    
          <h6 className='text-[12px] font-[600] mb-1 mt-2' >{'To Date'}</h6>
          <div>
          <DatePicker ampm={false} placeholder='' className='w-full'  value={search?.to_date} onChange={(v) => {setsearch({...search,to_date:v})}}  /> 
          </div>
          </div>




          
          <div className='flex mt-4'>
          <div  className="mr-2" >
          <ButtonOutlinedAutoWidth btnName="Reset" onClick={resetfunc}/>   
          </div>
          <ButtonFilledAutoWidth btnName="Apply" onClick={()=>applyfilterfunction(1)} />   
          </div>

        </div>
      </Drawer>

      <Drawer maskStyle={{background:'black',opacity:0.8}} onClose={()=>setpreview(false)}  maskClosable={()=>setpreview(false)} open={preview} width={600} closable={false}>
        
        <div >
          <div className='mb-3 -mt-3 flex items-center justify-between border-b pb-3 border-slate-100'>
            <h6  onClick={()=>setpreview(false)} className='flex cursor-pointer items-center'>
            <HiLogout size={20} className='text-slate-300' /> 
            <span className='ml-2 font-[600]'>Lead Preview</span>
            </h6>

            <h6 onClick={()=>navigate(`detail`,{state:selected_lead?._id})} className='border cursor-pointer flex items-center p-1.5 px-2 font-[500] text-[11px] border-slate-100 rounded'>View Full Detail <IoMdArrowForward size={15} className='ml-1' /></h6>
          </div>
          <div className='border relative border-slate-100 '>
            <div className='flex absolute right-2 top-1'>
                <CopyToClipboard text={selected_lead?.email}>
              <AiOutlineMail size={22} className='border cursor-pointer p-1 mr-2 rounded-full text-slate-400' />
              </CopyToClipboard>
              <CopyToClipboard text={selected_lead?.phone}>
              <AiOutlinePhone size={22} className='border cursor-pointer p-1 mr-2 rounded-full text-slate-400'  />
              </CopyToClipboard>
              
              <AiOutlineEdit onClick={()=>navigate('edit',{state:selected_lead?._id})} size={22} className='border cursor-pointer p-1 rounded-full text-slate-400'  />
            </div>
            <div className='flex p-2 items-center border-b   border-slate-100 '>
            <h6 className='bg-purple-200 w-9 h-9 text-[12px] flex items-center justify-center uppercase rounded-full '>{selected_lead?.contact_name?.slice(0,2)}</h6>
            <div className='ml-2'>
              <h6 className='mb-0 font-[700]'>{selected_lead?.contact_name}</h6>
              <h6 className='flex text-[11px] -mt-0.5 items-center text-slate-400'><AiOutlineMail  className='mr-2'/> {selected_lead?.email} <AiOutlinePhone className='mx-2' /> {selected_lead?.phone} </h6>
            </div>
            </div>
            <div className='flex items-center justify-evenly'>
              <div className='border-r  border-slate-100 w-full pl-3 py-2'>
                <h6 className='text-slate-400 text-[11px]'>Lead owner</h6>
                <h6 className='font-[600] text-[12px]'>{selected_lead?.lead_owner?.name}</h6>
              </div>

              <div className='border-r  border-slate-100 w-full pl-3  py-2'>
                <h6 className='text-slate-400 text-[11px]'>Company</h6>
                <h6 className='font-[600] text-[12px]'>{selected_lead?.company_name}</h6>
              </div>

              <div className='border-r  border-slate-100 w-full pl-3  py-2'>
                <h6 className='text-slate-400 text-[11px]'>Job Title</h6>
                <h6 className='font-[600] text-[12px]'>{selected_lead?.designation}</h6>
              </div>

              <div className=' w-full pl-3 py-2'>
                <h6 className='text-slate-400 text-[11px]'>Requirment</h6>
                <h6 className='font-[600] text-[11px]'>{selected_lead?.sqft && 'SQFT,'} {selected_lead?.seater && 'SEATER,'} {selected_lead?.rent && 'RENT,'} {selected_lead?.sale && 'SALE'}</h6>
              </div>

            </div>
          </div>


          <div className='mt-2 flex justify-between'>
          {stage?.map((s,i)=>(
            <h6 key={i} className={` w-full mx-0.5 font-[600] cursor-pointer text-center p-1 text-[11px] ${stage?.find(f=>f?.name === path)?.index >  i ? 'bg-green-100 text-green-600' : s?.name == path ?  'bg-green-600 text-white' : 'bg-salte-100 text-slate-600'} `}>{s?.value}</h6>
          ))}
          </div>

          <div className='flex items-center my-4 pb-4 border-slate-100 border-b justify-between'>
            <h6 className='text-[11px]'>Lead Source <span className='text-[11px] font-[700]'>{selected_lead?.lead_source?.name}</span> </h6>
            <h6 className='text-[11px]'>Last Activity : <span className='text-[11px] font-[700]'> {moment(selected_lead?.updatedAt).format('lll')}</span></h6>
          </div>


          {(attachment?.datas?.length === 0 && note?.datas?.length === 0 )&& 
                <div className='grid place-items-center mt-20  items-center justify-center'>
                  <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                  <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                  <h6 className='font-[500] w-[80%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any particual notes or reminders added for this particular leads.</h6>
                </div>
          }

          {attachment?.datas?.length !== 0 && 
          <>
          <h6 className='text-[12px] font-[700]'> Reminder <span className='bg-slate-100 p-1 px-2 rounded font-[400] text-slate-800 text-[12px]'>{attachment?.datas?.length}</span></h6>
          {attachment?.datas?.map((n)=>(
          <div className='border mt-2 p-2 rounded border-slate-100'>
                <div className='border-b flex items-center justify-between pb-2 mb-2 border-slate-100'> 
                  <h6 className='text-[11px] flex items-center '><CiStickyNote size={21} className='bg-slate-100 text-blue-600 p-1 mr-2' /> <span className='font-[500] mr-1'>Reminder </span> by <span  className='font-[500] ml-1'> {n?.user_id?.name}</span></h6>
                  <h6 className='text-[10px] text-slate-400 flex items-center '><AiOutlineCalendar size={21} className='p-1 mr-1' /> <span className='font-[500] mr-1'>{moment(n?.createdAt)?.format('lll')}</span></h6>
                </div>   
                <h6 className='text-[12px] font-[500] mb-1'>{n?.title}</h6>
                <h6 className='leading-tight text-slate-400 text-[11px] font-[400]'>{n?.description} <span className='text-black'> {moment(n?.on_date_time)?.format('lll')} </span></h6>
                
          </div>
          ))}
          </>}

          {note?.datas?.length !== 0 && 
          <>
          <h6 className='text-[12px] font-[700] my-4'> Notes <span className='bg-slate-100 p-1 px-2 rounded font-[400] text-slate-800 text-[12px]'>{note?.datas?.length}</span></h6>
          
          {note?.datas?.map((n)=>(
          <div className='border mt-2 p-2 rounded border-slate-100'>
                <div className='border-b flex items-center justify-between pb-2 mb-2 border-slate-100'> 
                  <h6 className='text-[11px] flex items-center '><CiStickyNote size={21} className='bg-slate-100 text-blue-600 p-1 mr-2' /> <span className='font-[500] mr-1'>Note </span> by <span  className='font-[500] ml-1'> {n?.user_id?.name}</span></h6>
                  <h6 className='text-[10px] text-slate-400 flex items-center '><AiOutlineCalendar size={21} className='p-1 mr-1' /> <span className='font-[500] mr-1'>{moment(n?.createdAt)?.format('lll')}</span></h6>
                </div>   
                <h6 className='text-[12px] font-[500] mb-1'>{n?.note_title}</h6>
                <h6 className='leading-tight text-slate-400 text-[11px] font-[400]'>{n?.summary}</h6>
                {(n?.note_file !== null && n?.note_file !== '' && n?.note_file !== undefined) &&
                          <>
                          <div className='mt-1 border-slate-100  flex relative py-1'>
                            <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 object-contain' />
                            <div className='ml-1 -mt-0.5'>
                            <h6 className='font-[600] text-[11px]'>{returnfilename(n?.note_file)}</h6>
                            <h6 className='font-[600] text-[9px] -mt-0.5'>{getFileSizeString(n?.filesize)} </h6>
                            </div>
                          {(n?.note_file !== null && n?.note_file !== undefined && n?.note_file !== '') && <AiOutlineCloudDownload size={23} className='absolute right-1 top-1.5 bg-slate-100 p-1 rounded-full' onClick={()=>handleDownload(`${process.env.REACT_APP_AWS_IMAGE_URL}${n?.note_file}`,returnfilename(n?.note_file))}/>}

                            </div>
                            </>}
          </div>
          ))}
          </>}


        </div>
      </Drawer>

      
      <div className='flex'>
      <div className={`min-w-44`}>
        <FTSLeadMenu />
      </div>
      <div  className="min-w-[87%] max-w-[87%] px-4 pt-4">
      
        <div>
          <div className='flex items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total FTS Leads ({leads?.pagination?.total})</h6>
            <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? leads?.datas?.length > 0 ? 1 : 0 :  (page - 1) * leads?.pagination?.limit } - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < leads?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
              </div>


              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

            
            <div >
                

              <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 mr-2 rounded-md border-slate-300' />
            
            
            </div> 
        
            
            {step == 2 && <>
            <Select 
              bordered={false}
              value={search?.department !== '' ? search?.department : null}
              options={departments}
              onChange={(e)=>setsearch({...search,department:e})}
              placeholder={'Department'}
              className='border rounded-[6px] border-slate-300 h-[30px] mr-2 w-[100px]' 
              />  
              </>} 

          <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
          {leads_selected_list?.length > 0 &&
          <div className='ml-2'>
          <ButtonFilledAutoWidth onClick={createManyContactBasedOnlIST} btnName="Create Contact" /> 
          </div>}
         
          <div className='ml-2'>
          <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
          </div>
            </div>
          </div>
          <div className='border-b flex items-center justify-start'>
            <h6 onClick={()=>{setstep(1);setpage(1)}} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-max px-2 text-[11px] capitalize`}>Our Leads</h6>
            <h6 onClick={()=>{setstep(2);setpage(1)}} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-max px-2 text-[11px] capitalize`}>Assigned Leads</h6>
          </div>    

        
          {leads?.datas?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
            <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
            <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
            <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
           </div>
          }

      
          {step === 1 && 
          <>
          {leads?.datas?.length > 0 &&
          <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
          <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
              <h6  className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'> SL NO </h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[190px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
              <h6 className='top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Name</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[200px]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created By</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[250px] px-2 py-1 font-[600] text-slate-600 border-r'>Assigned Department</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created On</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[110px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
          </div>
        
          {leads?.datas?.map((d,i)=>(
          <div  key={d?._id} className={`flex z-50 h-full border-b border-slate-200 z-0`}  >
              <h6 onClick={()=>selected_lead_check_to_group(d?._id)} className='sticky top-0 z-30 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* leads?.pagination?.limit)} </h6>
              <h6 className='text-[12px] font-[500] w-[190px] px-2 py-1 truncate border-r  border-slate-200'>{d?.company_name}</h6>
              <h6 className='text-[12px] font-[500] w-[150px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.contact_name}</h6>
              <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.phone}</h6>
              <h6 className='text-[12px] font-[500] w-[200px]   px-2 py-1 break-all border-r  border-slate-200'>{d?.email}</h6>
              <h6 className='text-[10px] font-[500] w-[150px] px-2 py-1 truncate border-r  border-slate-200 font-[700]'>{d?.lead_owner?.name} <br></br> <span className='bg-blue-200 mt-[2px] text-[8px] inline-block rounded p-1 text-[10px]'>{d?.lead_owner?.department_id[0]?.department_name}</span></h6>
              <h6 className='text-[12px] font-[500] w-[250px] px-2 py-1 truncate border-r  border-slate-200'>
                <div class="flex -space-x-4">
                  {d?.ftsleadassigneds?.map((f)=>(
                    <Tooltip title={f?.department_id?.department_name}>
                    <span className='border-2 border-white rounded-full bg-gray-100 p-1 px-2 text-[7px] dark:border-gray-800 font-[800]'>{f?.department_id?.department_name?.match(/\b(\w)/g)?.join('')}</span>
                    </Tooltip>
                  ))} 
                </div>  
              </h6>
              <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6>
              <h6  className='text-[12px] w-[110px]  px-2 py-1 flex'>
            
                {path !== 'invoice_raised' &&
                <Tooltip title="Edit">
                <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('edit',{state:d?._id})}/></span>
                </Tooltip>}

            
                {(user?.roles?.includes('delete_data') || user?.roles?.includes('admin')) &&
                <Tooltip title="Delete">
                <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{setdelete_modal(true);setselected_lead(d)}}/></span>
                </Tooltip>}
                

              
              </h6>
          </div>))}
          </div>}
          </>}

          {step === 2 && 
          <>
          {leads?.datas?.length > 0 &&
          <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
          <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
              <h6  className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'> SL NO </h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[190px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
              <h6 className='top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Name</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[200px]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created By</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[250px] px-2 py-1 font-[600] text-slate-600 border-r'>Assigned Department</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created On</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[110px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
          </div>
        
          {leads?.datas?.map((d,i)=>(
          <div  key={d?._id} className={`flex z-50 h-full border-b border-slate-200 z-0`}  >
              <h6 onClick={()=>selected_lead_check_to_group(d?._id)} className='sticky top-0 z-30 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* leads?.pagination?.limit)} </h6>
              <h6 className='text-[12px] font-[500] w-[190px] px-2 py-1 truncate border-r  border-slate-200'>{d?.company_name}</h6>
              <h6 className='text-[12px] font-[500] w-[150px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.contact_name}</h6>
              <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.phone}</h6>
              <h6 className='text-[12px] font-[500] w-[200px]   px-2 py-1 break-all border-r  border-slate-200'>{d?.email}</h6>
              <h6 className='text-[10px] font-[500] w-[150px] px-2 py-1 truncate border-r  border-slate-200 font-[700]'>{d?.lead_owner?.name} <br></br> <span className='bg-blue-200 mt-[2px] text-[8px] inline-block rounded p-1 text-[10px]'>{d?.lead_owner?.department_id[0]?.department_name}</span></h6>
              <h6 className='text-[12px] font-[500] w-[250px] px-2 py-1 truncate border-r  border-slate-200'>
                <div class="flex -space-x-4">
                  {d?.ftsleadassigneds?.map((f)=>(
                    <Tooltip title={f?.department_id?.department_name}>
                    <span className='border-2 border-white rounded-full bg-gray-100 p-1 px-2 text-[7px] dark:border-gray-800 font-[800]'>{f?.department_id?.department_name?.match(/\b(\w)/g)?.join('')}</span>
                    </Tooltip>
                  ))} 
                </div>  
              </h6>
              <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6>
              <h6  className='text-[12px] w-[110px]  px-2 py-1 flex'>
            
                {path !== 'invoice_raised' &&
                <Tooltip title="Edit">
                <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('edit',{state:d?._id})}/></span>
                </Tooltip>}

            
                {(user?.roles?.includes('delete_data') || user?.roles?.includes('admin')) &&
                <Tooltip title="Delete">
                <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{setdelete_modal(true);setselected_lead(d)}}/></span>
                </Tooltip>}
                

              
              </h6>
          </div>))}
          </div>}
          </>}

        </div>

        
      


      </div>
      </div>
   
    </div>
  )
}

export default FTSLeadlist