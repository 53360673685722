import React, { useEffect, useState } from 'react'
import FTSLeadMenu from './FTSLeadMenu'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton,Tooltip } from '@mui/material'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ButtonFilledAutoWidth } from '../../components/button';
import { GetFTSLeadsSharedToUsBasedUserFTSService } from '../../services/FTSLeadServices';
import { useSelector } from 'react-redux';

function FTSLeadRecievedFromFTS() {

  const navigate = useNavigate()  
  const [page,setpage] = useState(1)  
  const [leads,setleads] = useState({pagination:{total:0,totalPages:0,limit:25}})

  let user = useSelector(state=>state.Auth)
  console.log("user",user)

  useEffect(()=>{
    getdata()
  },[])

  async function getdata(){
    console.log("1 here okk")
    const response = await GetFTSLeadsSharedToUsBasedUserFTSService(page)
    setleads(response?.data)
    console.log("response?.data",response?.data)
  }

  async function addData() {
    
  }


  async function resetfunc() {
    
  }

  async function deleteData() {
    
  }

  console.log("leads",leads)

  return (
    <div className='h-screen max-h-screen'>
       <div className='flex'>
            <div className={`min-w-44`}>
                <FTSLeadMenu />
            </div>
        <div  className="w-[87%] px-4 pt-4">
            <div >
                <div className='flex items-center justify-between border-b pb-2'>
                    <h6 className='font-[700] text-[14px] '>Total Leads Recieved From FTS ({leads?.pagination?.total})</h6>
                    <div className='flex items-center'>
                    <div className='flex items-center text-[12px]'>

                        <h6 className='mr-2 font-[600]'>{page == 1 ? leads?.datas?.length > 0 ? 1 : 0 :  (page - 1) * leads?.pagination?.limit } - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
                        <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                        <div>
                        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        <IconButton onClick={()=>{ page < leads?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                        </div>
                    </div>



            
                    </div>
                </div>

                        {leads?.datas?.length === 0 &&
                            <div className='grid place-items-center mt-20  items-center justify-center'>
                                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                                <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
                            </div>
                        }
                
                
                        {leads?.datas?.length > 0 &&
                        <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
                            <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                                <h6  className='top-0 bg-white z-50 text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Name</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[90px]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[130px] px-2 py-1 font-[600] text-slate-600 border-r'>Requirment</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>Converted</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[130px] px-2 py-1 font-[600] text-slate-600 border-r'>Summary</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created On</h6>
                                <h6 className='sticky top-0 z-50  text-[12px] w-[110px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
                            </div>
                        
                            {leads?.datas?.map((d,i)=>(
                            <div  key={d?._id} className={`flex z-50 h-full border-b border-slate-200 z-0`}  >
                                <h6  className='sticky top-0 z-30 text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex items-center justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* leads?.pagination?.limit)}</h6>
                                <h6 className='text-[12px] font-[500] w-[170px] px-2 py-1 truncate border-r  border-slate-200'>{d?.fts_lead?.company_name}</h6>
                                <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.fts_lead?.contact_name}</h6>
                                <h6 className='text-[12px] font-[500] w-[90px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.fts_lead?.phone}</h6>
                                <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.fts_lead?.email}</h6>
                               
                    
                                <h6 className={`text-[12px] font-[500] w-[130px] px-2 py-1  truncate  border-r  border-slate-200 ${d?.requirment ? 'block' : 'text-[#fff]'}`}>{d?.requirment ? d?.requirment : 'Not Added'}</h6>
                                <h6 className='text-[12px] text-center font-[500] w-[80px] px-2 py-1 truncate border-r  border-slate-200'> {d?.converted === '1' && <span className='bg-green-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '1' && 'Converted'}</span>} {d?.converted === '2' &&  <span className='bg-red-200 p-1 text-[9px] text-center font-[700] rounded'>{d?.converted === '2' && 'Rejected'}</span>} {d?.converted === '3' &&  <span className='bg-yellow-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '3' && 'Pending'}</span>} {d?.converted === '4' &&  <span className='bg-blue-200 p-1 text-[9px] text-center font-[700] rounded '>{d?.converted === '4' && 'Progress'}</span>} </h6>
                                <h6 className={`text-[12px] font-[500] w-[130px] px-2 py-1 truncate border-r  border-slate-200 ${d?.remarks ? 'block' : 'text-[#fff]'}`}>{d?.remarks ? d?.remarks : 'Not Added'}</h6>
                                
                                <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6>
                                <h6  className='text-[12px] w-[110px]  px-2 py-1 flex'>
                                
                                <Tooltip title="Edit">
                                <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('/fts_leads_assign_remark/list/edit',{state:d})}/></span>
                                </Tooltip>
                    
                                {user?.roles?.includes('delete_data') &&
                                <Tooltip title="Delete">
                                <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{deleteData(d?._id)}}/></span>
                                </Tooltip>}
                                </h6>
                            </div>))}
                         </div>}
       </div>     
       </div>     
     </div>
    </div> 
  )
}

export default FTSLeadRecievedFromFTS