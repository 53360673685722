import React, { useEffect, useState } from 'react'
import GoBack from '../../../components/back/GoBack'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { Select } from 'antd'
import DatabaseMenu from '../DatabaseMenu'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import { CreatePropertyZoneService, UpdatePropertyZoneService } from '../../../services/database/databaseoptions/PropertyZoneServices'
import { TextAreaInput1 } from '../../../components/input'
import { UploadOfficeSpaceAttachmentService } from '../../../services/database/mainoptions/OfficeSpaceServices'
import Uploader from '../../../components/Uploader'
import { CreateDBRequirmentInfoService, UpdateDBRequirmentInfoService } from '../../../services/database/DBrequirmentInfoServices'

function RequirmentInfoListCE() {
  
  const [data,setdata] = useState({lead_id:'',requirment:'',remarks:'',file:'',status:''})  
  const [error,seterror] = useState({lead_id:'',requirment:'',remarks:'',file:'',status:''})  


  const navigate = useNavigate()
  const  {state} = useLocation()


  const statusArr = [{label:'Pending',value:'Pending'},{label:'Progress',value:'Progress'},{label:'Completed',value:'Completed'}]


  useEffect(()=>{
    if(state?._id !== undefined){
        let sendData = {...state}
        sendData['status'] = {label:sendData['status'],value:sendData['status']}
        sendData['created_by'] = {label:sendData['created_by']?.name,value:sendData['created_by']?._id}
        sendData['handled_by'] = {label:sendData['handled_by']?.name,value:sendData['handled_by']?._id}
        setdata(sendData)
    }else{
        navigate(-1)
    }
  },[state])


  async function submitform(){
    if(!data?.requirment){
        seterror({...error,requirment:'This Field is required*'})
    }else if(!data?.remarks){
        seterror({...error,remarks:'This Field is required*'})
    }else{
        let passData = {...data}
        delete passData?.lead_id
        delete passData?.created_by
        delete passData?.handled_by
        passData['status'] = data?.status?.value

        if(data?._id === undefined){
            const response = await CreateDBRequirmentInfoService(passData)
            if(response?.status === 201){
                toast.success("Requirment Info Created Successfully")
                resetform()
            }
        }else{

            const response = await UpdateDBRequirmentInfoService(passData,state?._id)
            if(response?.status === 200){
                toast.success("Requirment Info Updated Successfully")
                resetform()
            }
        }
    }
  }

  function resetform(){
    setdata({lead_id:'',requirment:'',remarks:'',file:'',status:''})
    seterror({lead_id:'',requirment:'',remarks:'',file:'',status:''})
    navigate(-1)

  }

  async function handlechange(e){
        setdata({...data,[e.target.name]:e.target.value})
        seterror({...error,[e.target.name]:''})
  }

   async function uploadFile(name,v){
      const fd = new FormData()
      fd.append('image',v); 
      console.log("fd here",fd)
      const response = await UploadOfficeSpaceAttachmentService(fd)
      console.log("response here",response?.data)
      if(response?.status === 200){
        setdata({...data,[name]:response?.data?.data})
        seterror({...error,[name]:''})
      }
    }
  

  return (
    <div className='flex'>
        <div className='min-w-[180px] w-[180px] max-w-[180px]'>
        <DatabaseMenu /> 
        </div>
        <div className='ml-4 w-64 mt-4 pr-5 min-h-screen max-h-screen h-screen overflow-y-scroll'>
        <div>
        <GoBack />

        <div className='border-b  pb-2'>
        <h6 className='font-[800] mb-1'>Create / Edit Requirment Info</h6> 
        <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or update the requirment info for the lead.</h6> 
        </div> 

        <TextAreaInput1 
              mandatory={true}
              label={'Requirment'}  
              variant="standard"
              name="requirment"
              type="text"
              value={data.requirment}
              error={error.requirment}
              handlechange={handlechange}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

        <TextAreaInput1 
              mandatory={true}
              label={'Remarks'}  
              variant="standard"
              name="remarks"
              type="text"
              value={data.remarks}
              error={error.remarks}
              handlechange={handlechange}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>  


<h6 className='text-[12px] font-semibold mb-1 mt-1'>Status </h6>
        <div className='border border-slate-300'>
        <Select
           value={data.status} 
           error={error.status}
           bordered={false}
           placeholder="" 
           onChange={(e)=>{setdata({...data,status:statusArr?.find((f)=>f?.value === e)});seterror({...error,status:''})}} 
           className='w-full rounded-[0px]'
           options={statusArr} 
           
           />
          </div> 

          <h6 className='text-[11px] font-[600] mb-1 mt-2' >Upload File</h6>    
          <Uploader image={data?.file} name="resume"  setimagefunc={(e)=>{uploadFile('file',e);seterror({...error,file:''})}}  removeimageuploadfunc = {()=>{setdata({...data,file:''});seterror({...error,file:''})}}/> 


              <div className='flex mt-2 justify-end border-t pt-2'>
               <ButtonOutlinedAutoWidth  btnName="Cancel" onClick={()=>navigate(-1)} />  
               <h6 className='w-[10px]'></h6>
               <ButtonFilledAutoWidth  btnName="Save" onClick={submitform} />  
              </div>    

        </div> 
    </div >
    </div>

  )
}

export default RequirmentInfoListCE