import React, { useEffect, useState } from 'react'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@mui/material'
import FTSLeadMenu from './FTSLeadMenu';
import { Select, DatePicker } from 'antd';
import { GetFTSLeadsSharedByUsService, GetFTSLeadsSharedToUsService } from '../../services/FTSLeadServices';
import { useNavigate } from 'react-router-dom';
import {AiOutlineEdit,AiOutlineFilter,AiOutlineReload} from 'react-icons/ai';
import {useSelector} from 'react-redux'
import { SearchUser } from '../../services/AuthServices';
import { GetDepartmentService } from '../../services/DepartmentServices';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';

function FTSLeadRecieved() {

  const navigate = useNavigate()
  const [page,setpage] = useState(1)
  const [step,setstep] = useState('Pending')
  const [leads,setleads] = useState({datas:[],pagination:{total:0,totalPages:0}})

  const [search,setsearch] = useState({from_date:'',from_date1:'',to_date:'',to_date1:'',department:'',user:'',text:''})

  const [users,setusers] = useState([])
  const [departments,setdepartments] = useState([])
  

  const roles = useSelector(state=>state.Auth.roles)
  

  const stages = [{label:'Pending',value:'Pending'},{label:'Accepted',value:'Accepted'},{label:'Rejected',value:'Rejected'}]
  
  useEffect(()=>{
    getdepartment()
  },[])

  useEffect(()=>{
    getdata()
  },[page,step])

  async function getdata() {
    const response = await GetFTSLeadsSharedToUsService(page,step,search?.from_date1,search?.to_date1,search?.department?.value === undefined ? '' : search?.department?.value,search?.user?.value === undefined ? '' : search?.user?.value,search?.text)
    setleads(response?.data)
  }

  async function resetfunc() {
    setpage(1)
    setsearch({from_date:'',from_date1:'',to_date:'',to_date1:'',department:'',user:''})
    const response = await GetFTSLeadsSharedToUsService(page,step,'','','','','')
    setleads(response?.data)
  } 

  async function onSearch(v){
    const response = await SearchUser(v)
    let arr = []
    response?.data?.forEach((d)=>{
      arr.push({value:d?._id,label:d?.name})
    })
    setusers(arr)
  }

   async function getdepartment(){
      const response = await GetDepartmentService();
      let arr = []
      response.data.datas.forEach((d)=>{
        if(d?.department_name !== 'Finance Team'){
          arr.push({label:d?.department_name,value:d?.id})
        }  
      })
      setdepartments(arr)
    }

   async function applyfilter() {
    setpage(1)
    const response = await GetFTSLeadsSharedToUsService(1,step,search?.from_date1,search?.to_date1,search?.department?.value === undefined ? '' : search?.department?.value,search?.user?.value === undefined ? '' : search?.user?.value,search?.text)
    setleads(response?.data)
   }   

  return (
    <div className='h-screen max-h-screen'>
        <div className='flex'>
            <div className={`min-w-44`}>
                <FTSLeadMenu />
            </div>
            <div  className="w-[98%] px-4 pt-4">
            <div>
          <div className='flex items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total Leads Recieved To FTS ({leads?.pagination?.total})</h6>
            <div className='w-[70%] flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>

                

                <h6 className='mr-2 font-[600]'>{page == 1 ? leads?.datas?.length > 0 ? 1 : 0 :  (page - 1) * leads?.pagination?.limit } - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < leads?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
                <input type='text' placeholder='search' value={search?.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border-[#d0d7de] max-w-[100px] p-1 px-2 rounded text-[11px] focus:ring-0 outline-none border  px-2 w-full  text-[400] text-[12px] focus:bg-white mr-2' />


                  <DatePicker
                  size="small"
                  style={{ fontSize: "11px" }}
                  ampm={false}
                  placeholder="From Date"
                  className="text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2"
                  value={search?.from_date}
                  onChange={(v,v1) => {
                    setsearch({ ...search, from_date: v,from_date1:v1 });
                  }}
                />

                <DatePicker
                  size="small"
                  style={{ fontSize: "11px" }}
                  ampm={false}
                  placeholder="To Date"
                  className="text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2"
                  value={search?.to_date}
                  onChange={(v,v1) => {
                    setsearch({ ...search, to_date: v,to_date1:v1 });
                  }}
                />
                <div className='mr-2'>
                  <Select
                    bordered={false}
                    placeholder={'Department'}
                    className='w-full border border-gray-300 h-[30px] min-w-[100px] max-w-[100px] rounded-[5px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none' 
                    optionFilterProp="children"
                    onChange={(e)=>setsearch({...search,department:departments?.find((f)=>f?.value === e)})}
                    value={search?.department === '' ? null : search?.department}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={departments}
                 />
                </div>

                <div>
                  <Select
                    showSearch
                    bordered={false}
                    placeholder={'User'}
                    className='w-full border border-gray-300 h-[30px] min-w-[100px] max-w-[100px]  rounded-[5px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none' 
                    optionFilterProp="children"
                    onChange={(e)=>setsearch({...search,user:users?.find((f)=>f?.value === e)})}
                    onSearch={onSearch}
                    value={search?.user === '' ? null : search?.user}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={users}
                 />
                </div>

                 <AiOutlineFilter onClick={()=>applyfilter(1)} size={24} className='border mx-1.5 border-slate-600 bg-slate-600 text-[#dedede] p-1 rounded' />
                 <AiOutlineReload onClick={()=>resetfunc(1)} size={24} className='border mx-1.5 border-slate-200 bg-slate-100 text-[#000] p-1 rounded' />

              
              </div>



       
            </div>
          </div>
          <div className='border-b flex items-center justify-start'>
            {stages?.map((s)=>(
              <h6 key={s?.value} onClick={()=>{setstep(s?.value);setpage(1)}} className={`font-[600] ${step === s?.value && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-max px-2 text-[11px] capitalize`}>{s?.label}</h6>
            ))}
          </div>
              <>
                    {leads?.datas?.length > 0 && 
                    <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
                    <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                        <h6 className='sticky top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Contact Person Info</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Created Department</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Assigned Department</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600  border-r'>Remarks</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600  border-r'>Stage</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Status</h6>
                    </div>
                  
                    {leads?.datas?.map((d,i)=>(
                    <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
                        <h6 className='text-[12px] font-[500] w-[5%]  px-2 py-1 border-r border-slate-200 '>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
                        <h6 className='text-[12px] font-[500] w-[15%] px-2 py-1 border-r border-slate-200'>{d?.company_name}
                        {d?.zone !== undefined && <h6 className='text-[10px]'>Zone : {d?.zone}</h6>}</h6>
                        <h6 className='text-[12px] font-[500] w-[15%] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
                          <div>
                          <h6>{d?.contact_name}</h6>  
                          <span>{d?.phone} / {d?.email}  </span>
                          </div>
                        </h6>
                        <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
                          {d?.department_id?.department_name}
                          <h6 className='text-[10px]'>Created By : {d?.lead_owner?.name}</h6>
                          <h6 className='text-[10px] font-[500]'>{d?.previously_handled_by}</h6>
                        </h6>
                        <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
                        <span className='bg-slate-100 px-2 py-1 rounded text-[10px]'>{d?.lead_owner_department?.department_name}</span>
                        </h6>
                        <h6 className='text-[11px]  w-[15%]  px-2 py-1  border-r border-slate-200'>
                           Requirment :  <span className='font-[800]'>{d?.requirment}</span>  /
                           Remarks :  <span className='font-[800]'>{d?.remarks}</span>
          
                           {d?.converted == '1' && <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.lead_id?._id})} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>}
          
                        </h6>   
                        <h6 className='text-[12px]  flex items-start break-word  font-[500] w-[10%] border-r px-2 py-1 border-slate-200 '>
                         <span className='bg-slate-100 px-2 py-1 text-[11px] font-[600] rounded'>{d?.lead_shared_status}</span>
                        </h6>
          
                       
                        <h6 className='text-[12px]   font-[500] w-[10%]  px-2 py-1 truncate border-slate-200 '>
                         {/* {roles?.includes('admin') && */}
                         <AiOutlineEdit className='mt-1 ml-1' onClick={()=>navigate('/fts_leads/list/edit',{state:d})} />
                          {/* } */}
                        </h6>
                    </div>))}
                    </div>}
                    </>
          
                 
                    {leads?.datas?.length === 0 &&
                      <div className='grid place-items-center mt-20  items-center justify-center'>
                      <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' /> 
                      <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                      <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
                    </div>
                    }
          </div>
            </div>
        </div>    

    </div>
  )
}

export default FTSLeadRecieved