import { get } from "../../../helpers/apihelpers";


export const GetDBDashboardService=async()=>{
    try {
        const result = await get(`api/database/db_dashboard/get`,);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetDBRequirmentinfoDashboardService=async(id,step,from_date,to_date,text,limit=25)=>{
    try {
        const result = await get(`api/database/db_dashboard/get_requirment_info/${id}?step=${step}&from_date=${from_date}&to_date=${to_date}&text=${text}&limit=${limit}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}


