import React from 'react';
import {RiUser6Line} from 'react-icons/ri';
import {useLocation,useNavigate } from 'react-router-dom';
import {GrMultiple} from 'react-icons/gr';
import {MdOutlineSpaceDashboard} from 'react-icons/md';
import {TbReport} from 'react-icons/tb';
import { TbPigMoney } from "react-icons/tb";
// import {DatePicker} from 'antd';
import { BsFuelPumpDiesel } from "react-icons/bs";
import { FcStatistics } from "react-icons/fc";



function FinanceMenu() {

  const {pathname} = useLocation();
  const path = pathname.split('/')[pathname.split('/').length - 2]


  const navigate = useNavigate();

  const menu = [
    {name:'Finance Dashboard',icon:MdOutlineSpaceDashboard,path:'/finance/dashboard',id:1,color:''},
    {name:'Finance Report',icon:TbReport,path:'/finance/finance_dashboard_data',id:1,color:''},
    {name:'Finance Statistics',icon:GrMultiple,path:'/finance/finance_dashboard',id:2,color:''},
  ]  
  
  const menu1 = [
    {name:'Invoice Request',icon:RiUser6Line,path:`/finance/invoice`,id:1,color:''},
    {name:'Reimbusment List',icon:TbPigMoney,path:`/finance/reimbusment_list`,id:2,color:''},
  ] 

  const menu2 = [
    {name:'Conveyance Form',icon:BsFuelPumpDiesel,path:`/finance/conveyance_vochuer`,id:1,color:''},
    {name:'Revenue Forecast',icon:FcStatistics,path:`/finance/revenue_forecast`,id:1,color:''},
  ] 


  return (
    <div className='mr-0 min-h-screen max-h-sceen border-r  w-44 px-2' >

        <div className='mb-4 mt-5'>
        <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Finance Option</h6>

            {menu.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}
        </div>

        <div className={` ${path === 'daily_tasks' ? 'mt-5' : 'border-t pt-4 ' }`}>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Invoice Option</h6>
            {menu1.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16} />
                 <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>    
            ))}
        </div>

        <div className={`border-t pt-4`}>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Finance Form</h6>
            {menu2.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16} />
                 <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>    
            ))}
        </div>
    </div>
  )
}

export default FinanceMenu