import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateFTSVerticalReportService=async(data)=>{
    try {
        const result = await post(`api/fts_vertical_report/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFTSVerticalReportService=async(data,id)=>{
    try {
        const result = await put(`api/fts_vertical_report/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFTSVerticalReportGraphDataService=async(department)=>{
    try {
        const result = await get(`api/fts_vertical_report/get_report_data?department=${department}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFTSVerticalReportService=async(page,text,from_date,to_date,department,status)=>{
    try {
        const result = await get(`api/fts_vertical_report/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&department=${department}&status=${status}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFTSVerticalReportService=async(id)=>{
    try {
        const result = await deleteRequest(`api/fts_vertical_report/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadFTSVerticalReportService=async(data)=>{
    try {
        const result = await postfd(`api/fts_vertical_report/upload_excel`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadFTSVerticalReportService=async(page,text,from_date,to_date)=>{
    try {
        const result = await get(`api/fts_vertical_report/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

