import React,{useState,useEffect} from 'react'
import ItMenu from '../ItMenu'
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import { Modal, Select } from 'antd';
import { IconButton } from '@mui/material'
import moment from 'moment';
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { DeleteAssetAssignedService, DownloadAssetAssignedOptionService, GetAssetAssignedService } from '../../../services/ITServices/AssetAssignedOption/AssetAssignedService';
import { RiBillLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { GetDepartmentService, GetUsersByDepartment } from '../../../services/DepartmentServices';
import { LuHardDriveDownload } from "react-icons/lu";
import fileDownload from "js-file-download";
import axios from 'axios';

function AssignAssetOptionList() {


  const {pathname}  = useLocation()
  const path = pathname.split('/')[pathname.split('/').length - 1]

  const user = useSelector(state=>state.Auth)

  const [data,setdata] = useState([])
  const [selecteddata,setselectedData] = useState({})
  const [modal, setModal] = useState(false);
  const [search,setsearch] = useState({text:'',department:'',user:''})

  const [departmentArr,setdepartmentArr] = useState([])
  const [userArr,setuserArr] = useState([])

  const [type,settype] = useState('')
  const [page,setpage] = useState(1)

  const navigate = useNavigate()


  useEffect(()=>{
    getdepartment()
  },[])
 

  useEffect(()=>{
    getdata()
  },[page,type])

    async function getdata(){
        const response = await GetAssetAssignedService(page,search?.text,search?.department?.value !== undefined ? search?.department?.value : '',search?.user?.value !== undefined ? search?.user?.value : '')
        setdata(response.data)
    }  

    async function deletedata(){
        const response = await DeleteAssetAssignedService(selecteddata._id)
        if(response.status === 200){
            setModal(false)
            toast.success("Deleted Successfully")
            getdata()
        }else{
          setModal(false)
          toast.success("Deleted Successfully")
          getdata()
        }
    }

    async function resetfunc() {
      setpage(1)
      setsearch({text:''})
      const response = await GetAssetAssignedService(1,'')
      setdata(response.data)
    }

    async function applyfilterfunction() {
      setpage(1)
      const response = await GetAssetAssignedService(1,search?.text,search?.department?.value !== undefined ? search?.department?.value : '',search?.user?.value !== undefined ? search?.user?.value : '')
      setdata(response.data)
    }


    async function getdepartment() {
      const response = await GetDepartmentService()
      let arr = []
      response?.data?.datas?.forEach((d)=>{
        arr?.push({label:d?.department_name,value:d?.id})
      })
      setdepartmentArr(arr)
    }

    async function getuserbaseddepartment(v) {
      const response = await GetUsersByDepartment(v)
      let arr = []
      response?.data?.datas?.forEach((d)=>{
        arr?.push({label:d?.name,value:d?._id})
      })
      setuserArr(arr)
    }


    function handlechange(v,type){

      console.log("v here",v)
      console.log("type here",type)

       if(type == 'department'){
          let departmentFind = departmentArr?.find((f)=>f?.value === v)
          if(departmentFind !== null){
            setsearch({...search,department:departmentFind,user:''}) 
            getuserbaseddepartment(departmentFind?.value)
          }
       }else if(type == 'user'){
        let userFind = userArr?.find((f)=>f?.value === v)
        console.log("userFind",userFind)

        if(userFind !== null){
          setsearch({...search,user:userFind}) 
        }
     }
    }

  async function downloaddata() {
    const response = await DownloadAssetAssignedOptionService(search?.text,search?.department?.value !== undefined ? search?.department?.value : '',search?.user?.value !== undefined ? search?.user?.value : '')
      let path = response.data.path.replace('public/','')
      handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${path}`,path.split('/')[path.split('/')?.length - 1])
    }

    const handleDownload = (url, filename) => {
      axios.get(url, {
          responseType: 'blob',
      })
          .then((res) => {
              fileDownload(res.data, filename)
          })
    }


  return (
    <div className='flex mx-0 box-border  max-h-screen overflow-y-scroll overflow-x-hidden'>
        <Modal
        keepMounted
        open={modal}
        onClose={()=>setModal(false)}
        width={300}
        footer={false}
        closable={false}
       
      >
        <div >
          <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
          <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
          <div className='flex justify-end mt-3 '>
            {/* <div className='mr-1 w-full'> */}
            <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setModal(false)} />
            {/* </div> */}
            <div  className='ml-2'>
            <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deletedata()}  /> 
            </div>
          </div>
        </div>
        </Modal>
        

        <div>
            <ItMenu />
        </div>
        <div className='px-4 w-[100%]'>
        <div >
        <div className='pt-5'>
        <div className="flex justify-between align-center items-center border-b pb-2 ">
        <span className="font-black text-[14px]">Total User Asset Assigned ({data?.pagination?.total})</span>


        <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>
                   
                        <LuHardDriveDownload onClick={()=>downloaddata()} size={24}  className='mr-1 bg-gray-200 p-1.5' />

                        <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                        <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                        <div>
                        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                        </div>
                    </div>


                    {/* <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                     */}
                    <div className='mr-2 flex'>
                        

                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
                     
                 

                   
                    </div>    

                    <div className='border border-slate-300 rounded-md mr-[4px] py-[2px]'>
                      <Select
                          placeholder='Select Department'
                          bordered={false}
                          size='small'
                          defaultValue={search?.department === '' ? null : search?.department}
                          value={search?.department === '' ? null : search?.department}
                          style={{ width: 110 }}
                          onChange={(v)=>handlechange(v,'department')}
                          options={departmentArr}
                      />
                    </div>

                    <div className='border border-slate-300 rounded-md mr-[4px] py-[2px]'>
                      <Select
                          placeholder='Select User'
                          bordered={false}
                          size='small'
                          defaultValue={search?.user === '' ? null : search?.user}
                          value={search?.user === '' ? null : search?.user}
                          style={{ width: 110 }}
                          onChange={(v)=>handlechange(v,'user')}
                          options={userArr}
                      />
                    </div>


                  
                <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
               
                <div className='ml-2'>
                <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
                </div>
                    </div>

        
        </div>
        </div>

        {data?.datas?.length === 0 &&
        <div className='grid place-items-center mt-20  items-center justify-center'>
          <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
          <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
          <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data added based on your current page option please click on add the data button to see list here.</h6>
        </div>
       }


       {data?.datas?.length > 0 &&
        <div className='border-l overflow-y-scroll max-h-[90vh] border-r'>
          <div className='flex border-b sticky top-0 bg-white'>
            <h6 className='text-[12px] py-1 px-2 border-r w-[60px] font-[600]'>Sl No</h6>
            <h6 className='text-[12px] py-1 px-2 border-r w-[13%] font-[600]'>Employee Name </h6>
            <h6 className='text-[12px] py-1 px-2 border-r w-[12%] font-[600]'>Department</h6>
            <h6 className='text-[12px] py-1 px-2 border-r w-[15%] font-[600]'>Laptop</h6>
            <h6 className='text-[12px] py-1 px-2 border-r w-[15%] font-[600]'>Mobile</h6>
            <h6 className='text-[12px] py-1 px-2 border-r w-[14%] font-[600]'>Mouse</h6>
            <h6 className='text-[12px] py-1 px-2 border-r w-[14%] font-[600]'>Pendrive</h6>
            <h6 className='text-[12px] py-1 px-2 border-r w-[15%] font-[600]'>Hard Disk</h6>
            <h6 className='text-[12px] py-1 px-2 border-r w-[10%] font-[600]'>Created At</h6>
            <h6 className='text-[12px] py-1 px-2 w-[10%] font-[600]'>Action</h6>
          </div> 
          {data?.datas?.map((d,i)=>(
          <div key={d?._id} className='flex border-b'>
            <h6 className='text-[11px] py-1 px-2 border-r w-[60px] font-[500]'>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
            <h6 className='text-[11px] py-1 px-2 border-r w-[13%] font-[500]'>{d?.employee?.name}</h6>
            <h6 className='text-[11px] py-1 px-2 border-r w-[12%] font-[500] items-start flex flex-wrap'>{d?.employee?.department_id?.department_name}</h6>
            <h6 className='text-[11px] py-1 px-2 border-r w-[15%] font-[500] items-start flex flex-wrap'>{d?.laptop?.map((d)=><p className='capitalize mb-[2px] px-1 bg-slate-100 mx-1 py-[2px] font-[500]'>{`${d?.name} - [${d?.asset_id}]`}</p>)}</h6>
            <h6 className='text-[11px] py-1 px-2 border-r w-[15%] font-[500] items-start flex flex-wrap'>{d?.mobile?.map((d)=><p className='capitalize mb-[2px] px-1 bg-slate-100 mx-1 py-[2px] font-[500]'>{`${d?.name} - [${d?.asset_id}]`}</p>)}</h6>
            <h6 className='text-[11px] py-1 px-2 border-r w-[14%] font-[500] items-start flex flex-wrap'>{d?.mouse?.map((d)=><p className='capitalize mb-[2px] px-1 bg-slate-100 mx-1 py-[2px] font-[500]'>{`${d?.name}-   [${d?.asset_id}]`}</p>)}</h6>
            <h6 className='text-[11px] py-1 px-2 border-r w-[14%] font-[500] items-start flex flex-wrap'>{d?.pendrive?.map((d)=><p className='capitalize mb-[2px] px-1 bg-slate-100 mx-1 py-[2px] font-[500]'>{`${d?.name} - [${d?.asset_id}]`}</p>)}</h6>
            <h6 className='text-[11px] py-1 px-2 border-r w-[15%] font-[500] items-start flex flex-wrap'>{d?.harddisk?.map((d)=><p className='capitalize mb-[2px] px-1 bg-slate-100 mx-1 py-[2px] font-[500]'>{`${d?.name} - [${d?.asset_id}]`}</p>)}</h6>
            <h6 className='text-[11px] py-1 px-2 border-r w-[10%] font-[500] items-start flex flex-wrap'>{moment(d?.createdAt)?.format('ll')}</h6>
            <h6 className='text-[11px] flex py-1 px-2 w-[10%] font-[500]'>
            <RiBillLine size={11} className='mr-2' onClick={()=>navigate('detail',{state:d})} /> 
            <AiOutlineEdit size={11} className='mr-2' onClick={()=>navigate('edit',{state:d})} /> 
            {(user?.roles?.includes('admin')) &&
            <AiOutlineDelete size={11} onClick={()=>{setselectedData(d);setModal(true)}} /> 
            }
            </h6>
          </div>))}   
        </div>}


        {/* {data?.datas?.length > 0 &&
        <div className='grid grid-cols-6 gap-1 mt-2'>
            {data?.datas?.map((d,i)=>(
            <div key={d?.id}  className='border items-center relative justify-between px-2 py-1 border-b'>
              <div className='h-[100px] flex items-center justify-center bg-slate-100'>
                {![null,undefined,'','null','undefined']?.includes(d?.image) && <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.image}`} alt="No Img" className='h-[80px] bg-slate-100' />}
                </div>
                <h6 className='text-[13px] font-[500] '>{d.name}</h6>
                <h6 className='text-[12px] font-[500] '>ASSET ID : <span className='font-[800]'>{d.asset_id}</span></h6>
                <h6 className='text-[10px] font-[500] '>Type : <span className='font-[800]'>{d.type}</span></h6>
                <h6 className='text-[10px] font-[500] bg-slate-100 mt-1 p-1'>Created At : <span className='text-[10px] font-[800]'>{moment(d.createdAt).format('LLL')}</span> </h6>
                <div className='absolute right-1 bg-white top-1.5 flex'>
                <Tooltip title="Edit" >
                 <span><AiOutlineEdit size={14} className="cursor-pointer" onClick={()=>{navigate('edit',{state:d})}}/></span>
                </Tooltip>
                <Tooltip title="Delete">
                 <span><AiOutlineDelete size={14} className='ml-2 cursor-pointer'  onClick={()=>{setselecteddata(d);setModal(true)}}/></span>
                </Tooltip>
                </div>
            </div>
            ))}
        </div>} */}
          </div>
        </div>
    </div>
  )
}

export default AssignAssetOptionList