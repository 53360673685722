import React,{useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import GoBack from '../../components/back/GoBack';
import { Select } from 'antd';
import { TextAreaInput1, TextInput } from '../../components/input';
import { ButtonFilledAutoWidth } from '../../components/button';
import { toast } from 'react-hot-toast'
import { BiCheckbox,BiCheckboxSquare } from 'react-icons/bi'
import { useSelector } from 'react-redux';
import { CreateFTSVerticalReportService, UpdateFTSVerticalReportService } from '../../services/ftsVerticalReportServices/FTSVerticalReportServices';
import { GetDepartmentService } from '../../services/DepartmentServices';
import FTSLeadMenu from '../fts_lead/FTSLeadMenu';
import ErrorComponent from '../../components/errorDesign/ErrorComponent';


function FTSVerticalReportCE() {

  const {state,pathname} = useLocation();
  const navigate = useNavigate();


  // const status = [{label:"Pending",value:"Pending"},{label:"Wip",value:"Wip"},{label:"Converted",value:"Converted"},{label:"Lost",value:"Lost"}]
  const status = [{label:"Client Approched",value:"Client Approched"},{label:"Proposal Sent",value:"Proposal Sent"},{label:"Agreement Signed",value:"Agreement Signed"},{label:"Active Req shared by Clients",value:"Active Req shared by Clients"},{label:"References Provided",value:"References Provided"}]

  
  const path = pathname.split('/')[1]

  const [data,setdata] = useState({name:'',mobile:'',company:'',designation:'',requirment:'',status:'',department:''});  
  const [error,seterror] = useState({name:'',mobile:'',company:'',designation:'',requirment:'',status:'',department:''}); 

  const [departments,setdepartments] = useState([])
  
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  useEffect(()=>{
    console.log("state",state)

    if(state?._id !== undefined){

        let d = state 
        delete d.created_by

        setdata({
            ...data,
            ...state,
            department:{label:d?.department?.department_name,value:d?.department?._id}
        })
    }
    getdepartment()
  },[state])

  console.log("data",data)

  async function getdepartment() {
    const response = await GetDepartmentService();
    let arr = []
    
    response.data.datas.forEach((d)=>{
      if(d?.department_name !== 'Finance Team' && d?.department_name !== 'Gallery Team'  && d?.department_name !== 'Shilpa Foundation'){
        arr.push({label:d?.department_name,value:d?.id})
      }  
    })

    setdepartments(arr)
  }

  async function submitform(){
    if(!data.name){
        seterror({...error,name:'This field is required *'})
    }else if(!data.status){
        seterror({...error,status:'This field is required *'})
    }else if(!data.department){
      seterror({...error,department:'This field is required *'})
    }else{
        let sendData = {...data}

        if(data?.created_by !== ''){
           sendData['created_by'] = data?.created_by?.value !== undefined ? data?.created_by?.value : data?.created_by
        }

        if(data?.department?.value !== undefined){
          sendData['department'] = data?.department?.value
        }else{
          delete sendData['department']
        }

        if(state?._id === undefined || state?._id === null){
            const response = await CreateFTSVerticalReportService(sendData)
            if (response.status === 201) {
            resetform()
            toast.success('FTS Vertical Report Created Successfully')
            }   
          
      }else{
        sendData["id"] = state?._id
        const response = await UpdateFTSVerticalReportService(sendData,state?._id)
        if (response.status === 200) {
          resetform()
          navigate(-1)
          toast.success('FTS Vertical Report Updated Successfully')
        }   
      }
    }
  }


  function resetform(){
    setdata({name:'',mobile:'',email:'',company:'',designation:'',requirment:'',status:'',department:''})
    seterror({name:'',mobile:'',email:'',company:'',designation:'',requirment:'',status:'',department:''})
  }

  return (
    <div className='flex '>
        {/* {pathname !== '/conveyance_vochuer_create' &&
        <FinanceMenu />} */}
         <div className={`min-w-44 ${path == 'profile' && 'min-w-[180px] pl-2'}`}>
             <FTSLeadMenu />
            </div>

        <div className={`w-full md:w-72 lg:w-72 px-4 pt-5`} >

            <GoBack /> 
            
            

            <h6 className='font-bold'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} FTS Vertical Report</h6>
            <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> FTS Vertical Report</b> for your reference.</h6>
        



          


            <TextInput 
                label={'Name'}  
                variant="standard"
                name="name"
                type="text"
                mandatory={true}
                error={error.name}
                value={data.name}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                label={'Mobile'}  
                variant="standard"
                name="mobile"
                mandatory={false}
                type="text"
                error={error.mobile}
                value={data.mobile}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                label={'Email'}  
                variant="standard"
                mandatory={false}
                name="email"
                type="text"
                error={error.email}
                value={data.email}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                label={'Company'}  
                variant="standard"
                mandatory={false}
                name="company"
                type="text"
                error={error.company}
                value={data.company}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                label={'Designation'}  
                variant="standard"
                name="designation"
                type="text"
                error={error.designation}
                value={data.designation}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>


              <TextAreaInput1 
                label={'Requirment'}  
                name="requirment"
                type="text"
                mandatory={false}
                error={error.requirment}
                value={data.requirment}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

             
            <>
            <h6 className='text-[11px] font-[600] mb-1 mt-2' >{"Status"}</h6>

            <div className='border border-l-4 border-l-slate-700 border-gray-300 py-[2px]'>
            <Select
                bordered={false}
                size='small'
                allowClear={true}
                onClear={()=>setdata({...data,status:''})}
                defaultValue={data?.status}
                value={data?.status}
                className='w-[100%]'
                options={status}
                onChange={(v)=>{setdata({...data,status:v});seterror({...error,status:''})}}
            />
            </div>
            <ErrorComponent  error={error?.status}/>


            <h6 className='text-[11px] font-[600] mb-1 mt-2' >{"Department"}</h6>

            <div className='border border-l-4 border-l-slate-700 border-gray-300 py-[2px]'>
            <Select
              bordered={false}
              size='small'
              allowClear={true}
              onClear={()=>setdata({...data,department:''})}
              defaultValue={data?.department}
              value={data?.department}
              className='w-[100%]'
              options={departments}
              onChange={(v)=>{setdata({...data,department:departments?.find((f)=>f?.value === v)});seterror({...error,department:''})}}
            />
            </div>

            <ErrorComponent  error={error?.department}/>
            </>



            <div className='mt-5'>
            <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? "UPDATE VERTICAL REPORT DATA" : "ADD VERTICAL REPORT DATA"}  onClick={submitform} />  
            </div>
            
        </div>
   </div> 
  )
}

export default FTSVerticalReportCE