import React,{useEffect, useState,useRef} from 'react'
import { toast } from 'react-hot-toast'
import { ButtonFilledAutoWidth } from '../../../components/button'
import { TextAreaInput1, TextInput } from '../../../components/input'
import { useNavigate, useLocation } from 'react-router-dom'
import GoBack from '../../../components/back/GoBack'
import ItMenu from '../ItMenu'
import { CreateInventoryOptionService, UpdateInventoryOptionService, UploadInventoryOptionService } from '../../../services/ITServices/InventoryOption/InventoryOptionServices'
import Uploader from '../../../components/Uploader'
import { GetFeatureOptionService } from '../../../services/ITServices/FeatureOption/FeatureOptionServices'
import { Select } from 'antd'
import Barcode from 'react-barcode';
import  {toPng} from 'html-to-image';
import { AiOutlineEdit } from 'react-icons/ai'
import image_logo from '../../../assets/images/fcpl_white_logo.png'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'


function InventoryOptionCE() {

  const stickerRef = useRef();
  const stickerRef1 = useRef();

  const [typeSelected,settypeSelected] = useState('')
 
  const [data,setdata] = useState({device_id:'',asset_id:'',barcode:'',name:'',storage:'',ram:'',configuration:'',brand:'',processor:'',purchase_type:'',device_type:'',device_stage:'',device_status:'',image:'',price:'',monthly_rent_price:''});  
  const [error,seterror] = useState({device_id:'',asset_id:'',barcode:'',name:'',storage:'',ram:'',configuration:'',brand:'',processor:'',purchase_type:'',device_type:'',device_stage:'',device_status:'',image:'',price:'',monthly_rent_price:''});
  const [type,settype] = useState("");


  const [brand,setbrand] = useState([])
  const [processor,setprocessor] = useState([])
  const [purchaseType,setpurchaseType] = useState([])
  const [deviceType,setdeviceType] = useState([])
  const [deviceStage,setdeviceStage] = useState([])
  const [deviceStatus,setdeviceStatus] = useState([])
  
  const {pathname}  = useLocation()
  const path = pathname.split('/')[pathname.split('/').length - 2]
  const path1 = pathname.split('/')[pathname.split('/').length - 1]

  const {state} = useLocation();
  const navigate = useNavigate();

  useEffect(()=>{
    if(path == 'laptop'){
      settype("Laptop")
    }
    if(path == 'desktop'){
      settype("Desktop")
    }
    if(path == 'cpu'){
      settype("CPU")
    }
    if(path == 'mobile'){
      settype("Mobile")
    }
    if(path == 'mouse'){
      settype("Mouse")
    }
    if(path == 'keyboard'){
      settype("Keyboard")
    }
    if(path == 'hard_disk'){
      settype("HardDisk")
    }
    if(path == 'cable'){
      settype("Cable")
    }
    if(path == 'laptop_charger'){
      settype("Laptop Charger")
    }
    if(path == 'mobile_charger'){
      settype("Mobile Charger")
    }
    if(path == 'pendrive'){
      settype("Pendrive")
    }
   },[path])

  useEffect(()=>{
    if(state?._id !== null && state?._id !== undefined && path1 !== 'create'){

      let passData = {
        ...state 
      }

      if(state?.processor?._id !== undefined){
        passData['processor'] = {label:state?.processor?.name,value:state?.processor?._id}
      }

      if(state?.brand?._id !== undefined){
        passData['brand'] = {label:state?.brand?.name,value:state?.brand?._id}
      }

      if(state?.purchase_type?._id !== undefined){
        passData['purchase_type'] = {label:state?.purchase_type?.name,value:state?.purchase_type?._id}
      }

      if(state?.device_type?._id !== undefined){
        passData['device_type'] = {label:state?.device_type?.name,value:state?.device_type?._id}
      }

      if(state?.device_stage?._id !== undefined){
        passData['device_stage'] = {label:state?.device_stage?.name,value:state?.device_stage?._id}
      }

      if(state?.device_status?._id !== undefined){
        passData['device_status'] = {label:state?.device_status?.name,value:state?.device_status?._id}
      }

      setdata({...data,...passData})
    }else{
      if(state?._id !== undefined){
        let passData = {
          ...state 
        }

        delete passData?._id
        delete passData?.asset_id
        delete passData?.device_id
  
        if(state?.processor?._id !== undefined){
          passData['processor'] = {label:state?.processor?.name,value:state?.processor?._id}
        }
  
        if(state?.brand?._id !== undefined){
          passData['brand'] = {label:state?.brand?.name,value:state?.brand?._id}
        }
  
        if(state?.purchase_type?._id !== undefined){
          passData['purchase_type'] = {label:state?.purchase_type?.name,value:state?.purchase_type?._id}
        }
  
        if(state?.device_type?._id !== undefined){
          passData['device_type'] = {label:state?.device_type?.name,value:state?.device_type?._id}
        }
  
        if(state?.device_stage?._id !== undefined){
          passData['device_stage'] = {label:state?.device_stage?.name,value:state?.device_stage?._id}
        }
  
        if(state?.device_status?._id !== undefined){
          passData['device_status'] = {label:state?.device_status?.name,value:state?.device_status?._id}
        }
  
        setdata({...data,...passData})
      }
    }
  },[state])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }


  async function submitform(){
    if(!data.name){
        seterror({...error,name:'Name field is required *'})
    }else if(!data.device_id){
      seterror({...error,device_id:'Device ID field is required *'})
    }else{
      if(state?._id === undefined || state?._id === null || path1 === 'create'){
        let send_data = {...data}

        delete send_data.brand
        delete send_data.processor
        delete send_data.purchase_type
        delete send_data.device_type
        delete send_data.device_stage
        delete send_data.device_status

        if(data?.brand !== ''){
          send_data['brand'] = data?.brand?.value
        }
        if(data?.processor !== ''){
          send_data['processor'] = data?.processor?.value
        }
        if(data?.purchase_type !== ''){
          send_data['purchase_type'] = data?.purchase_type?.value
        }
        if(data?.device_type !== ''){
          send_data['device_type'] = data?.device_type?.value
        }
        if(data?.device_stage !== ''){
          send_data['device_stage'] = data?.device_stage?.value
        }
        if(data?.device_status !== ''){
          send_data['device_status'] = data?.device_status?.value
        }

        send_data['type'] = type

        console.log("send_data",send_data)
        const response = await CreateInventoryOptionService(send_data)
        if (response.status === 201) {
          resetform()
          toast.success(`${type} Created Successfully`)
        }   
        if(response.status === 422){
          seterror({...error,name:response?.data?.errors?.name})
        } 
      }else{
        let send_data = {...data}
        send_data["_id"] = state?._id

        delete send_data.brand
        delete send_data.processor
        delete send_data.purchase_type
        delete send_data.device_type
        delete send_data.device_stage
        delete send_data.device_status

        if(data?.brand !== ''){
          send_data['brand'] = data?.brand?.value
        }
        if(data?.processor !== ''){
          send_data['processor'] = data?.processor?.value
        }
        if(data?.purchase_type !== ''){
          send_data['purchase_type'] = data?.purchase_type?.value
        }
        if(data?.device_type !== ''){
          send_data['device_type'] = data?.device_type?.value
        }
        if(data?.device_stage !== ''){
          send_data['device_stage'] = data?.device_stage?.value
        }

        if(data?.device_status !== ''){
          send_data['device_status'] = data?.device_status?.value
        }


        const response = await UpdateInventoryOptionService(send_data,state?._id)
        if (response.status === 200) {
          resetform()
          navigate(-1)
          toast.success(`${type} Updated Successfully`)
        }   
        if(response.status === 422){
          seterror({...error,name:response?.data?.errors?.name})
        } 
      }
  }
  }

  function resetform(){
    setdata({device_id:'',asset_id:'',barcode:'',name:'',storage:'',ram:'',configuration:'',brand:'',processor:'',purchase_type:'',device_type:'',device_stage:'',device_status:'',image:'',price:'',monthly_rent_price:''})
    seterror({device_id:'',asset_id:'',barcode:'',name:'',storage:'',ram:'',configuration:'',brand:'',processor:'',purchase_type:'',device_type:'',device_stage:'',device_status:'',image:'',price:'',monthly_rent_price:''})
  }

  async function uploadfilefunc(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadInventoryOptionService(fd)
    if(response?.status === 200){
    setdata({...data,[name]:response?.data?.data})
    }
  }


  async function handleselect(v,type){
    if(type == 'processor'){
      let findData = processor.find((f)=>f.value === v)
      setdata({...data,[type]:findData})
    }
    if(type == 'brand'){
      let findData = brand.find((f)=>f.value === v)
      setdata({...data,[type]:findData})
    }
    if(type == 'purchase_type'){
      let findData = purchaseType.find((f)=>f.value === v)
      setdata({...data,[type]:findData})
    }
    if(type == 'device_type'){
      let findData = deviceType.find((f)=>f.value === v)
      setdata({...data,[type]:findData})
    }
    if(type == 'device_stage'){
      let findData = deviceStage.find((f)=>f.value === v)
      setdata({...data,[type]:findData})
    }
    if(type == 'device_status'){
      let findData = deviceStatus.find((f)=>f.value === v)
      setdata({...data,[type]:findData})
    }
 }

 async function searchData(v,type){
  if(type == 'Processor'){
    const response = await GetFeatureOptionService(1,v,type)
    let d = response?.data?.datas
    let arr = []
    d.forEach((d1)=>{
       arr.push({label:d1?.name,value:d1?._id})
    })
    setprocessor(arr)
  }

  if(type == 'Brand'){
    const response = await GetFeatureOptionService(1,v,type)
    let d = response?.data?.datas
    let arr = []
    d.forEach((d1)=>{
       arr.push({label:d1?.name,value:d1?._id})
    })
    setbrand(arr)
  }

  if(type == 'Purchase Type'){
    const response = await GetFeatureOptionService(1,v,type)
    let d = response?.data?.datas
    let arr = []
    d.forEach((d1)=>{
       arr.push({label:d1?.name,value:d1?._id})
    })
    setpurchaseType(arr)
  }

  if(type == 'Device Type'){
    const response = await GetFeatureOptionService(1,v,type)
    let d = response?.data?.datas
    let arr = []
    d.forEach((d1)=>{
       arr.push({label:d1?.name,value:d1?._id})
    })
    setdeviceType(arr)
  }

  if(type == 'Device Stage'){
    const response = await GetFeatureOptionService(1,v,type)
    let d = response?.data?.datas
    let arr = []
    d.forEach((d1)=>{
       arr.push({label:d1?.name,value:d1?._id})
    })
    setdeviceStage(arr)
  }

  if(type == 'Device Status'){
    const response = await GetFeatureOptionService(1,v,type)
    let d = response?.data?.datas
    let arr = []
    d.forEach((d1)=>{
       arr.push({label:d1?.name,value:d1?._id})
    })
    setdeviceStatus(arr)
  }
 }


 function downloadSticker(){
  
  if (typeSelected == 1 && stickerRef.current) {
    toPng(stickerRef.current)
        .then((dataUrl) => {
            const link = document.createElement('a');
            link.download = `sticker.png`;
            link.href = dataUrl;
            link.click();
        })
        .catch((err) => {
            console.error('Error generating sticker:', err);
        });
  }

  if (typeSelected == 2 && stickerRef1.current) {
    toPng(stickerRef1.current)
        .then((dataUrl) => {
            const link = document.createElement('a');
            link.download = `sticker.png`;
            link.href = dataUrl;
            link.click();
        })
        .catch((err) => {
            console.error('Error generating sticker:', err);
        });
  }

 }
  

  return (
    <div className='flex '>


    <div >
      <ItMenu />
    </div> 
   
    <div className=' px-4 pt-5' >


        <div className='sm:w-full lg:w-72'>
        <GoBack /> 

        <h6 className='font-[700]'>{(state?._id !== null && state?._id !== undefined && path1 !== 'create') ? 'Edit' : 'Add'} {type}</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> {type}</b> for your comapny employees.</h6>
        </div>


        <div className='flex border-box min-w-[450px] max-w-[450px]'>
            <div className='min-w-[225px] max-w-[225px]  mr-5'>
            <TextInput 
                label={'Name'}  
                variant="standard"
                name="name"
                type="text"
                mandatory={true}
                error={error.name}
                value={data.name}
                handlechange={handlechange}
                placeholder="Enter your name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>


            <TextInput 
                label={'Device Id'}  
                variant="standard"
                name="device_id"
                type="text"
                error={error.device_id}
                value={data.device_id}
                mandatory={true}
                handlechange={handlechange}
                placeholder="Enter your name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            {(state?._id !== null && state?._id !== undefined && path1 !== 'create') &&
            <TextInput 
                label={'Asset Id (Unique)'}  
                variant="standard"
                name="asset_id"
                type="text"
                readOnly={true}
                error={error.asset_id}
                value={data.asset_id}
                mandatory={true}
                handlechange={handlechange}
                placeholder="Enter your name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>}

              {['Laptop','CPU','Pendrive','Mobile','Desktop','HardDisk']?.includes(type) && 
              <>
              <TextInput 
                label={'RAM'}  
                variant="standard"
                name="ram"
                type="text"
                mandatory={true}
                error={error.ram}
                value={data.ram}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

              <TextInput 
                label={'Storage'}  
                variant="standard"
                name="storage"
                type="text"
                mandatory={true}
                error={error.storage}
                value={data.storage}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

              <TextAreaInput1 
                label={'Configuration'}  
                variant="standard"
                name="configuration"
                type="text"
                mandatory={true}
                error={error.configuration}
                value={data.configuration}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>
              </>}
            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Image</h6>    
            <Uploader image={data?.image}  setimagefunc={(e)=>{uploadfilefunc(e,'image');seterror({...error,image:''})}}  removeimageuploadfunc = {()=>{setdata({...data,image:''});seterror({...error,image:''})}}/>    

            </div>
            <div className='min-w-[225px] max-w-[225px]'>


            {['Laptop','CPU','Mobile','Desktop']?.includes(type) && 
            <>
            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Processor</h6>
              <div className='w-[100%]'>
                <Select 
                  value={data?.processor}
                  allowClear={true}
                  type="processor"
                  filterOption={false}
                  showSearch={true}
                  onSearch={(e)=>searchData(e,'Processor')}
                  onChange={e=>{handleselect(e,'processor')}}
                  options={processor}
                  bordered={false}
                  className='w-[100%] border-l-4 border-l-slate-700 border border-slate-300'
                />
            </div>
            </>}

            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Brand</h6>
            <div className='w-[100%]'>
                <Select 
                  value={data.brand}
                  allowClear={true}
                  type="brand"
                  filterOption={false}
                  showSearch={true}
                  onSearch={(e)=>searchData(e,'Brand')}
                  onChange={e=>{handleselect(e,'brand')}}
                  options={brand}
                  bordered={false}
                  className='w-[100%] border-l-4 border-l-slate-700 border border-slate-300'
                />
            </div>

            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Purchase Type</h6>
            <div className='w-[100%]'>
                <Select 
                  value={data.purchase_type}
                  allowClear={true}
                  type="purchase_type"
                  filterOption={false}
                  showSearch={true}
                  onSearch={(e)=>searchData(e,'Purchase Type')}
                  onChange={e=>{handleselect(e,'purchase_type')}}
                  options={purchaseType}
                  bordered={false}
                  className='w-[100%] border-l-4 border-l-slate-700 border border-slate-300'
                />
            </div>

            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Device Type</h6>
            <div className='w-[100%]'>
                <Select 
                  value={data.device_type}
                  allowClear={true}
                  type="device_type"
                  filterOption={false}
                  showSearch={true}
                  onSearch={(e)=>searchData(e,'Device Type')}
                  onChange={e=>{handleselect(e,'device_type')}}
                  options={deviceType}
                  bordered={false}
                  className='w-[100%] border-l-4 border-l-slate-700 border border-slate-300'
                />
            </div>

            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Device Stage</h6>
            <div className='w-[100%]'>
                <Select 
                  value={data.device_stage}
                  allowClear={true}
                  type="device_stage"
                  filterOption={false}
                  showSearch={true}
                  onSearch={(e)=>searchData(e,'Device Stage')}
                  onChange={e=>{handleselect(e,'device_stage')}}
                  options={deviceStage}
                  bordered={false}
                  className='w-[100%] border-l-4 border-l-slate-700 border border-slate-300'
                />
            </div>

            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Device Status</h6>
            <div className='w-[100%]'>
                <Select 
                  disabled={path1 == 'edit'}
                  value={data.device_status}
                  allowClear={true}
                  type="device_status"
                  filterOption={false}
                  showSearch={true}
                  onSearch={(e)=>searchData(e,'Device Status')}
                  onChange={e=>{handleselect(e,'device_status')}}
                  options={deviceStatus}
                  bordered={false}
                  className='w-[100%] border-l-4 border-l-slate-700 border border-slate-300'
                />
            </div>

            <TextInput 
                label={'Price'}  
                variant="standard"
                name="price"
                type="text"
                mandatory={true}
                error={error.price}
                value={data.price}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  


              <TextInput 
                label={'Monthly Rent (Amount)'}  
                variant="standard"
                name="monthly_rent_price"
                type="text"
                mandatory={true}
                error={error.monthly_rent_price}
                value={data.monthly_rent_price}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

                
                <div className='mt-2'>
                  <h6 className='text-[11px] font-[800]'>Select Type For Download</h6>
                  
                  <div className='flex mt-2 items-center'>
                    <div onClick={()=>settypeSelected(1)} className='flex items-center'>
                    {typeSelected != '1' ? <BiCheckbox />  : <BiCheckboxSquare /> }
                    <h6 className='text-[11px] font-[700] ml-1'>Large</h6>
                    </div>

                    <div onClick={()=>settypeSelected(2)} className='flex ml-2 items-center'> 
                    {typeSelected != '2' ? <BiCheckbox />  : <BiCheckboxSquare /> }
                    <h6 className='text-[11px] font-[700] ml-1'>Small</h6>
                    </div>
                  </div>

                </div>

              <div className='flex'>
                {typeSelected == '1' &&
                <div ref={stickerRef} className='bg-slate-700 p-2 border h-[80px] mt-2 w-[200px] rounded  overflow-hidden'>
                  <div className='flex items-center'>
                    <img alt="image" style={{width:'20px',height:'20px',resize:'contain'}} src={image_logo} />
                    <h6 className='text-[11px] text-center w-[100%] text-center font-[900] text-white'>Property of Fidelitus</h6>
                  </div>
                  <div className='bg-white p-1 mt-1'>
                    <h1 className='text-[13px] px-1 uppercase text-center font-[900]'>{data?.asset_id}</h1>
                  </div>
                </div>}

                {typeSelected == '2' &&
                <div ref={stickerRef1} className='bg-slate-700 p-2 border h-[80px] mt-2 w-[100px] rounded  overflow-hidden'>
                  <div className='flex items-center justify-center'>
                    <img alt="image" style={{width:'20px',height:'20px',resize:'contain'}} src={image_logo} />
                  </div>
                  <div className='bg-white p-1 mt-1'>
                    <h1 className='text-[8px] break-all px-1 uppercase text-center font-[900]'>{data?.asset_id}</h1>
                  </div>
                </div>}

                <AiOutlineEdit onClick={downloadSticker} className='ml-2 mt-2 cursor-pointer' />
              </div>

            </div>

            
            </div>

         
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined && path1 !== 'create') ? `Update ${type}` : `Add ${type}`}  onClick={submitform} />  
        </div>


    </div>
    </div>
  )
}

export default InventoryOptionCE