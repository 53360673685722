import React, { useEffect, useState } from 'react'
import { Tooltip } from '@mui/material'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import DatabaseMenu from '../DatabaseMenu';
// import { DeletePlotTypeService, GetPlotTypeService } from '../../../../../services/database/databaseoptions/PlotTypeServices';
import {AiOutlineDelete, AiOutlineEdit} from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import moment from 'moment';
import { DatePicker,Modal,Select } from 'antd';
import { IconButton, } from '@mui/material'
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { MdOutlineCloudUpload } from "react-icons/md";
import { AiOutlineFilter,AiOutlineReload,AiOutlineFilePdf,AiOutlineFileExcel,AiOutlinePlus } from "react-icons/ai";
import { DeleteAdminManagedOfficeService, DeleteManagedOfficeService, GenerateManagedOfficePPTService, GetManagedOfficeService, UpdateManagedOfficeService, UploadManagedOfficeExcelService } from '../../../services/database/mainoptions/ManagedOfficeServices';
import {BiCheckbox, BiCheckboxSquare} from 'react-icons/bi';
import {RiBillLine} from 'react-icons/ri';
import Uploader from '../../../components/Uploader';
import { GetPropertyTypeService } from '../../../services/database/databaseoptions/PropertyTypeServices';
import { GetPropertyStatusService } from '../../../services/database/databaseoptions/PropertyStatusServices';
import { GetPropertyZoneService } from '../../../services/database/databaseoptions/PropertyZoneServices';
import { FilterLocationBasedZoneService } from '../../../services/database/databaseoptions/LocationBasedZoneServices';
import { SearchUser } from '../../../services/AuthServices';
import { LiaFilePowerpointSolid } from "react-icons/lia";
import { MdRestore } from "react-icons/md";

// import { DeleteAdminPropertyTypeService, DeletePropertyTypeService, GetPropertyTypeService } from '../../../../services/database/databaseoptions/PropertyTypeServices';
// import { DeleteAdminApprovedTypeService, DeleteApprovedTypeService, GetApprovedTypeService } from '../../../../services/database/databaseoptions/ApprovedTypeServices';

function ManagedOfficeList() {

  const user = useSelector(state=>state.Auth)
  const location = useLocation();

  const roles = useSelector(state=>state.Auth.roles)
  let path = location?.pathname?.split('/')[2]


  const [table_heading,settable_heading] = useState({base_type:true,property_category:true,managed_office_name:true,zone:true,location_of_property:true,longitude:false,latitude:false,google_coordinate_link:true,managed_office_address:false,landmark_to_property:false,seater_offered:true,rent_per_seat:false,escalation:false,amenities_available:false,amenities:true,no_of_car_parking_slots:true,car_parking_charges:false,security_deposit:false,lock_in_period:false,lease_term_tenure:false,possession_type:false,facility_type:false,power_and_backup:false,total_built_up_area:false,no_of_floors:false,per_floor_area:false,area_offered:false,floor_offered:false,rent_per_sqft:false,maintenace_charges:false,interior_details:false,oc_availability:false,status:false,primary_contact_name:false,primary_contact_no:false,secondary_contact_name:false,secondary_contact_no:false})

  const navigate = useNavigate()
  const [data,setdata] = useState({pagination:{total:0}})
  const [selected_data,setselected_data] = useState({})
  const [data_selected_list,setdata_selected_list] = useState([])
  const [modal,setmodal] = useState(false)
  const [modal1,setmodal1] = useState(false)
  const [modal2,setmodal2] = useState(false)
  const [page,setpage] = useState(1)
  const [step,setstep] = useState(1)
  const [search,setsearch] = useState({from_date:'',to_date:'',from_date1:'',to_date1:'',text:'',bast_type:'',status:'',zone:'',location_based_zone:'',user:''})


  const [baseTypeArr,setbaseTypeArr] = useState([])
  const [statusArr,setStatusArr] = useState([])
  const [zoneArr,setzoneArr] = useState([])
  const [locationBasedZoneArr,setlocationBasedZoneArr] = useState([])
  const [userArr,setuserArr] = useState([])

  const [excel,setexcel] = useState({file:'',error:''})

  useEffect(()=>{
    getdefaultoptions()
  },[])

  useEffect(()=>{
    getdata()
  },[page,step])

  async function getdefaultoptions(){
    const response = await GetPropertyTypeService(1,'','','',1)
    const response1 = await GetPropertyStatusService(1,'','','',1)
    const response2 = await GetPropertyZoneService(1,'','','',1)

    let arr = response?.data?.datas?.map((d)=> {return {"label":d?.name,"value":d?._id}})
    let arr1 = response1?.data?.datas?.map((d)=> {return {"label":d?.name,"value":d?._id}})
    let arr2 = response2?.data?.datas?.map((d)=> {return {"label":d?.name,"value":d?._id}})

    setbaseTypeArr(arr)
    setStatusArr(arr1)
    setzoneArr(arr2)
  }

  async function getlocationbasedzoneoptions(id) {
    const response = await FilterLocationBasedZoneService(id)
    let arr = response?.data?.datas?.map((d)=> {return {"label":d?.name,"value":d?._id}})
    setlocationBasedZoneArr(arr)
  }

  async function getdata(){
    const response = await GetManagedOfficeService(
      page,
      search?.from_date1,
      search?.to_date1,
      search?.text,
      step,search?.base_type?.value !== undefined ? search?.base_type?.value : '',
      search?.status?.value !== undefined ? search?.status?.value : '',
      search?.zone?.value !== undefined ? search?.zone?.value : '',
      search?.location_based_zone?.value !== undefined ? search?.location_based_zone?.value : '',
      search?.user?.value !== undefined ? search?.user?.value : ''
    )
    setdata(response?.data)
  }
 
  async function applyfilterfunction(){
    setpage(1)
    const response = await GetManagedOfficeService(
      1,
      search?.from_date1,
      search?.to_date1,
      search?.text,
      step,search?.base_type?.value !== undefined ? search?.base_type?.value : '',
      search?.status?.value !== undefined ? search?.status?.value : '',
      search?.zone?.value !== undefined ? search?.zone?.value : '',
      search?.location_based_zone?.value !== undefined ? search?.location_based_zone?.value : '',
      search?.user?.value !== undefined ? search?.user?.value : ''
    )
    setdata(response?.data)

  }

  async function deletefunc(){
    const response = await DeleteManagedOfficeService(selected_data?._id)
    if(response?.status === 200){
      toast.success("Managed Office Deleted Successfully!")
      getdata()
      setmodal(!modal)
    }
  }

  async function admindeletefunc(){
    const response = await DeleteAdminManagedOfficeService(selected_data?._id)
    if(response?.status === 200){
      toast.success("Managed Office Deleted Successfully [Pemanently]!")
      getdata()
      setmodal(!modal)
    }
  }

  async function onSearch(v){
    const response = await SearchUser(v)
    let arr = []
    response?.data?.forEach((d)=>{
      arr.push({value:d?._id,label:d?.name})
    })
    setuserArr(arr)
  }

  async function handleSelect(v,type) {
    if(type === 'base_type'){
      let findData = baseTypeArr?.find((f)=>f?.value === v)
      if(findData !== null){
        setsearch({...search,base_type:findData})
      }
    }else if(type === 'status'){
      let findData = statusArr?.find((f)=>f?.value === v)
      if(findData !== null){
        setsearch({...search,status:findData})
      }
    }else if(type === 'zone'){
      let findData = zoneArr?.find((f)=>f?.value === v)
      if(findData !== null){
        getlocationbasedzoneoptions(findData?.value)
        setsearch({...search,zone:findData})
      }
    }else if(type === 'user'){
      let findData = userArr?.find((f)=>f?.value === v)
      if(findData !== null){
        setsearch({...search,user:findData})
      }
    }else if(type === 'location_based_zone'){
      let findData = locationBasedZoneArr?.find((f)=>f?.value === v)
      if(findData !== null){
        setsearch({...search,location_based_zone:findData})
      }
    }
  }


  async function uploadExcel() {
    if(excel?.file == ''){
      setexcel({...excel,error:"Please Choose Excel File to Upload"})
    }else{
      const response = await UploadManagedOfficeExcelService({file:excel?.file})
      if(response.status === 200){
        setmodal1(false)
        toast.success("Excel Uploaded Successfully!")
        getdata()
      }else{
        setmodal1(false)
        toast.error("Error While Uploading Excel")
        getdata()
      }
    }
    
  }

  async function resetform() {
    setpage(1)
    setsearch({from_date:'',to_date:'',from_date1:'',to_date1:'',text:'',bast_type:'',status:'',zone:'',location_based_zone:'',user:''})
    const response = await GetManagedOfficeService(1,'','','',step,'','','','')
    setdata(response?.data)
  }

  async function adminrestoredatafunc() {
    const response = await UpdateManagedOfficeService({is_deleted:false},selected_data?._id)
    if(response?.status === 200){
      toast.success("Managed Office Data Restored Successfully ")
    }
    setmodal2(!modal2)
    getdata()
  }

  async function generatePPTfunc(){
    if(data_selected_list?.length > 0){
      const response = await GenerateManagedOfficePPTService({selected_list:data_selected_list})
      console.log("response?.data",response?.data)
    }else{
      toast.error("Select Data To Geenrate PPT")
    }
  }

  function dataSelectedList(v){
    console.log("Im here",v)
    let oldDataSelected = [...data_selected_list]
    if(oldDataSelected.length > 0){
     let filterData = oldDataSelected.find((f)=>f == v)
     console.log("filterData",filterData)
     if(filterData !== null && filterData !== undefined){
      oldDataSelected = oldDataSelected.filter((f)=>f != v)
     }else{
      oldDataSelected.push(v)
     }
     setdata_selected_list(oldDataSelected)
    }else{
      setdata_selected_list([v])
    }
  }

  console.log("data_selected_list",data_selected_list)

  return (
    <div className='overflow-hidden h-screen max-h-screen'>
        <Modal open={modal} className='absolute top-0 left-[42%]' width={300} footer={false} closable={false}>
           <div>
             <h6 className='text-[12px] font-[700]'>Delete Managed Office Data</h6>
             <h6 className='text-[10px] bg-slate-100 p-[4px] leading-[14px]'>Are you sure want to delete the selected managed office data once deleted will not be retrieved</h6>
             
             <h6 className='text-[12px] mt-2'>Name : <span className='font-[700]'>{selected_data?.managed_office_name}</span></h6>
             <h6 className='text-[12px]'>Zone : <span className='font-[700]'>{selected_data?.zone?.name}</span> / Location Of Property : <span className='font-[700]'>{selected_data?.location_of_property?.name}</span></h6>

             <div className='flex mt-2 justify-end border-t pt-2'>
               <ButtonOutlinedAutoWidth  btnName="Cancel" onClick={()=>setmodal(false)} />  
               <h6 className='w-[10px]'></h6>
               <ButtonFilledAutoWidth  btnName="Save" onClick={step == 1 ? ()=>deletefunc() : ()=>admindeletefunc()} />  
              </div>    
           </div>
        </Modal>


        <Modal open={modal2} className='absolute top-0 left-[42%]' width={300} footer={false} closable={false}>
           <div>
             <h6 className='text-[12px] font-[700]'>Restore Managed Office Data</h6>
             <h6 className='text-[10px] bg-slate-100 p-[4px] leading-[14px]'>Are you sure want to restore the selected managed office data once restored will be visible for you to edit and update</h6>
             
             <h6 className='text-[12px] mt-2'>Name : <span className='font-[700]'>{selected_data?.managed_office_name}</span></h6>
             <h6 className='text-[12px]'>Zone : <span className='font-[700]'>{selected_data?.zone?.name}</span> / Location Of Property : <span className='font-[700]'>{selected_data?.location_of_property?.name}</span></h6>

             <div className='flex mt-2 justify-end border-t pt-2'>
               <ButtonOutlinedAutoWidth  btnName="Cancel" onClick={()=>setmodal(false)} />  
               <h6 className='w-[10px]'></h6>
               <ButtonFilledAutoWidth  btnName="Save" onClick={()=>adminrestoredatafunc()} />  
              </div>    
           </div>
        </Modal>

        <Modal open={modal1} width={350} closable={false} footer={false} className='absolute top-0 left-[40%]'>
          <div className='absolute right-6'>
           <a href="https://fcplfmsbucket.s3.ap-south-1.amazonaws.com/Managed+space+excel+format+(1).xlsx" downlaod> <h6 className='text-[10px] cursor-pointer font-[700] flex underline items-center'><AiOutlineFileExcel className='mr-1' /> Download Sample</h6></a>
          </div>
          <h6 className="font-bold text-[13px]  mb-2 w-full">Upload Excel</h6>
          
          <Uploader image={excel?.file}  setimagefunc={(e)=>{setexcel({...excel,file:e,error:''})}}  removeimageuploadfunc = {()=>setexcel({...excel,file:'',error:''})} />

          <div className='mt-2 flex items-center'>
            <ButtonOutlinedAutoWidth btnName="Close" onClick={()=>setmodal1(false)}/>
            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName="Save" onClick={()=>uploadExcel()} />
            </div>
          </div>
       </Modal> 
        
        <div  className="w-[100%] flex">
            <div className='min-w-[180px] max-w-[180px]'>
            <DatabaseMenu />
            </div>
            <div  className='px-5 py-4 w-[87%] '>
              <div className='border-b pb-2'>
                <div className='flex items-center justify-between '>
                    <h6 className='font-[700] text-[14px] '>Total Managed Office List ({data?.pagination?.total})</h6>
                    <div className='flex items-center'>
                    <div className='flex items-center text-[12px] mr-2'>
                    
                        <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                        <IconButton onClick={resetform}><BsArrowRepeat size={16} /></IconButton>

                        <div>
                        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                        </div>
                    </div>
                    
                    <div className='ml-2'>
                        <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')} /> 
                    </div>
                    </div>
                </div>

                <div className='flex mt-2 border-t pt-2 -pl-4'>
                    {/* <div className='mt-0 -ml-2 mr-[4px]'>
                        <Select 
                        bordered={false}
                        placeholder="Base Type"
                        value={search?.base_type === '' ? null : search?.base_type}
                        options={baseTypeArr}
                        onChange={(e)=>handleSelect(e,'base_type')}
                        className='w-[110px] border h-[28px] border-slate-300 ml-2 rounded-[5px]'

                        />

            
                    </div> */}

                    <div className='mt-0 mr-[4px]'>
                        <Select 
                            bordered={false}
                            placeholder="Status"
                            value={search?.status === '' ? null : search?.status}
                            options={statusArr}
                            onChange={(e)=>handleSelect(e,'status')}
                            className='w-[110px] border h-[28px] border-slate-300 ml-2 rounded-[5px]'

                        />

                
                    </div>

                    <div className='mt-0 mr-[4px]'>
                        <Select 
                        bordered={false}
                        placeholder="Zone"
                        value={search?.zone === '' ? null : search?.zone}
                        options={zoneArr}
                        onChange={(e)=>handleSelect(e,'zone')}
                        className='w-[110px] border h-[28px] border-slate-300 ml-2 rounded-[5px]'

                        />

            
                    </div>  

                    <div className='mt-0 mr-[4px]'>
                        <Select 
                        bordered={false}
                        placeholder="Location"
                        value={search?.location_based_zone === '' ? null : search?.location_based_zone}
                        options={locationBasedZoneArr}
                        onChange={(e)=>handleSelect(e,'location_based_zone')}
                        className='w-[110px] border h-[28px] border-slate-300 ml-2 rounded-[5px]'

                        />

            
                    </div>  

                    <div className='mt-0 mr-[4px]'>
                        <Select 
                        showSearch
                        bordered={false}
                        placeholder="User"
                        value={search?.user === '' ? null : search?.user}
                        options={userArr}
                        onChange={(e)=>handleSelect(e,'user')}
                        onSearch={onSearch}
                        filterOption={false}
                        className='w-[110px] border h-[28px] border-slate-300 ml-2 rounded-[5px]'

                        />

            
                    </div>


                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 

                    
                    <div className='mr-2'>
                        

                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
                
                    </div>   

                    <AiOutlineFilter onClick={applyfilterfunction} size={24} className='cursor-pointer bg-slate-600 mr-[5px] text-white p-[6px] rounded-[4px]' /> 
                    <AiOutlineReload onClick={resetform} size={24} className='cursor-pointer bg-slate-600 mr-[5px] text-white p-[6px] rounded-[4px]' /> 
                    <AiOutlineFileExcel onClick={()=>setmodal1(true)} size={24} className='cursor-pointer bg-slate-600 mr-[5px] text-white p-[6px] rounded-[4px]' /> 
                    <AiOutlineFilePdf size={24} onClick={()=>alert("PDF Pending")} className='cursor-pointer bg-slate-600 mr-[5px] text-white p-[6px] rounded-[4px]' /> 
                    <LiaFilePowerpointSolid onClick={generatePPTfunc} size={24} className='cursor-pointer bg-slate-600 mr-[5px] text-white p-[6px] rounded-[4px]' /> 
                    <AiOutlinePlus onClick={()=>navigate('create')} size={24} className='cursor-pointer bg-slate-600 mr-[5px] text-white p-[6px] rounded-[4px]' /> 

                {/* <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} />  */}
                </div>  
               </div>  

            {(roles?.includes('admin') || roles?.includes('db_head')) &&
            <div className='flex  border-b text-[11px]'>
              <h1 onClick={()=>setstep(1)} className={`w-[70px] cursor-pointer p-[4px] text-center ${step == 1 && 'bg-slate-600 text-white'} font-[600]`}>Active</h1>
              <h1 onClick={()=>setstep(2)} className={`w-[70px] cursor-pointer p-[4px] text-center ${step == 2 && 'bg-slate-600 text-white'} font-[600]`}>In Active</h1>
            </div>}
            
            {data?.datas?.length === 0 &&
              <div className='grid place-items-center mt-20  items-center justify-center'>
              <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
              <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
              <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
            </div>
            }

            {data?.datas?.length > 0 &&
            <div className='max-h-[75vh] min-w-[100%] max-w-[100%] mt-4  border-t overflow-y-scroll  border-l border-r '>
            <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                <h6  className='truncate sticky bg-white top-0 bg-white z-50 text-[12px] w-10  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'> <p  className='truncate w-[13px] h-[13px] rounded-[2px] border p-[1.6px] flex items-center justify-center' > </p> </h6>
                {/* {table_heading?.base_type && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Base Type</h6>} */}
                {table_heading?.managed_office_name && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px]  min-w-[200px]  max-w-[200px] px-2 py-1 font-[600] text-slate-600 border-r'>Managed Office Name</h6>}
                {table_heading?.managed_office_address && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Managed Office Address</h6>}
                {table_heading?.zone && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Zone</h6>}
                {table_heading?.location_of_property && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Location of Property</h6>}
                {table_heading?.google_coordinate_link && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Google Coordinate</h6>}
                {table_heading?.landmark_to_property && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>LandMark To Property</h6>}
                {table_heading?.seater_offered && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Seater Offered</h6>}
                {table_heading?.rent_per_seat && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Rent Per Seat</h6>}
                {table_heading?.amenities && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600'>Amenities</h6>}
                {table_heading?.car_parking_charges && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Car Parking Charges</h6>}
                {table_heading?.security_deposit && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Security Deposit</h6>}
                {table_heading?.escalation && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[120px] max-w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Escalation</h6>}
                {table_heading?.lock_in_period && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[120px] max-w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Lock In Period</h6>}
                {table_heading?.lease_term_tenure && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Lease Term Tenure</h6>}
                {table_heading?.possession_type && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Possession Type</h6>}
                {table_heading?.total_built_up_area && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Total Built Up Area</h6>}
                {table_heading?.no_of_floors && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[150px] max-w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>No Of Floors</h6>}
                {table_heading?.per_floor_area && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Per Floor Area</h6>}
                {table_heading?.interior_details && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Interior Details</h6>}
                {table_heading?.area_offered && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Area Offered</h6>}
                {table_heading?.floor_offered && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Floor Offered</h6>}
                {table_heading?.rent_per_sqft && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Rent Per Sqft</h6>}
                {table_heading?.power_and_backup && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Power & BackUp</h6>}
                {table_heading?.maintenace_charges && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Maintenance Charges</h6>}
                {table_heading?.facility_type && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Facility Type</h6>}
                {table_heading?.oc_availability && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Oc Availablility</h6>}
                {table_heading?.status && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Status</h6>}
                {table_heading?.primary_contact_name && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Primary Contact Name</h6>}
                {table_heading?.primary_contact_no && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[150px] max-w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Primary Contact No</h6>}
                {table_heading?.added_by && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[140px] max-w-[140px]  px-2 py-1 font-[600] text-slate-600 border-r'>Added By</h6>}
                {table_heading?.created_At && <h6 className='truncate sticky top-0 z-50 bg-white text-[12px] min-w-[120px] max-w-[120px]  px-2 py-1 font-[600] text-slate-600 '>Created At</h6>}
                <h6 className='truncate sticky border-l top-0 z-50 bg-white text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
            </div>
      
            {data?.datas?.map((d,i)=>(
            <div  key={d?._id} className={`flex truncate  z-50 border-b border-slate-200 z-0`}  >
                  <h6 className='text-[11px] max-w-[30px]  px-2 py-1  cursor-pointer border-r flex justify-center'> <p onClick={()=>dataSelectedList(d?._id)} className={`w-[13px] h-[13px] rounded-[2px] border p-[1.6px] flex items-center justify-center`} >{data_selected_list?.includes(d?._id) &&  <p className='bg-slate-600 flex justify-center items-center rounded-[2px] w-[10px] h-[8px]'></p>} </p> </h6>
                  {/* {table_heading?.base_type && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1 font-[600]  border-r'>{d?.base_type?.name}</h6>} */}
                  {table_heading?.managed_office_name && <h6 className='truncate text-[12px] min-w-[200px] max-w-[200px]  px-2 py-1  border-r'>{d?.managed_office_name}</h6>}
                  {table_heading?.managed_office_address && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1  border-r'>{d?.managed_office_address}</h6>}
                  {table_heading?.zone && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1  border-r'>{d?.zone?.name}</h6>}
                  {table_heading?.location_of_property && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px] px-2 py-1  border-r'>{d?.location_of_property?.name}</h6>}
                  {table_heading?.google_coordinate_link && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px] px-2 py-1  border-r'>{d?.google_coordinate_link}</h6>}
                  {table_heading?.landmark_to_property && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]] px-2 py-1  border-r'>{d?.landmark_to_property}</h6>}
                  {table_heading?.seater_offered && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px] px-2 py-1  border-r'>{d?.seater_offered}</h6>}
                  {table_heading?.rent_per_seat && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px] px-2 py-1  border-r'>{d?.rent_per_seat}</h6>}
                  {table_heading?.amenities && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px] px-2 py-1  border-r overflow-hidden flex'>{d?.amenities?.map((a)=><p className='bg-slate-200 mr-[2px] mb-[2px] p-[2px] px-[4px] rounded text-[9px] font-[700]'>{a?.name}</p>)}</h6>}
                  {table_heading?.car_parking_charges && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px] px-2 py-1  border-r'>{d?.car_parking_charges}</h6>}
                  {table_heading?.security_deposit && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px] px-2 py-1  border-r'>{d?.security_deposit}</h6>}
                  {table_heading?.escalation && <h6 className='truncate text-[12px] min-w-[120px] max-w-[120px] px-2 py-1  border-r'>{d?.escalation}</h6>}
                  {table_heading?.lock_in_period && <h6 className='truncate text-[12px] min-w-[120px] max-w-[120px] px-2 py-1  border-r'>{d?.lock_in_period}</h6>}
                  {table_heading?.lease_term_tenure && <h6 className='truncate text-[12px] min-w-[140px] max-w-[140px] px-2 py-1  border-r'>{d?.lease_term_tenure}</h6>}
                  {table_heading?.possession_type && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px] px-2 py-1  border-r'>{d?.possession_type}</h6>}
                  {table_heading?.total_built_up_area && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1  border-r'>{d?.total_built_up_area}</h6>}
                  {table_heading?.no_of_floors && <h6 className='truncate text-[12px] min-w-[150px] max-w-[150px]  px-2 py-1  border-r'>{d?.no_of_floors}</h6>}
                  {table_heading?.per_floor_area && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1  border-r'>{d?.per_floor_area}</h6>}
                  {table_heading?.interior_details && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1  border-r'>{d?.interior_details}</h6>}
                  {table_heading?.area_offered && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1  border-r'>{d?.area_offered}</h6>}
                  {table_heading?.floor_offered && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1  border-r'>{d?.floor_offered}</h6>}
                  {table_heading?.rent_per_sqft && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1  border-r'>{d?.rent_per_sqft}</h6>}
                  {table_heading?.power_and_backup && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1  border-r'>{d?.power_and_backup}</h6>}
                  {table_heading?.maintenace_charges && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1  border-r'>{d?.maintenace_charges}</h6>}
                  {table_heading?.facility_type && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1  border-r'>{d?.facility_type?.name}</h6>}
                  {table_heading?.oc_availability && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1  border-r'>Oc Availablility</h6>}
                  {table_heading?.status && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1  border-r'>{d?.status?.name}</h6>}
                  {table_heading?.primary_contact_name && <h6 className='truncate text-[12px] min-w-[170px] max-w-[170px]  px-2 py-1  border-r'>{d?.primary_contact_name}</h6>}
                  {table_heading?.primary_contact_no && <h6 className='truncate text-[12px] min-w-[150px] max-w-[150px]  px-2 py-1  border-r'>{d?.primary_contact_no}</h6>}
                  {table_heading?.added_by && <h6 className='truncate text-[12px] min-w-[140px] max-w-[140px]  px-2 py-1  border-r'>Added By</h6>}
                  {table_heading?.created_At && <h6 className='truncate text-[12px] min-w-[120px] max-w-[120px]  px-2 py-1  border-r'>{moment(d?.createdAt)?.format('LLL')}</h6>}
                  <h6  className='truncate text-[12px] w-[120px]  px-2 py-1 flex'>
                  <Tooltip title="Detail">
                  <span><RiBillLine size={13} onClick={()=>navigate(`detail`,{state:d?._id})} className=" z-10"/></span>
                  </Tooltip>

                 
                  {step == 1 &&
                  <Tooltip title="Edit">
                  <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('edit',{state:d?._id})}/></span>
                  </Tooltip>}

                  {step == 2 &&
                  <Tooltip title="Restore">
                  <span><MdRestore size={13} className="z-10 ml-2" onClick={()=>{setselected_data(d);setmodal2(true)}} /></span>
                  </Tooltip>}

                  
                
                
                  <Tooltip title="Delete">
                  <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{setselected_data(d);setmodal(true)}}/></span>
                  </Tooltip>
                
                  

                
                </h6>
            </div>))}
            </div>}
          
           
            </div>

        
      


      </div>
    </div>
  )
}

export default ManagedOfficeList