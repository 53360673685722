import React,{useState,useEffect} from 'react'
import { Select } from 'antd'
import { TextAreaInput1, TextInput } from '../../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import { toast } from 'react-hot-toast'
import { CreateLeadOptionsService, UpdateLeadOptionsService } from '../../../services/LeadOptionServices'
import GoBack from '../../../components/back/GoBack'
import { useLocation, useNavigate } from 'react-router-dom'
import LeadMenu from '../LeadMenu'

function DailyTaskCE() {

  const {state,pathname} = useLocation() 
  const navigator = useNavigate()
  
  console.log("state",state)

  const [stages,setstages] = useState([])

  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  const [singleData,setsingleData] = useState({followup_owner:'',department_id:'',contact_name:'',phone:'',email:'',designation:'',company_name:'',lead_source:'',industry:'',stage:'',website:'',requirment:'',address:'',message:'',important:'',building_name:'',floor_no:'',zoneInfo:'',type:{label:'New',value:'New'}})
  const [error,seterror] = useState({followup_owner:'',department_id:'',contact_name:'',phone:'',email:'',designation:'',company_name:'',lead_source:'',industry:'',stage:'',website:'',requirment:'',address:'',message:'',important:'',type:'',building_name:'',floor_no:'',zoneInfo:''})
  
  const [selected_stage,setselected_stage] = useState({})

  const [loader,setloader] = useState(false)
  let selectedData = ''

//   console.log("path kp",path)
  useEffect(()=>{
    if(path === 'edit'){
      let d = state.data

      console.log("d here ",d?.zoneInfo)
      setsingleData({
        ...singleData,
        contact_name:d?.contact_name,
        phone:d?.phone,
        email:d?.email,
        designation:'',
        company_name:d?.company_name,
        website:d?.website,
        requirment:d?.requirment,
        address:d?.address,
        message:d?.message,
        important:'',
        type:(d?.type === '' || d?.type === undefined) ? '' : {label:d?.type,value:d?.type},
        floor_no:d?.floor_no,
        zoneInfo:d?.zoneInfo,
        building_name:d?.building_name,
    })
    }
    setselected_stage({label:state?.data?.stage?.name,value:state?.data?.stage?._id})
    let arr = []

    state?.allStage?.forEach((s)=>{
        if(s?.name === 'Meetings' || s?.name === 'Walk Ins' || s?.name === 'LOI Discussion' || s?.name === 'Agreement Discussion'){
         arr.push({label:s?.name,value:s?._id})
        }
     })
     setstages(arr)
    if(state?.type === 'meeting'){
        setselected_stage(arr.find((f)=>f?.label === 'Meetings'))
    }

    if(state?.type === 'walk_ins'){
        setselected_stage(arr.find((f)=>f?.label === 'Walk Ins'))
    }
  },[])

//   console.log("state",state)

  async function submitform(){
    setloader(true)
    if(state?.type === 'daily_tasks'){
        if(!singleData.message){
            seterror({...error,message:'The message field is required'})
            setloader(false)
        }else{
            let send_data = {
                message:singleData.message,
                stage:state?.stage?._id
            }
            if(path === 'edit'){
                const response = await UpdateLeadOptionsService(send_data,state?.data?._id)
                if(response.status === 200){
                    setloader(false)
                    toast.success('Job Updated Successfully')
                    resetform() 
                }
            }else{
                const response = await CreateLeadOptionsService(send_data)
                if(response.status === 201){
                    setloader(false)
                    toast.success('Job Added Successfully')
                    resetform() 
                }
            }
        }
    }else if(state?.type === 'calls'){
        if(!singleData.contact_name){
            seterror({...error,contact_name:'The Contact Name field is required'})
            setloader(false)
        }else if(!singleData.phone || singleData.phone.length !== 10){
            seterror({...error,phone:'Enter a valid 10 digit Mobile no'})
            setloader(false)
        }else if(!singleData.company_name){
            seterror({...error,company_name:'The Company name field is required'})
            setloader(false)
        }else if(!singleData.message){
            seterror({...error,message:'The message field is required'})
            setloader(false)
        }else{
            let send_data = {
                contact_name:singleData.contact_name,
                phone:singleData.phone,
                email:singleData.email,
                company_name:singleData.company_name,
                message:singleData.message,
                stage:state?.stage?._id,
                type:singleData?.type?.value !== undefined ? singleData?.type?.value : ''
            }

            if(path === 'edit'){
                const response = await UpdateLeadOptionsService(send_data,state?.data?._id)
                if(response.status === 200){
                    setloader(false)
                    toast.success('Job Updated Successfully')
                    resetform() 
                }
            }else{
                const response = await CreateLeadOptionsService(send_data)
                if(response.status === 201){
                    setloader(false)
                    toast.success('Job Added Successfully')
                    resetform() 
                }
            }
        }
    }else if(state?.type === 'walk_ins'){
        if(!singleData.company_name){
            setloader(false)
            seterror({...error,company_name:'The comapany name field is required'})
        }else if(!singleData.address){
            seterror({...error,address:'The address field is required'})
            setloader(false)
        }else{
            let send_data = {
                stage:state?.stage?._id,
                contact_name:singleData.contact_name,
                phone:singleData.phone,
                email:singleData.email,
                designation:singleData.designation,
                company_name:singleData.company_name,
                website:singleData.website,
                requirment:singleData.requirment,
                address:singleData.address,
                building_name:singleData.building_name,
                floor_no:singleData.floor_no,
                zoneInfo:singleData.zoneInfo,
                type:singleData?.type?.value !== undefined ? singleData?.type?.value : ''
            }
            if(path === 'edit'){
                const response = await UpdateLeadOptionsService(send_data,state?.data?._id)
                if(response.status === 200){
                    setloader(false)
                    toast.success('Job Updated Successfully')
                    resetform() 
                }
            }else{
                const response = await CreateLeadOptionsService(send_data)
                if(response.status === 201){
                    setloader(false)
                    toast.success('Job Added Successfully')
                    resetform() 
                }
            }
        }
    }else if(state?.type === 'property_visit'){
        if(!singleData.company_name){
            seterror({...error,company_name:'The site name field is required'})
            setloader(false)
        }else if(!singleData.address){
            seterror({...error,address:'The site address field is required'})
            setloader(false)
        }else{
            let send_data = {
                stage:state?.stage?._id,
                contact_name:singleData.contact_name,
                phone:singleData.phone,
                email:singleData.email,
                designation:singleData.designation,
                company_name:singleData.company_name,
                // industry:singleData.industry,
                website:singleData.website,
                requirment:singleData.requirment,
                address:singleData.address,
                type:singleData?.type?.value !== undefined ? singleData?.type?.value : ''
            }
            if(path === 'edit'){
                const response = await UpdateLeadOptionsService(send_data,state?.data?._id)
                if(response.status === 200){
                    toast.success('Job Updated Successfully')
                    resetform() 
                    setloader(false)
                }
            }else{
                const response = await CreateLeadOptionsService(send_data)
                if(response.status === 201){
                    toast.success('Job Added Successfully')
                    resetform() 
                    setloader(false)
                }
            }
        }
    }else if(state?.type === 'meeting'){
        if(!singleData.address){
            seterror({...error,address:'The name field is required'})
            setloader(false)
        }else if(!singleData.company_name){
            seterror({...error,company_name:'The name field is required'})
            setloader(false)
        }else if(!singleData.contact_name){
            seterror({...error,contact_name:'The location field is required'})
            setloader(false)
        }else if(!singleData.phone){
            seterror({...error,phone:'The location field is required'})
            setloader(false)
        }else if(!singleData.email){
            seterror({...error,email:'The location field is required'})
            setloader(false)
        }else{
            // console.log("selected_stage kp",selected_stage)
            let send_data = {
                stage:selected_stage?.value,
                contact_name:singleData.contact_name,
                phone:singleData.phone,
                email:singleData.email,
                designation:singleData.designation,
                company_name:singleData.company_name,
                // industry:singleData.industry,
                website:singleData.website,
                requirment:singleData.requirment,
                address:singleData.address,
                type:singleData?.type?.value !== undefined ? singleData?.type?.value : ''
            }
            if(path === 'edit'){
                const response = await UpdateLeadOptionsService(send_data,state?.data?._id)
                if(response.status === 200){
                    toast.success('Job Updated Successfully')
                    resetform() 
                    setloader(false)
                    
                }
            }else{
                const response = await CreateLeadOptionsService(send_data)
                if(response.status === 201){
                    toast.success('Job Added Successfully')
                    resetform() 
                    setloader(false)
                }
            }
        }
    }else if(state?.type === 'scouting'){
        if(!singleData.contact_name){
            seterror({...error,contact_name:'This field is required'})
            setloader(false)
        }else if(!singleData.requirment){
            seterror({...error,requirment:'The requirment field is required'})
            setloader(false)
        }else{
            let send_data = {
                stage:state?.stage?._id,
                contact_name:singleData.contact_name,
                requirment:singleData.requirment,
                type:singleData?.type?.value !== undefined ? singleData?.type?.value : ''
            }
            if(path === 'edit'){
                const response = await UpdateLeadOptionsService(send_data,state?.data?._id)
                if(response.status === 200){
                    toast.success('Job Updated Successfully')
                    resetform() 
                    setloader(false)
                    
                }
            }else{
                const response = await CreateLeadOptionsService(send_data)
                if(response.status === 201){
                    toast.success('Job Added Successfully')
                    resetform() 
                    setloader(false)
                }
            }
        }
    }else{
    }
   }


   function resetform(){
        setsingleData({followup_owner:'',department_id:'',contact_name:'',phone:'',email:'',designation:'',company_name:'',lead_source:'',industry:'',stage:'',website:'',requirment:'',address:'',message:'',important:'',building_name:'',floor_no:'',zoneInfo:''})
        seterror({followup_owner:'',department_id:'',contact_name:'',phone:'',email:'',designation:'',company_name:'',lead_source:'',industry:'',stage:'',website:'',requirment:'',address:'',message:'',important:'',building_name:'',floor_no:'',zoneInfo:''})
        if(path === 'edit'){
            navigator(-1)
        }
       
    }

    function handlechange(e){
        setsingleData({...singleData,[e.target.name] : e.target.value})
        seterror({...error,[e.target.name] : ''})
    }


  const options = [{label:'New',value:'New'},{label:'Old',value:'Old'}] 

  return (
    <div className='flex min-h-screen max-h-screen h-screen '>
    <LeadMenu />
    <div className='w-[20%] pl-5 min-h-screen max-h-screen h-screen overflow-y-screen overflow-x-hidden' >
            <GoBack />
            
            <div className='border-b pb-2'>
                <h6 className='text-[13px] mb-1 font-[800]'>Create/Edit the Daily Task Done By You</h6>
                <h6 className='text-[12px] bg-slate-200 p-2'>Use the Below form to add the daily task report </h6>
           </div>

            {selectedData === '' ?
            <>
            {state?.type === 'calls' &&
            <>
            <TextInput  value={singleData?.contact_name} mandatory={true} error={error?.contact_name} handlechange={handlechange} name="contact_name" label="Contact Name" />
            <TextInput  value={singleData?.phone} mandatory={true} error={error?.phone} handlechange={handlechange} name="phone" label="Mobile" />
            <TextInput  value={singleData?.email} mandatory={false} error={error?.email} handlechange={handlechange} name="email" label="Email" />
            <TextInput  value={singleData?.company_name} mandatory={true} error={error?.company_name} handlechange={handlechange} name="company_name" label="Company" />
            <TextAreaInput1  value={singleData?.message} mandatory={true} error={error?.message} handlechange={handlechange} name="message" label="Remarks" />
            </>
            }

            {state?.type === 'daily_tasks' &&
            <TextAreaInput1  value={singleData?.message} mandatory={true} error={error?.message} handlechange={handlechange} name="message" label="Remarks" />
            }

          
          {/* <h6>zoneInfo --- {state?.zoneInfo}</h6> */}

            {(state?.type  !== 'calls' && state?.type  !== 'daily_tasks')  && 
            <>
            {state?.type  !== 'meeting' && state?.type  !== 'scouting' && <TextInput mandatory={(state?.type === 'walk_ins' || state?.type === 'property_visit')} value={singleData?.company_name} handlechange={handlechange} error={error?.company_name} name="company_name" label={state?.type === 'walk_ins' ? "Company Name" : "Property Name"} />}
            {state?.type  !== 'scouting' && <TextAreaInput1 value={singleData?.address} handlechange={handlechange} mandatory={(state?.type === 'walk_ins' || state?.type === 'property_visit' || state?.type === 'meeting')}  error={error?.address} name="address" label={state?.type === 'walk_ins' ? "Company Address" : state?.type === 'meeting' ? "Location" : "Property Address"} />}
            {state?.type  !== 'walk_ins' &&  <TextInput handlechange={handlechange} value={singleData?.company_name} mandatory={state?.type === 'meeting'}  error={error?.company_name}  name="company_name" label="Company Name" />}
            {(state?.type  === 'walk_ins')  && <TextInput handlechange={handlechange} value={singleData?.zoneInfo} mandatory={state?.type === 'walk_ins'}  error={error?.zoneInfo}  name="zoneInfo" label="Zone Info" />}
            {(state?.type  === 'walk_ins')  && <TextInput handlechange={handlechange} value={singleData?.building_name} mandatory={state?.type === 'walk_ins'}  error={error?.building_name}  name="building_name" label="Building Name" />}
            {(state?.type  === 'walk_ins')  && <TextInput handlechange={handlechange} value={singleData?.floor_no} mandatory={state?.type === 'walk_ins'}  error={error?.floor_no}  name="floor_no" label="Floor No" />}
           
            <TextInput value={singleData?.contact_name} handlechange={handlechange} mandatory={state?.type === 'meeting' || state?.type === 'scouting'} error={error?.contact_name}  name="contact_name" label={state?.type  === 'scouting' ? "Whom Youre Searching" : "Name"} />
            {state?.type  !== 'scouting' && <TextInput value={singleData?.phone} handlechange={handlechange} mandatory={state?.type === 'meeting'} error={error?.phone} name="phone" label="Mobile" />}
            {state?.type  !== 'scouting' && <TextInput value={singleData?.email} handlechange={handlechange} mandatory={state?.type === 'meeting'} error={error?.email} name="email" label="Email" />}
            {/* <TextInput value={singleData?.designation} handlechange={handlechange} error={error?.message} name="designation" label="Designation" /> */}
           
            <TextAreaInput1 value={singleData?.requirment} handlechange={handlechange} error={error?.requirment} mandatory={state?.type === 'scouting'} name="requirment" label={state?.type  === 'scouting' ? "What's there Requirment" : "Remarks"} />
            </>}

            
            </>
            :
            <>



                {state?.type === 'calls' &&
                <TextAreaInput1  value={selectedData?.message} mandatory={true} error={error?.message}  name="message" label="No of calls" />}

                
 
                {state?.type !== 'calls' && 
                <>
                {state?.type !== 'meeting' && <TextInput mandatory={(state?.type === 'walk_ins' || state?.type === 'property_visit')} value={selectedData?.company_name}  name="company_name" label={state?.type === 'walk_ins' ? "Company Name" : state?.type === 'scouting' ? "What Your'e Scouting" : "Property Name"} />}
                <TextAreaInput1 value={selectedData?.address}  mandatory={(state?.type === 'walk_ins' || state?.type === 'property_visit' || state?.type === 'meeting')}  error={error?.address} name="address" label={state?.type === 'walk_ins' ? "Company Address" : state?.type === 'meeting' ? "Location" : "Property Address"} />
                <TextInput value={selectedData?.contact_name}  mandatory={state?.type === 'meeting'} error={error?.contact_name}  name="contact_name" label="Name" />
                <TextInput value={selectedData?.phone} mandatory={state?.type == 'meeting'}  error={error?.phone} name="phone" label="Mobile" />
                <TextInput value={selectedData?.email} mandatory={state?.type == 'meeting'}  error={error?.email} name="email" label="Email" />
                {/* <TextInput value={selectedData?.designation}  error={error?.message} name="designation" label="Designation" /> */}

                <TextAreaInput1 value={selectedData?.requirment}  error={error?.requirment} name="requirment" label={"Remarks"} />
                </>}

                </>

            }

        
        {/* <h6>{state?.type}</h6> */}
         {/* {(state?.type ===  'Meeting' || state?.type ===  'meeting') &&
          <>
          <h6 className='text-[11px] font-[600] mb-1' >{'Select Stage'}</h6>
          <Select
              value={selected_stage}
              bordered={false}
              className='border w-[100%] border-l-4 border-l-slate-600 border-slate-300 h-[30px]' 
              options={stages}
              onChange={(e)=>setselected_stage(stages?.find((f)=>f?.value === e))}
              
             />
             </>} */}
            
          {state?.type  !== 'scouting'  &&
          <>
            <h6 className='text-[11px] font-[600] mb-1 mt-2' >{'Type'}</h6>
            <Select
              value={singleData?.type}
              bordered={false}
              className='border w-[100%] border-l-4 border-l-slate-600 border-slate-300 h-[30px]' 
              options={options}
              onChange={(e)=>setsingleData({...singleData,type:{label:e,value:e}})}
              
             />
        </>}
             {selectedData === '' &&
            <div className='flex mt-2 border-t pt-2 w-full items-end '>
                <div className='mr-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>navigator(-1)} />
                </div>
                <ButtonFilledAutoWidth btnName="Save" onClick={submitform} />
            </div>
            }
           </div> 

    </div>
  )
}

export default DailyTaskCE