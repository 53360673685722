import { deleteRequest, get, post, put } from "../../helpers/apihelpers";

export const CreateDBRequirmentInfoService=async(data)=>{
    try {
        const result = await post(`api/database/requirment_info/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateDBRequirmentInfoService=async(data,id)=>{
    try {
        const result = await put(`api/database/requirment_info/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetDBRequirmentInfoService=async(page,from_date,to_date,text,step)=>{
    try {
        const result = await get(`api/database/requirment_info/get?page=${page}&from_date=${from_date}&to_date=${to_date}&text=${text}&step=${step}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetDBRequirmentInfoBasedLeadIdService=async(id)=>{
    try {
        const result = await get(`api/database/requirment_info/get_based_lead/${id}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteDBRequirmentInfoService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/requirment_info/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAdminDBRequirmentInfoService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/requirment_info/delete_admin/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterDBRequirmentInfoService=async(id)=>{
    try {
        const result = await get(`api/database/requirment_info/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}