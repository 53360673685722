import React, { useEffect, useState } from 'react'
import GoBack from '../../../../components/back/GoBack'
import { CreatePlotTypeService, UpdatePlotTypeService } from '../../../../services/database/databaseoptions/PlotTypeServices'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { TextInput } from '../../../../components/input'
import DatabaseMenu from '../../DatabaseMenu'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../../components/button'
import { CreatePropertyTypeService, UpdatePropertyTypeService } from '../../../../services/database/databaseoptions/PropertyTypeServices'
import { CreatePropertyCategoryService, UpdatePropertyCategoryService } from '../../../../services/database/databaseoptions/PropertyCategoryServices'
import { CreatePropertyFacilityService, UpdatePropertyFacilityService } from '../../../../services/database/databaseoptions/PropertyFacilityServices'

function PropertyFacilityCE() {
  
  const [data,setdata] = useState({name:'',sort:'',is_deleted:false})  
  const [error,seterror] = useState({name:'',sort:'',is_deleted:''})  


  const navigate = useNavigate()
  const  {state} = useLocation()


  useEffect(()=>{
    if(state?._id !== undefined){
        setdata(state)
    }
  },[state])


  async function submitform(){
    if(!data?.name){
        seterror({...error,name:'This Field is required*'})
    }else if(!data?.sort){
        seterror({...error,sort:'This Field is required*'})
    }else{
        if(data?._id === undefined){
            const response = await CreatePropertyFacilityService(data)
            if(response?.status === 201){
                toast.success("Property Facility Created Successfully")
                setdata({name:'',sort:'',is_deleted:false})
                seterror({name:'',sort:'',is_deleted:''})
            }
        }else{
            const response = await UpdatePropertyFacilityService(data,state?._id)
            if(response?.status === 200){
                toast.success("Property Facility Updated Successfully")
                setdata({name:'',sort:'',is_deleted:false})
                seterror({name:'',sort:'',is_deleted:''})
                navigate(-1)
            }
        }
    }
  }

  async function handlechange(e){
        setdata({...data,[e.target.name]:e.target.value})
        seterror({...error,[e.target.name]:''})
  }

  return (
    <div className='flex'>
        <div className='min-w-[180px] w-[180px] max-w-[180px]'>
        <DatabaseMenu /> 
        </div>
        <div className='ml-4 w-64 mt-4 pr-5 min-h-screen max-h-screen h-screen overflow-y-scroll'>
        <div>
        <GoBack />

        <div className='border-b  pb-2'>
        <h6 className='font-[800] mb-1'>Create / Edit Property Category</h6> 
        <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the Property category for the property function.</h6> 
        </div> 

        <TextInput 
              mandatory={true}
              label={'Name'}  
              variant="standard"
              name="name"
              type="text"
              value={data.name}
              error={error.name}
              handlechange={handlechange}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

        <TextInput 
              mandatory={true}
              label={'Sort'}  
              variant="standard"
              name="sort"
              type="number"
              value={data.sort}
              error={error.sort}
              handlechange={handlechange}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>  


              <div className='flex mt-2 justify-end border-t pt-2'>
               <ButtonOutlinedAutoWidth  btnName="Cancel" onClick={()=>navigate(-1)} />  
               <h6 className='w-[10px]'></h6>
               <ButtonFilledAutoWidth  btnName="Save" onClick={submitform} />  
              </div>    

        </div> 
    </div >
    </div>

  )
}

export default PropertyFacilityCE