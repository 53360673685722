import React, { useEffect, useState } from 'react'
import DatabaseMenu from '../DatabaseMenu'
import GoBack from '../../../components/back/GoBack'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetManagedOfficeDetailService } from '../../../services/database/mainoptions/ManagedOfficeServices'
import toast from 'react-hot-toast'
import { GrLocationPin } from "react-icons/gr";
import { AiOutlineEdit } from 'react-icons/ai'

function ManagedOfficeDetail() {

  const {state} = useLocation() 
  const navigate = useNavigate()

  console.log("state",state)

  const [data,setdata] = useState({})

  const [selectedImage,setselectedImage] = useState("")

  useEffect(()=>{
    getdetail()
  },[state])

  async function getdetail() {
    const response = await GetManagedOfficeDetailService(state)
    if(response?.status == 200){
        setselectedImage(response?.data?.datas[0]?.main_image)
        setdata(response?.data?.datas[0])
    }else{
        navigate(-1)
        toast.error("Data Not Found")
    }
  }

  function openFile(v){
    let url = `${v}`
    window.open(url,'_blank')
   }

  return (
    <div className='flex'>
        <div className='min-w-[180px] w-[180px] max-w-[180px]'>
        <DatabaseMenu /> 
        </div>
        <div className='ml-4 w-[100%] mt-4 pr-5 min-h-screen max-h-screen h-screen overflow-y-scroll'>
            <div>
            <GoBack />

            <div className='border flex relative rounded p-2'>
                <div onClick={()=>navigate('/database/managed_office/edit',{state:state})} className='flex items-center justify-center bg-slate-100 rounded pr-3 pl-2 cursor-pointer z-100 absolute right-2 p-1 border border-slate-400 '>
                    <AiOutlineEdit className='mr-2 text-[10px]' />
                    <h6 className='text-[11px]'>Edit</h6>
                </div>

                <div className='w-[250px] border border-slate-200 rounded p-1 mr-4 h-[150px]'>
                    <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${selectedImage}`} className='w-[100%] h-[100%] object-cover' />
                </div>

                <div>
                   <h6 className='font-[800] text-[13px]'>{data?.managed_office_name}</h6>
                   {data?.managed_office_address !== '' && <h6 className='bg-slate-100 px-2 py-[4px] flex items-start text-[12px]'><GrLocationPin className='mt-[3px] mr-2' /> {data?.managed_office_address}</h6>}
                   <h6 className='text-[12px] mt-1'>Zone : <span className='font-[800]'>{data?.zone?.name}</span> / Area : <span className='font-[800]'>{data?.location_of_property?.name}</span></h6>
                   <h6 className='text-[12px] mt-1'>Landmark To Property : <span className='font-[800]'>{data?.landmark_to_property}</span></h6>
                    
                  <div className='flex mt-2'>
                    {!['',null,undefined,'null','undefined']?.includes(data?.main_image) &&
                    <div onClick={()=>setselectedImage(data?.main_image)} className='w-[40px] cursor-pointer rounded border border-slate-200 p-1 mr-1 h-[40px]'>
                        <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.main_image}`} className='w-[100%] h-[100%] object-cover' />
                    </div>}
                    {!['',null,undefined,'null','undefined']?.includes(data?.image1) &&
                    <div onClick={()=>setselectedImage(data?.image1)} className='w-[40px] cursor-pointer rounded border border-slate-200 p-1 mr-1 h-[40px]'>
                        <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.image1}`} className='w-[100%] h-[100%] object-cover' />
                    </div>}
                    {!['',null,undefined,'null','undefined']?.includes(data?.image2) &&
                    <div onClick={()=>setselectedImage(data?.image2)} className='w-[40px] cursor-pointer rounded border border-slate-200 p-1 mr-1 h-[40px]'>
                        <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.image2}`} className='w-[100%] h-[100%] object-cover' />
                    </div>}
                    {!['',null,undefined,'null','undefined']?.includes(data?.image3) &&
                    <div onClick={()=>setselectedImage(data?.image3)} className='w-[40px] cursor-pointer rounded border border-slate-200 p-1 mr-1 h-[40px]'>
                        <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.image3}`} className='w-[100%] h-[100%] object-cover' />
                    </div>}
                    {!['',null,undefined,'null','undefined']?.includes(data?.image4) &&
                    <div onClick={()=>setselectedImage(data?.image4)} className='w-[40px] cursor-pointer rounded border border-slate-200 p-1 mr-1 h-[40px]'>
                        <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.image4}`} className='w-[100%] h-[100%] object-cover' />
                    </div>}
                  </div>  
               
                </div>
            
            </div> 


            <div className='mt-2 flex border p-2 rounded'>




                <div className='w-[30%] mr-5'>
                    <h6 className='font-[900] text-[12px] mt-3 mb-2'>More Information</h6>
                    <div className='border'>
                        
                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Seater Offered</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.seater_offered}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Rent Per Seat</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.rent_per_seat}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Escalation</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.escalation}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Amenities Availablility</h6>
                            <h6 className='w-[50%] p-1 font-[800] flex flex-wrap border-b text-[12px]'>{data?.amenities?.map((a)=><span className='bg-slate-100 px-2 py-1 rounded text-[9px] mr-1 mb-1'>{a?.name}</span>)}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Amenities List</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.amenities_available ? 'Available' : 'Not Available'}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>No of Car Parking Slots</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.no_of_car_parking_slots}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Car Parking Charges</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.car_parking_charges}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Security Deposit</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.security_deposit}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Lock In Period</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.lock_in_period}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Power And Backup</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.power_and_backup}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r '>OC Availablility</h6>
                            <h6 className='w-[50%] p-1 font-[800] '>{data?.oc_availability ? 'True' : 'False'}</h6>
                        </div>
                    </div>

                    <h6 className='font-[900] text-[12px] mt-3 mb-2'>Creator Information</h6>
                    <div className='border'>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Name</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.added_by?.name}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Employee Id</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.latitude}</h6>
                    </div>
                    </div>
                </div>

                <div className='w-[30%]'>
                    <h6 className='font-[900] text-[12px] mt-3 mb-2'>Contact Information</h6>
                    <div className='border'>
                        
                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Primary Contact Name</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.primary_contact_name}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Priamary Contact No</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.primary_contact_no}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Secondary Contact Name</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.secondary_contact_name}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%]'>Secondary Contact No</h6>
                            <h6 className='w-[50%] p-1 font-[800]' >{data?.secondary_contact_no}</h6>
                        </div>

                    </div>

                    <h6 className='font-[900] text-[12px] mt-3 mb-2'>Location Information</h6>
                    <div className='border'>
                        
                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Latitude</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.latitude}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Longitude</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.longitude}</h6>
                        </div>

                        <div className='text-[13px] flex '>
                            <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Google Map Link</h6>
                            <h6 className='w-[50%] p-1 font-[800] border-b text-[12px] break-all'><span onClick={()=>openFile(data?.google_coordinate_link)} className='underline text-blue-500 cursor-pointer'>{data?.google_coordinate_link}</span></h6>
                        </div>


                    </div>

                </div>

            </div>

            
            </div>
        </div>
    </div>
  )
}

export default ManagedOfficeDetail