import React, { useEffect, useState } from 'react'
import DatabaseMenu from '../DatabaseMenu'
import GoBack from '../../../components/back/GoBack'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetOfficeSpaceDetailService } from '../../../services/database/mainoptions/OfficeSpaceServices'
import toast from 'react-hot-toast'
import { GrLocationPin } from "react-icons/gr";
import { AiOutlineClose, AiOutlineEdit } from 'react-icons/ai'
import { Modal } from 'antd'


function OfficeSpaceDetail() {
  
  const {state} = useLocation() 
  const navigate = useNavigate()

  const [data,setdata] = useState({})

  const [selectedImage,setselectedImage] = useState("")

  const [floorSelected,setfloorSelected] = useState(null)

  useEffect(()=>{
    getdetail()
  },[state])

  async function getdetail() {
    const response = await GetOfficeSpaceDetailService(state)
    if(response?.status == 200){
        setselectedImage(response?.data?.datas[0]?.main_image)
        setdata(response?.data?.datas[0])
    }else{
        navigate(-1)
        toast.error("Data Not Found")
    }
  }

  function openFile(v){
    let url = `${v}`
    window.open(url,'_blank')
   }

   console.log("data",data)

  return (
    <div className='flex relative'>

    <Modal closable={false} footer={false} width={800} open={floorSelected !== null}>
       <div className='rlative'>
            <h6 className='font-[800] text-[13px]'>Units ({floorSelected?.units?.length})</h6>   
            <AiOutlineClose  className='bg-slate-200 p-1 rounded absolute right-4 top-2' onClick={()=>setfloorSelected(null)} />

            {floorSelected?.units?.map((u)=>(
                <div className='' key={u?._id}>
                      <div className='flex border p-2 mt-2 rounded pb-2'> 
                            <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${u?.main_image}`} className='min-w-[40%] max-w-[40%] h-[120px] bg-slate-100 object-cover' />

                            <div className='pl-2'>
                                <h6 className='text-[13px] mb-1'>Unit Type : <span className='font-[800]'> {u?.unit_type?.name} </span> </h6>
                                <h6 className='text-[13px] mb-1'>Unit Name : <span className='font-[800]'> {u?.unit_name} </span> </h6>
                                <h6 className='text-[13px] mb-1'>Unit Number : <span className='font-[800]'> {u?.unit_number} </span> </h6>
                                <h6 className='text-[13px] mb-1'>Area Offered In Sqft : <span className='font-[800]'> {u?.area_offered_in_sqft} </span> </h6>
                            </div>    
                        </div>

                        <div className='flex items-start justify-evenly'> 
                            <div className='w-[33%] mr-2'> 
                            <h6 className='text-[12px] font-[800] px-1 py-1 mt-2'>Other Information</h6>
                            <div className='border'>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Area Offered In Sqft</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.area_offered_in_sqft}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Maintenance Charges</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.maintenace_charges}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Lock In Period</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.lock_in_period}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Lease Term and Tenure</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.lease_term_and_tenure}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Car Parking Slots</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.car_parking_slots}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Car Parking Charges</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.car_parking_charges}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Posession Type</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.possession_type}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Facility Type</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.facility_type}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Furnish Details</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.furnished_details?.name}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Possession Type</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.possession_type}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Status</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.status?.name}</h6>
                                </div>

                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Escalation</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.escalation}</h6>
                                </div>


                            

                            </div>  
                            </div>     

                            <div className='w-[33%] mr-2'> 
                            <h6 className='text-[12px] font-[800] px-1 py-1 mt-2'>Contact Information</h6> 
                            <div className='border'>
                            <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Owner Name</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.owner_name}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Owner No</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.owner_no}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Manager Name</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.manager_name}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Manager No</h6>
                                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{u?.manager_no}</h6>
                                </div> 
                            </div>     
                            </div>     

                            <div className='w-[33%]'> 
                            <h6 className='text-[12px] font-[800] px-1 py-1 mt-2'>Attachments</h6> 
                            <div className='border'>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Main Image</h6>
                                    <h6 onClick={()=>u?.main_image !== '' && openFile(`${process.env.REACT_APP_AWS_IMAGE_URL}${u?.main_image}`)}  className='w-[50%] break-all text-blue-500 underline p-1 font-[800] border-b text-[12px]'>{u?.main_image}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Unit Plan</h6>
                                    <h6 onClick={()=>u?.unit_plan !== '' && openFile(`${process.env.REACT_APP_AWS_IMAGE_URL}${u?.unit_plan}`)}  className='w-[50%] break-all text-blue-500 underline p-1 font-[800] border-b text-[12px]'>{u?.unit_plan}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Presentation Sheet</h6>
                                    <h6 onClick={()=>u?.presentation_ppt !== '' && openFile(`${process.env.REACT_APP_AWS_IMAGE_URL}${u?.presentation_ppt}`)}  className='w-[50%] break-all text-blue-500 underline p-1 font-[800] border-b text-[12px]'>{u?.presentation_ppt}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Term Sheet</h6>
                                    <h6 onClick={()=>u?.term_sheet !== '' && openFile(`${process.env.REACT_APP_AWS_IMAGE_URL}${u?.term_sheet}`)}  className='w-[50%] break-all text-blue-500 underline p-1 font-[800] border-b text-[12px]'>{u?.term_sheet}</h6>
                                </div>
                                <div className='text-[13px] flex '>
                                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Multiple Image</h6>
                                    <h6 onClick={()=>u?.multiple_image !== '' && openFile(`${process.env.REACT_APP_AWS_IMAGE_URL}${u?.multiple_image}`)}  className='w-[50%] break-all text-blue-500 underline p-1 font-[800] border-b text-[12px]'>{u?.multiple_image}</h6>
                                </div>
                            </div> 
                            </div> 
                        </div>   

                </div>    
            ))}
            <div>
            
            </div>    
       </div>
    </Modal>

    <div onClick={()=>navigate('/database/office_space/edit',{state:state})} className='flex items-center justify-center bg-slate-100 rounded pr-3 pl-2 cursor-pointer z-100 absolute right-2 top-2 p-1 border border-slate-400 '>
        <AiOutlineEdit className='mr-2 text-[10px]' />
        <h6 className='text-[11px]'>Edit</h6>
    </div>
    <div className='min-w-[180px] w-[180px] max-w-[180px]'>
    <DatabaseMenu /> 
    </div>
    <div className='ml-4 flex w-[100%]  pr-0 min-h-screen overflow-y-scroll'>
        <div className='w-[30%] pt-4 pr-5 border-r h-full'>
        <GoBack />

        {/* <h6 className='text-[11px]'>{process.env.REACT_APP_AWS_IMAGE_URL}{data?.main_image}</h6> */}

        <img alt="main_image" src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.main_image}`} className='w-[100%] h-[150px] bg-slate-100 object-cover' />


        <div className='mt-4'>
            <h6 className='font-[800] text-[13px]'>{data?.building_name}</h6>
            {data?.office_space_address !== '' && <h6 className='bg-slate-100 px-2 py-[4px] my-1 flex items-start text-[11px]'><GrLocationPin className='mt-[3px] mr-2' /> {data?.office_space_address}</h6>}
            <h6 className='text-[12px] mt-1'>Zone : <span className='font-[800]'>{data?.zone?.name}</span> / Area : <span className='font-[800]'>{data?.location_of_property?.name}</span></h6>
            <h6 className='text-[12px] mt-1'>Landmark To Property : <span className='font-[800]'>{data?.landmark_to_property}</span></h6>


            <h6 className='text-[12px] font-[800] px-1 py-1 mt-2'>More Information</h6> 

            <div className='border'>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Total Built Up Area</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.total_built_up_area}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Total Floors In Building</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.total_floors_in_building}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Total Land Area</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.total_land_area}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Cafeteria</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.cafeteria}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Cafeteria Charges</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.cafeteria_charges}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Floor Area Efficiency</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.floor_area_efficiency}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Floor Palte Size</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.floor_plate_size}</h6>
                    </div>
                   
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Lease Term And Tenure</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.lease_term_and_tenure}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Lock In Period</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.lock_in_period}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Maintenance Charges</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.maintenace_charges}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>OC Availability</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.oc_availability}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Power And Backup</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.power_and_backup}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Rent Price</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.rent_price}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Sale Price</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.sale_price}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Security Deposit</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.security_deposit}</h6>
                    </div>
                  
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Zone Name</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.zone?.name}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Location of Property</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.location_of_property?.name}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r '>Landmark to Property</h6>
                        <h6 className='w-[50%] p-1 font-[800]  text-[12px]'>{data?.landmark_to_property}</h6>
                    </div>


 
                  
                  
                    
                  
            </div>    

            <h6 className='text-[12px] font-[800] px-1 py-1 mt-2'>Location Information</h6> 

            <div className='border'>
                <div className='text-[13px] flex '>
                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Latitude</h6>
                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.latitude}</h6>
                </div>
                <div className='text-[13px] flex '>
                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Longitude</h6>
                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.longitude}</h6>
                </div>
                <div className='text-[13px] flex '>
                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Google Link</h6>
                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'><span onClick={()=>data?.google_coordinate_link !== '' && openFile(data?.google_coordinate_link)} className='underline text-blue-500 cursor-pointer'>{data?.google_coordinate_link}</span></h6>
                </div>
            </div>

            <h6 className='text-[12px] font-[800] px-1 py-1 mt-2'>Attachments</h6> 

            <div className='border'>
                <div className='text-[13px] flex '>
                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Term Sheet</h6>
                    <h6 onClick={()=>data?.term_sheet !== '' && openFile(`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.term_sheet}`)}  className='w-[50%] break-all text-blue-500 underline p-1 font-[800] border-b text-[12px]'>{data?.term_sheet}</h6>
                </div>
                <div className='text-[13px] flex '>
                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Presentation Sheet</h6>
                    <h6 onClick={()=>data?.presentation_ppt !== '' && openFile(`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.presentation_ppt}`)}  className='w-[50%] break-all text-blue-500 underline p-1 font-[800] border-b text-[12px]'>{data?.presentation_ppt}</h6>
                </div>
                <div className='text-[13px] flex '>
                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Building Plan</h6>
                    <h6 onClick={()=>data?.building_plan !== '' && openFile(`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.building_plan}`)}  className='w-[50%] break-all text-blue-500 underline p-1 font-[800] border-b text-[12px]'>{data?.building_plan}</h6>
                </div>
                <div className='text-[13px] flex '>
                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Multiple Image</h6>
                    <h6 onClick={()=>data?.multiple_image !== '' && openFile(`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.multiple_image}`)}  className='w-[50%] break-all text-blue-500 underline p-1 font-[800] border-b text-[12px]'>{data?.multiple_image}</h6>
                </div>
            </div> 

            <h6 className='text-[12px] font-[800] px-1 py-1 mt-2'>Contact Information</h6> 

            <div className='border'>
                {data?.owner_name !== '' &&
                <div className='text-[13px] flex '>
                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Owner Name</h6>
                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.owner_name}</h6>
                </div>}
                {data?.owner_no !== '' &&
                <div className='text-[13px] flex '>
                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Owner No</h6>
                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.owner_no}</h6>
                </div>}
                {data?.manager_name !== '' &&
                <div className='text-[13px] flex '>
                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Manager Name</h6>
                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.manager_name}</h6>
                </div>}
                {data?.manager_no !== '' &&
                <div className='text-[13px] flex '>
                    <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Manager No</h6>
                    <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{data?.manager_no}</h6>
                </div>}
            </div>
            

        </div>
          
        

        </div>
        <div className='w-[70%] p-4'>
            <h6 className='font-[800] mb-4 text-[13px]'>Floor Info ({data?.floors?.length})</h6>
            
            {data?.floors?.map((f)=>(
            <div className='border relative rounded p-2 mb-2' key={f?._id}>

             <h6 onClick={()=>setfloorSelected(f)} className='bg-orange-200 cursor-pointer text-[12px] p-1 font-[800] rounded border border-orange-500 absolute right-3 top-3'>Units - {(f?.units?.length)}</h6>   

             <div className='flex border p-2 rounded pb-2'> 
                <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${f?.main_image}`} className='min-w-[40%] max-w-[40%] h-[120px] bg-slate-100 object-cover' />

                <div className='pl-2'>
                    <h6 className='text-[13px] mb-1'>Floor Type : <span className='font-[800]'> {f?.floor_type?.name} </span> </h6>
                    <h6 className='text-[13px] mb-1'>Floor Name : <span className='font-[800]'> {f?.floor_name} </span> </h6>
                    <h6 className='text-[13px] mb-1'>Floor Number : <span className='font-[800]'> {f?.floor_number} </span> </h6>
                    <h6 className='text-[13px] mb-1'>Area Offered In Sqft : <span className='font-[800]'> {f?.area_offered_in_sqft} </span> </h6>
                </div>    
             </div>

             <div className='flex items-start justify-evenly'> 
                <div className='w-[33%] mr-2'> 
                <h6 className='text-[12px] font-[800] px-1 py-1 mt-2'>Other Information</h6>
                <div className='border'>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Floor Sale Price</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{f?.floor_sale_price}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Floor Rent Per Sqft Per Month</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{f?.floor_rent_per_sqft_per_month}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Maintenance Charges</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{f?.maintenace_charges}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Floor Lock In Period</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{f?.floor_lock_in_period}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Floor Lease Term</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{f?.floor_lease_term}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Car Parking Slots</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{f?.car_parking_slots}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Car Parking Charges</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{f?.car_parking_charges}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Facility Type</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{f?.facility_type?.name}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Posession Type</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{f?.possession_type}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Security Deposit</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{f?.security_deposit}</h6>
                    </div>

                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Escalation</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{f?.escalation}</h6>
                    </div>


                

                </div>  
                </div>     

                <div className='w-[33%] mr-2'> 
                <h6 className='text-[12px] font-[800] px-1 py-1 mt-2'>Contact Information</h6> 
                <div className='border'>
                <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Owner Name</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{f?.owner_name}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Owner No</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{f?.owner_no}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Manager Name</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{f?.manager_name}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Manager No</h6>
                        <h6 className='w-[50%] p-1 font-[800] border-b text-[12px]'>{f?.manager_no}</h6>
                    </div> 
                </div>     
                </div>     

                <div className='w-[33%]'> 
                <h6 className='text-[12px] font-[800] px-1 py-1 mt-2'>Attachments</h6> 
                <div className='border'>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Presentation Sheet</h6>
                        <h6 onClick={()=>f?.presentation_ppt !== '' && openFile(`${process.env.REACT_APP_AWS_IMAGE_URL}${f?.presentation_ppt}`)}  className='w-[50%] break-all text-blue-500 underline p-1 font-[800] border-b text-[12px]'>{f?.presentation_ppt}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Building Plan</h6>
                        <h6 onClick={()=>f?.building_plan !== '' && openFile(`${process.env.REACT_APP_AWS_IMAGE_URL}${f?.building_plan}`)}  className='w-[50%] break-all text-blue-500 underline p-1 font-[800] border-b text-[12px]'>{f?.building_plan}</h6>
                    </div>
                    <div className='text-[13px] flex '>
                        <h6 className='bg-slate-100 p-1 w-[50%] border-r border-b'>Multiple Image</h6>
                        <h6 onClick={()=>f?.multiple_image !== '' && openFile(`${process.env.REACT_APP_AWS_IMAGE_URL}${f?.multiple_image}`)}  className='w-[50%] break-all text-blue-500 underline p-1 font-[800] border-b text-[12px]'>{f?.multiple_image}</h6>
                    </div>
                </div> 
                </div> 
            </div>     



            </div>))}

        </div>
    </div>
    </div>
  )
}

export default OfficeSpaceDetail