import React, { useEffect, useState } from 'react'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import GoBack from '../../components/back/GoBack'
import { TextAreaInput1, TextInput, TextInput1 } from '../../components/input'
import { useLocation, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import ProfitLossMenu from './ProfiltLossMenu'
import { CreateVerticalProfitLossService, UpdateVerticalProfitLossService } from '../../services/VerticalProfitLoss'
import { useSelector } from 'react-redux'
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus,AiOutlineFileExcel } from "react-icons/ai";
import { BiCheckbox,BiCheckboxSquare } from "react-icons/bi";
import { Modal, Select } from 'antd'
import { GetDepartmentService } from '../../services/DepartmentServices'
import Priceconstants from '../../constants/imageConstants'
import moment from 'moment'
import Uploader from '../../components/Uploader'
import * as XLSX from 'xlsx';

function ProfitLossListCE() {

  const user = useSelector(state=>state.Auth)
  const roles = useSelector(state=>state.Auth.roles)

  const [data,setdata] = useState({department:'',createdAt:'',turn_over:'',revenue:'',profit:'',expense:'',vendor_expenses:'',outstanding:'',net_profit:'',from_date:'',to_date:'',remark:'',department:'',total_no_of_painting:'',new_paintings_added_count:'',paintings_sold_count:'',fund_raised:'',fund_utilized:'',balance:'',employee_info:{entry:0,exit:0}})
  const [error,seterror] = useState({department:'',createdAt:'',turn_over:'',revenue:'',profit:'',expense:'',vendor_expenses:'',outstanding:'',net_profit:'',from_date:'',to_date:'',remark:'',department:'',total_no_of_painting:'',new_paintings_added_count:'',paintings_sold_count:'',fund_raised:'',fund_utilized:'',balance:'',employee_info:{entry:'',exit:''}})
   




  const [step,setstep] = useState(1)
  
  const [excel_file_modal,setexcel_file_modal] = useState('')
  const [excel_file,setexcel_file] = useState(null)
  const [lead,setlead] = useState({client_name:'',requirement:'',department:'',converted:false})
  const [department_work,setdepartment_work] = useState({name:'',work:'',expense:''})
  const [department_work_err,setdepartment_work_err] = useState({name:'',work:'',expense:''})
  const [invoice,setinvoice] = useState({client_name:'',requirement:'',converted:true,invoice_amt:'',collected:'',outstanding:'',invoice_no:'',date:''})
  const [invoice_err,setinvoice_err] = useState({client_name:'',requirement:'',converted:'',invoice_amt:'',collected:'',outstanding:'',invoice_no:'',date:''})
  const [shilpa_foundation_receipt_payment,setshilpa_foundation_receipt_payment] = useState({date:'',particulars:'',receipt:'',payment:'',closing_available_balance:'',remarks:''})
  const [shilpa_foundation_receipt_payment_err,setshilpa_foundation_receipt_payment_err] = useState({date:'',particulars:'',receipt:'',payment:'',closing_available_balance:'',remarks:''})
  const [leaderr,setleaderr] = useState({client_name:'',requirement:'',department:''})
  const [event,setevent] = useState({event_name:'',launched_date:''})
  const [eventerr,seteventerr] = useState({event_name:'',launched_date:''})
  const [expense,setexpense] = useState({name:'',amount:'',user:'',remark:''})
  const [expenseerr,setexpenseerr] = useState({name:'',amount:'',user:'',remark:''})


  const [deletedData,setdeleteData] = useState({index:0,type:'',data:{}})
  const [deleteModal,setdeleteModal] = useState(false)

  const [expenses_bifurcation,setexpenses_bifurcation] = useState([])
  const [new_events,setnew_events] = useState([])
  const [new_leads,setnew_leads] = useState([])
  const [departemnt_work_arr,setdepartemnt_work_arr] = useState([])
  const [shilpa_foundation_receipt_payment_arr,setshilpa_foundation_receipt_payment_arr] = useState([])
  const [invoice_info,setinvoice_info] = useState([])


  const [questionaries,setquestionaries] = useState({
    total_no_of_inhouse_headcount:'',
    total_no_of_external_contractor_if_applicable:'',
    risk_managent_compliance:{
        any_risk_identified:'',
        any_mitigation_plans_in_place:'',
        compliance_is_upto_date:'',
    },

    financial_health:{
      avg_gross_salary_paid_in_last_quarter:'',
      avg_monthly_rent_paid_in_last_quarter:'',
      avg_operation_cost:'',
      avg_cost_for_vendor_service_quarter:'',
      avg_employee_engagement_expenses_quarter:'',
      avg_fue_food_or_any_allowence_paid_quarter:'',
      total_incentive_paid_in_last_quarter:'',
      total_statutory_payment_paid_in_last_quarter:'',
     
      total_quarterly_target_set_for_entire_bu:'',
      total_invoice_raised:'',
     
      profit_variance:'',
    },

    innovation_and_growth:{
        has_your_bu_adapted_technology:'',
        any_new_system_or_process_implemented:'',
        any_new_system_or_process_planned_for_next_quarter:'',
    },

    planning_and_outlook:{
        how_is_market_outlook_for_quarter:'',
        any_expansion_plan_into_new_market:'',
        any_additional_headcount_hiring_planned:'',
    },

    customer_and_stakeholder_satisfaction:{
        what_is_your_customer_satisfaction_rate:'',
        any_notable_customer_feedback_recieved_recently:'',
        how_would_you_rate_your_stakeholder_relationship_health:'',
        any_customer_lost_due_to_lack_of_engagement:'',
    },

    employee_engagment_and_development:{
        total_no_of_people_hired_in_last_quarter:'',
        total_no_of_people_exited_in_last_quarter:'',
        list_down_primary_reason_for_exits:'',
        which_plans_are_in_place_to_reduce_attrition:'',
        are_there_any_employee_development_and_engagement_programs:'',
    },

  })

  const {pathname,state} = useLocation()
  let path = pathname?.split("/")[pathname?.split("/")?.length - 1]


  const [departmentArr,setdepartmentArr] = useState([])

  // console.log("department",department)

  const [clientModal,setclientModal] = useState(false)
  const [invoiceModal,setinvoiceModal] = useState(false)
  const [expensesModal,setexpensesModal] = useState(false)
  const [eventsModal,seteventsModal] = useState(false)
  const [departmentworkModal,setdepartmentworkModal] = useState(false)
  const [shilpafoundationPaymentReceiptModal,setshilpafoundationPaymentReceiptModal] = useState(false)

  const navigate = useNavigate()


  // console.log("state",state)

  useEffect(()=>{
    if(path === 'edit'){
    setdata({
      revenue:state?.revenue,
      profit:state?.profit,
      expense:state?.expense,
      vendor_expenses:state?.vendor_expenses !== undefined ? state?.vendor_expenses : '',
      turn_over:state?.turn_over,
      outstanding:state?.outstanding,
      net_profit:state?.net_profit,
      total_no_of_painting:state?.total_no_of_painting,
      new_paintings_added_count:state?.new_paintings_added_count,
      paintings_sold_count:state?.paintings_sold_count,
      fund_raised:state?.fund_raised,
      fund_utilized:state?.fund_utilized,
      balance:state?.balance,
      remark:state?.remark,
      createdAt:(state?.createdAt !== '' && state?.createdAt !== undefined) ? state?.createdAt?.slice(0,10) : '',
      department:{label:state?.department?.department_name,value:state?.department?._id},
      id:state?.id,
      employee_info:{entry:state?.employee_info?.entry === undefined ? 0 : state?.employee_info?.entry,exit:state?.employee_info?.exit === undefined ? 0 : state?.employee_info?.exit}
    })
    setexpenses_bifurcation(state?.expenses_bifurcation)
    setnew_leads(state?.new_leads)
    setnew_events(state?.new_events)
    

    setquestionaries({...questionaries,
      total_no_of_inhouse_headcount:state?.total_no_of_inhouse_headcount,
      total_no_of_external_contractor_if_applicable:state?.total_no_of_external_contractor_if_applicable,
      risk_managent_compliance:state?.risk_managent_compliance,
      financial_health:state?.financial_health,
      innovation_and_growth:state?.innovation_and_growth,
      planning_and_outlook:state?.planning_and_outlook,
      customer_and_stakeholder_satisfaction:state?.customer_and_stakeholder_satisfaction,
      employee_engagment_and_development:state?.employee_engagment_and_development
    })


    setshilpa_foundation_receipt_payment_arr(state?.shilpa_foundation_receipt_payment === undefined ? [] : state?.shilpa_foundation_receipt_payment)
    setdepartemnt_work_arr(state?.department_work)
    setinvoice_info(state?.invoice_info === undefined ? [] : state?.invoice_info)
    }
    if((roles?.includes('admin') || roles?.includes('finance_head'))){
      getDepartment()
    }else{
      if(path === 'create'){
        let departmentData = user?.department_id[0]
        setdata({...data,department:{label:departmentData.department_name,value:departmentData?._id}})
      }
    }
  },[])

 


  async function getDepartment(){
    const response = await GetDepartmentService()
    const arr = []
    response.data.datas.forEach((d)=>{
      arr.push({label:d?.department_name,value:d?.id})
    })
     setdepartmentArr(arr)
  }
  



  function handlechange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function submitData(){
  
    // if(!data?.revenue){
    //   seterror({...error,revenue:'This field is required*'})
    // }else if(!data?.profit){
    //   seterror({...error,profit:'This field is required*'})
    // }else if(!data?.expense){
    //   seterror({...error,expense:'This field is required*'})
    // }else if(!data?.net_profit){
    //   seterror({...error,net_profit:'This field is required*'})
    // }else if(!data?.remark){
    //   seterror({...error,remark:'This field is required*'})
    // }else{
      // console.log("data",data)
    
      const send_Data = {...data}
      send_Data['department'] = data?.department?.value
      send_Data['expenses_bifurcation'] = expenses_bifurcation
      send_Data['new_events'] = new_events
      send_Data['department_work'] = departemnt_work_arr
      send_Data['new_leads'] = new_leads
      send_Data['invoice_info'] = invoice_info
      send_Data['shilpa_foundation_receipt_payment'] = shilpa_foundation_receipt_payment_arr
     
      send_Data['total_no_of_inhouse_headcount'] = questionaries?.total_no_of_inhouse_headcount
      send_Data['total_no_of_external_contractor_if_applicable'] = questionaries?.total_no_of_external_contractor_if_applicable
      send_Data['risk_managent_compliance'] = questionaries?.risk_managent_compliance
      send_Data['financial_health'] = questionaries?.financial_health
      send_Data['innovation_and_growth'] = questionaries?.innovation_and_growth
      send_Data['planning_and_outlook'] = questionaries?.planning_and_outlook
      send_Data['customer_and_stakeholder_satisfaction'] = questionaries?.customer_and_stakeholder_satisfaction
      send_Data['employee_engagment_and_development'] = questionaries?.employee_engagment_and_development


      if(path === 'create'){
        const response = await CreateVerticalProfitLossService(send_Data)
        if(response?.status === 201){
          toast.success("Profit/Loss Added Successfully")
          resetform()
        }
      }else{
        const send_Data = {...data}
        send_Data['department'] = data?.department?.value
        send_Data['createdAt'] = data?.createdAt
        send_Data['expenses_bifurcation'] = expenses_bifurcation
        send_Data['new_events'] = new_events
        send_Data['department_work'] = departemnt_work_arr
        send_Data['new_leads'] = new_leads
        send_Data['invoice_info'] = invoice_info
        send_Data['shilpa_foundation_receipt_payment'] = shilpa_foundation_receipt_payment_arr

        send_Data['total_no_of_inhouse_headcount'] = questionaries?.total_no_of_inhouse_headcount
        send_Data['total_no_of_external_contractor_if_applicable'] = questionaries?.total_no_of_external_contractor_if_applicable
        send_Data['risk_managent_compliance'] = questionaries?.risk_managent_compliance
        send_Data['financial_health'] = questionaries?.financial_health
        send_Data['innovation_and_growth'] = questionaries?.innovation_and_growth
        send_Data['planning_and_outlook'] = questionaries?.planning_and_outlook
        send_Data['customer_and_stakeholder_satisfaction'] = questionaries?.customer_and_stakeholder_satisfaction
        send_Data['employee_engagment_and_development'] = questionaries?.employee_engagment_and_development

      
        const response = await UpdateVerticalProfitLossService(send_Data,state._id)
        if(response?.status === 200){
          toast.success("Profit/Loss Updated Successfully")
          resetform()
          navigate(-1)
        }
      }
     
    // }
  } 

  function resetform(){
    setdata({turn_over:'',revenue:'',profit:'',expense:'',vendor_expenses:'',outstanding:'',net_profit:'',from_date:'',to_date:'',remark:'',department:'',new_paintings_added_count:'',paintings_sold_count:'',fund_raised:'',fund_utilized:'',balance:'',department:''})
    seterror({turn_over:'',revenue:'',profit:'',expense:'',vendor_expenses:'',outstanding:'',net_profit:'',from_date:'',to_date:'',remark:'',department:'',new_paintings_added_count:'',paintings_sold_count:'',fund_raised:'',fund_utilized:'',balance:'',department:''})
    setexpenses_bifurcation([])
    setnew_events([])
    setnew_leads([])
    setinvoice_info([])
    setdepartemnt_work_arr([])
    setshilpa_foundation_receipt_payment_arr([])
  }

  async function handlechange1(e){
    setlead({...lead,[e.target.name]:e.target.value})
    setleaderr({...leaderr,[e.target.name]:''})
  }

  async function handlechange5(e){
    setinvoice({...invoice,[e.target.name]:e.target.value})
    setinvoice_err({...invoice_err,[e.target.name]:''})
  }

  async function handlechange6(e){
    setdepartment_work({...department_work,[e.target.name]:e.target.value})
    setdepartment_work_err({...department_work_err,[e.target.name]:''})
  }

  async function handlechange7(e){  
    setshilpa_foundation_receipt_payment({...shilpa_foundation_receipt_payment,[e.target.name]:e.target.value})
    setshilpa_foundation_receipt_payment_err({...shilpa_foundation_receipt_payment_err,[e.target.name]:''})
  }

  async function handlechange2(e){
    setexpense({...expense,[e.target.name]:e.target.value})
    setexpenseerr({...expenseerr,[e.target.name]:''})
  }

  async function handlechange3(e){
    setevent({...event,[e.target.name]:e.target.value})
    seteventerr({...eventerr,[e.target.name]:''})
  }

  async function handlechange4(e){

    
    let employee_info = data.employee_info
    let employee_info_err = error.employee_info
    employee_info[e.target.name] = e.target.value
    employee_info_err[e.target.name] = ''
    setdata({...data,...employee_info})
    seterror({...error,...employee_info_err})
  }

  async function submitstep(v){
    if(v == 1){
       if(!lead.client_name){
        setleaderr({...leaderr,client_name:'This Field is required*'})
       }else if(!lead.requirement){
        setleaderr({...leaderr,requirement:'This Field is required*'})
       }else{
           if(lead?.index === undefined){
            let old_data = {...lead}
            lead.id = new_leads.length
            setnew_leads([...new_leads,old_data])
            setclientModal(!clientModal)
            setlead({client_name:'',requirement:'',department:''})
            setleaderr({client_name:'',requirement:'',department:''})
           }else{
            let old_data = [...new_leads]
            old_data[lead?.index] = lead
            setnew_leads(old_data)
            setclientModal(!clientModal)
            setlead({client_name:'',description:'',department:''})
            setleaderr({client_name:'',description:'',department:''})
           }
       }
    }if(v == 2){
      if(!expense.name){
       setexpenseerr({...expenseerr,name:'This Field is required*'})
      }else if(!expense.amount){
       setexpenseerr({...expenseerr,amount:'This Field is required*'})
      }else{
          if(expense?.index === undefined){
           let old_data = {...expense}
           old_data.id = expenses_bifurcation?.length
           setexpenses_bifurcation([...expenses_bifurcation,old_data])
           setexpensesModal(!expensesModal)
           setexpense({name:'',amount:'',user:''})
           setexpenseerr({name:'',amount:'',user:''})
          }else{
           let old_data = [...expenses_bifurcation]
           old_data[expense?.index] = expense
           setexpenses_bifurcation(old_data)
           setexpensesModal(!expensesModal)
           setexpense({name:'',amount:'',user:''})
           setexpenseerr({name:'',amount:'',user:''})
          }
      }
    }if(v == 3){
      if(!event.event_name){
       seteventerr({...eventerr,event_name:'This Field is required*'})
      }else if(!event.launched_date){
       seteventerr({...eventerr,launched_date:'This Field is required*'})
      }else{
          if(event?.index === undefined){
           let old_data = {...event}
           old_data.id = new_events.length
           setnew_events([...new_events,old_data])
           seteventsModal(!eventsModal)
           setevent({event_name:'',launched_date:''})
           seteventerr({event_name:'',launched_date:''})
          }else{
           let old_data = [...new_events]
           old_data[event?.index] = event
           setnew_events(old_data)
           seteventsModal(!eventsModal)
           setevent({event_name:'',launched_date:''})
           seteventerr({event_name:'',launched_date:''})
          }
      }
    }if(v == 4){
      if(!invoice.client_name){
        setinvoice_err({...invoice_err,client_name:'This Field is required*'})
       }else if(!invoice.requirement){
        setinvoice_err({...invoice_err,requirement:'This Field is required*'})
       }else if(!invoice.invoice_amt){
        setinvoice_err({...invoice_err,invoice_amt:'This Field is required*'})
       }else if(!invoice.collected){
        setinvoice_err({...invoice_err,collected:'This Field is required*'})
       }else if(!invoice.outstanding){
        setinvoice_err({...invoice_err,outstanding:'This Field is required*'})
       }else if(!invoice.invoice_no){
        setinvoice_err({...invoice_err,invoice_no:'This Field is required*'})
       }else if(!invoice.date){
        setinvoice_err({...invoice_err,date:'This Field is required*'})
       }else{
           if(invoice?.index === undefined){
            let old_data = {...invoice}
            invoice.id = invoice_info.length
            setinvoice_info([...invoice_info,old_data])
            setinvoiceModal(!invoiceModal)
            setinvoice({client_name:'',requirement:'',converted:true,invoice_amt:'',collected:'',outstanding:''})
            setinvoice_err({client_name:'',requirement:'',converted:true,invoice_amt:'',collected:'',outstanding:''})
           }else{
            let old_data = [...invoice_info]
            old_data[invoice?.index] = invoice
            setinvoice_info(old_data)
            setinvoiceModal(!invoiceModal)
            setinvoice({client_name:'',requirement:'',converted:true,invoice_amt:'',collected:'',outstanding:''})
            setinvoice_err({client_name:'',requirement:'',converted:true,invoice_amt:'',collected:'',outstanding:''})
           }
       }
    }if(v == 5){
      // console.log("department_work",department_work)
      if(!department_work.name){
        setdepartment_work_err({...department_work_err,name:'This Field is required*'})
       }else if(!department_work.work){
        setdepartment_work_err({...department_work_err,work:'This Field is required*'})
       }else if(!department_work.expense){
        setdepartment_work_err({...department_work_err,expense:'This Field is required*'})
       }else{
           if(department_work?.index === undefined){
            let old_data = {...department_work}
            department_work.id = departemnt_work_arr.length
            setdepartemnt_work_arr([...departemnt_work_arr,old_data])
            setdepartmentworkModal(!departmentworkModal)
            setdepartment_work({name:'',work:'',expense:''})
            setdepartment_work_err({name:'',work:'',expense:''})
           }else{
            let old_data = [...departemnt_work_arr]
            old_data[department_work?.index] = department_work
            setdepartemnt_work_arr(old_data)
            setdepartmentworkModal(!departmentworkModal)
            setdepartment_work({name:'',work:'',expense:''})
            setdepartment_work_err({name:'',work:'',expense:''})
           }
       }
    }if(v == 6){
      // console.log("department_work",department_work)
      if(!shilpa_foundation_receipt_payment.date){
        setshilpa_foundation_receipt_payment_err({...shilpa_foundation_receipt_payment_err,date:'This Field is required*'})
       }else if(!shilpa_foundation_receipt_payment.particulars){
        setshilpa_foundation_receipt_payment_err({...shilpa_foundation_receipt_payment_err,particulars:'This Field is required*'})
       }else if(!shilpa_foundation_receipt_payment.receipt){
        setshilpa_foundation_receipt_payment_err({...shilpa_foundation_receipt_payment_err,receipt:'This Field is required*'})
       }else{
           if(shilpa_foundation_receipt_payment?.index === undefined){
            let old_data = {...shilpa_foundation_receipt_payment}
            shilpa_foundation_receipt_payment.id = departemnt_work_arr.length
            setshilpa_foundation_receipt_payment_arr([...shilpa_foundation_receipt_payment_arr,old_data])
            setshilpafoundationPaymentReceiptModal(!shilpafoundationPaymentReceiptModal)
            setshilpa_foundation_receipt_payment({date:'',particulars:'',receipt:'',payment:'',closing_available_balance:'',remarks:''})
            setshilpa_foundation_receipt_payment_err({date:'',particulars:'',receipt:'',payment:'',closing_available_balance:'',remarks:''})
           }else{
            let old_data = [...departemnt_work_arr]
            old_data[department_work?.index] = department_work
            setshilpa_foundation_receipt_payment_arr(old_data)
            setshilpafoundationPaymentReceiptModal(!shilpafoundationPaymentReceiptModal)
            setshilpa_foundation_receipt_payment({date:'',particulars:'',receipt:'',payment:'',closing_available_balance:'',remarks:''})
            setshilpa_foundation_receipt_payment_err({date:'',particulars:'',receipt:'',payment:'',closing_available_balance:'',remarks:''})
           }
       }
    }
  }

  function deletefunc(){
     if(deletedData?.type === "new_leads"){
        let arr = [...new_leads]
        arr.splice(deletedData?.index)
        setnew_leads(arr)
        setdeleteModal(!deleteModal)
     }

     if(deletedData?.type ===  "expenses_bifurcation"){
      let arr = [...expenses_bifurcation]
      arr.splice(deletedData?.index)
      setexpenses_bifurcation(arr)
      setdeleteModal(!deleteModal)
     }

     if(deletedData?.type === "new_events"){
      let arr = [...new_events]
      arr.splice(deletedData?.index)
      setnew_events(arr)
      setdeleteModal(!deleteModal)
     }

     if(deletedData?.type === "invoice_info"){
      let arr = [...invoice_info]
      arr.splice(deletedData?.index)
      setinvoice_info(arr)
      setdeleteModal(!deleteModal)
     }

     if(deletedData?.type === "departemnt_work_arr"){
      let arr = [...departemnt_work_arr]
      arr.splice(deletedData?.index)
      setdepartemnt_work_arr(arr)
      setdeleteModal(!deleteModal)
     }

     if(deletedData?.type === "shilpa_foundation_receipt_payment"){
      let arr = [...shilpa_foundation_receipt_payment_arr]
      arr.splice(deletedData?.index)
      setshilpa_foundation_receipt_payment_arr(arr)
      setdeleteModal(!deleteModal)
   }


  }

  async function validateData(){
     
      if(excel_file_modal == 1){
        const file = excel_file;
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
   
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          if(excelData.length === 0){
            toast.error("Invalid File Format")
          }else{
            let arr  = []
            excelData?.slice(1).forEach((d)=>{
              arr.push({name:d[5],amount:d[2],user:d[6],remark:d[9]})
            })

            setexpenses_bifurcation([...expenses_bifurcation,...arr])
            setexcel_file_modal('')
            setexcel_file(null)
            toast.success(`Expenses Excel Uploaded Successfully ${parseInt(excelData.length-1)} Items Inserted`)
          }
        }
        reader.readAsArrayBuffer(file);
      }
  }

  function handlechangequestionaries(type,obj,key,e){
    setquestionaries((prevQuestionaries) => ({
      ...prevQuestionaries,
      [obj]: {
        ...(prevQuestionaries[obj] || {}), // Preserve existing keys for the object
        [key]: e.target.value,            // Update the specific key
      },
    }));
  }

  // console.log("date",data?.department)

  return (
    <div className='flex'>
      <ProfitLossMenu />
      <div className='ml-5 w-[85%] overflow-x-scroll'>
          <GoBack />

           
              
            <div className='border-b pb-2'>
              <h6 className='text-[13px] mb-1 font-[800]'>Add / Edit Profit / Loss For the week</h6>
              <h6 className='text-[12px] bg-slate-200 p-2'>Use the below form to create or update the profit loss for the current week.</h6>
            </div>

            <Modal open={excel_file_modal !== ''} width={350} footer={false} closable={false}>
              <h6 className='text-[14px] font-[700]'>Select Excel Fie </h6>
              <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>{excel_file_modal == 1 && 'Select the expenses list excel to upload and validate before uploading'}</h6> 

              <div className='mt-2'>
              <Uploader image={excel_file}  setimagefunc={(e)=>{setexcel_file(e)}}  removeimageuploadfunc = {()=>{setexcel_file(null)}}/>    
              </div> 
              <div className='flex border-t pt-2 mt-2 justify-end items-center'>
                 <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>{setexcel_file_modal('');setexcel_file(null)}} />   
                 <h6 className='w-[10px]'></h6>
                 <ButtonFilledAutoWidth btnName="Save" onClick={()=>validateData()} />   
                 </div>

            </Modal>

            <Modal open={deleteModal} width={260} footer={false} closable={false}>
            <h6 className='text-[14px] font-[700]'>Are You sure </h6>
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Be sure before deleting the <span className='font-[800]'>{deletedData?.type}</span> once deleted will not be retrieved</h6> 

            <div className='flex border-t pt-2 mt-2 justify-end items-center'>
                 <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setdeleteModal(!deleteModal)} />   
                 <h6 className='w-[10px]'></h6>
                 <ButtonFilledAutoWidth btnName="Save" onClick={()=>deletefunc()} />   
                 </div>

            </Modal>

            <Modal open={clientModal} width={260} footer={false} closable={false}>

                 <h6 className='text-[14px] font-[700]'>Add / Edit Leads Generated </h6>  
                 <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Use the below form to create or update the leads generated for this week</h6> 

                 <TextInput
                    mandatory={true}
                    label={'Client Name'}  
                    variant="standard"
                    name="client_name"
                    type="text"
                    value={lead?.client_name}
                    error={leaderr?.client_name}
                    handlechange={handlechange1}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                  }}
                 
                 />       

                <TextAreaInput1
                    mandatory={true}
                    label={'Requirment'}  
                    variant="standard"
                    name="requirement"
                    type="text"
                    value={lead?.requirement}
                    error={leaderr?.requirement}
                    handlechange={handlechange1}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                  }}
                 
                 />  



                <div className='-mt-2'>
                {['Digital Media','Corp Team']?.includes(data.department?.label) &&
                 <TextInput
                    mandatory={true}
                    label={'Department'}  
                    variant="standard"
                    name="department"
                    type="text"
                    value={lead?.department}
                    error={leaderr?.department}
                    handlechange={handlechange1}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                  }}
                 
                 />}  
                 </div>  

            
            {['Digital Media','Corp Team']?.includes(data.department?.label) &&
            <div className='flex mt-2' onClick={()=>setlead({...lead,converted:!lead.converted})}>
                  {!lead?.converted ?
                  <BiCheckbox size={20} className='text-slate-200' /> : 
                  <BiCheckboxSquare size={20} className='text-slate-600' />}
                  <h6 className='text-[11px] font-[800] ml-2'>Converted</h6>
             </div>}

                 
                 
                 <div className='flex border-t pt-2 mt-2 justify-end items-center'>
                 <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setclientModal(!clientModal)} />   
                 <h6 className='w-[10px]'></h6>
                 <ButtonFilledAutoWidth btnName="Save" onClick={()=>submitstep(1)} />   
                 </div>
            </Modal>

            <Modal open={departmentworkModal} width={260} footer={false} closable={false}>

            <h6 className='text-[14px] font-[700]'>Add / Edit Invoice Generated</h6>  
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Use the below form to create or update the invoice generated for this week</h6> 

            <TextInput
              mandatory={true}
              label={'Name'}  
              variant="standard"
              name="name"
              type="text"
              value={department_work?.name}
              error={department_work_err?.name}
              handlechange={handlechange6}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />       

            <TextAreaInput1
              mandatory={true}
              label={'Work'}  
              variant="standard"
              name="work"
              type="text"
              value={department_work?.work}
              error={department_work_err?.work}
              handlechange={handlechange6}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />  

            <TextInput
              mandatory={true}
              label={'Expense'}  
              variant="standard"
              name="expense"
              type="number"
              value={department_work?.expense}
              error={department_work_err?.expense}
              handlechange={handlechange6}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />    



            <div className='flex border-t pt-2 mt-2 justify-end items-center'>
            <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setdepartmentworkModal(!departmentworkModal)} />   
            <h6 className='w-[10px]'></h6>
            <ButtonFilledAutoWidth btnName="Save" onClick={()=>submitstep(5)} />   
            </div>
            </Modal>

            <Modal open={invoiceModal} width={260} footer={false} closable={false}>

            <h6 className='text-[14px] font-[700]'>Add / Edit Invoice </h6>  
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Use the below form to create or update the invoice  for this week</h6> 

            <TextInput
              mandatory={true}
              label={'Client Name'}  
              variant="standard"
              name="client_name"
              type="text"
              value={invoice?.client_name}
              error={invoice_err?.client_name}
              handlechange={handlechange5}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />       

            <TextAreaInput1
              mandatory={true}
              label={'Requirment'}  
              variant="standard"
              name="requirement"
              type="text"
              value={invoice?.requirement}
              error={invoice_err?.requirement}
              handlechange={handlechange5}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />  
 
             {/* <div className='flex' onClick={()=>setinvoice({...invoice,converted:!invoice.converted})}>
                  {!invoice?.converted ?
                  <BiCheckbox size={20} className='text-slate-200' /> : 
                  <BiCheckboxSquare size={20} className='text-slate-600' />}
                  <h6 className='text-[11px] font-[800] ml-2'>Converted</h6>
             </div>   */}

            <TextInput
              mandatory={true}
              label={'Invoice Amt'}  
              variant="standard"
              name="invoice_amt"
              type="number"
              value={invoice?.invoice_amt}
              error={invoice_err?.invoice_amt}
              handlechange={handlechange5}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />    


          <TextInput
              mandatory={true}
              label={'Collected'}  
              variant="standard"
              name="collected"
              type="number"
              value={invoice?.collected}
              error={invoice_err?.collected}
              handlechange={handlechange5}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />    


          <TextInput
              mandatory={true}
              label={'Outstanding'}  
              variant="standard"
              name="outstanding"
              type="number"
              value={invoice?.outstanding}
              error={invoice_err?.outstanding}
              handlechange={handlechange5}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />    

            <TextInput
              mandatory={true}
              label={'Invoice No'}  
              variant="standard"
              name="invoice_no"
              type="text"
              value={invoice?.invoice_no}
              error={invoice_err?.invoice_no}
              handlechange={handlechange5}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />   

            <TextInput
              mandatory={true}
              label={'Invoice Date'}  
              variant="standard"
              name="date"
              type="date"
              value={invoice?.date}
              error={invoice_err?.date}
              handlechange={handlechange5}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />      



            <div className='flex border-t pt-2 mt-2 justify-end items-center'>
            <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setinvoiceModal(!invoiceModal)} />   
            <h6 className='w-[10px]'></h6>
            <ButtonFilledAutoWidth btnName="Save" onClick={()=>submitstep(4)} />   
            </div>
            </Modal>

            <Modal open={expensesModal} width={260} footer={false} closable={false}>

            <h6 className='text-[14px] font-[700]'>Add / Edit Expenses Made</h6>  
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Use the below form to create or update the expensese made for this week</h6> 

            <TextInput
              mandatory={true}
              label={'Expenses Name'}  
              variant="standard"
              name="name"
              type="text"
              value={expense?.name}
              error={expenseerr?.name}
              handlechange={handlechange2}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />       

            <TextInput
              mandatory={true}
              label={'Amount'}  
              variant="standard"
              name="amount"
              type="number"
              value={expense?.amount}
              error={expenseerr?.amount}
              handlechange={handlechange2}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />   

             <TextInput
              mandatory={false}
              label={'Amount Received By'}  
              variant="standard"
              name="user"
              type="text"
              value={expense?.user}
              error={expenseerr?.user}
              handlechange={handlechange2}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />    

             <TextAreaInput1
              mandatory={false}
              label={'Remarks'}  
              variant="standard"
              name="remark"
              type="text"
              value={expense?.remark}
              error={expenseerr?.remark}
              handlechange={handlechange2}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />     

   

            <div className='flex border-t pt-2 mt-2 justify-end items-center'>
            <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setexpensesModal(!expensesModal)} />   
            <h6 className='w-[10px]'></h6>
            <ButtonFilledAutoWidth btnName="Save" onClick={()=>submitstep(2)} />   
            </div>
            </Modal>

            <Modal open={eventsModal} width={260} footer={false} closable={false}>

            <h6 className='text-[14px] font-[700]'>Add / Edit Events Organized</h6>  
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Use the below form to create or update the events organized for this week</h6> 

            <TextInput
              mandatory={true}
              label={'Event Name'}  
              variant="standard"
              name="event_name"
              type="text"
              value={event?.event_name}
              error={eventerr?.event_name}
              handlechange={handlechange3}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />       

            <TextInput
              mandatory={true}
              label={'Launched Date'}  
              variant="standard"
              name="launched_date"
              type="date"
              value={event?.launched_date}
              error={eventerr?.launched_date}
              handlechange={handlechange3}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />    

            <div className='flex border-t pt-2 mt-2 justify-end items-center'>
            <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>seteventsModal(!eventsModal)} />   
            <h6 className='w-[10px]'></h6>
            <ButtonFilledAutoWidth btnName="Save" onClick={()=>submitstep(3)} />   
            </div>
            </Modal>

            <Modal open={shilpafoundationPaymentReceiptModal} width={260} footer={false} closable={false}>

            <h6 className='text-[14px] font-[700]'>Add / Edit Payment Receipt</h6>  
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Use the below form to create or update the payment receipt </h6> 

            <TextInput
              mandatory={true}
              label={'Date'}  
              variant="standard"
              name="date"
              type="date"
              value={shilpa_foundation_receipt_payment?.date}
              error={shilpa_foundation_receipt_payment_err?.date}
              handlechange={handlechange7}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />       

            <TextAreaInput1
              mandatory={true}
              label={'Particulars'}  
              variant="standard"
              name="particulars"
              type="text"
              value={shilpa_foundation_receipt_payment?.particulars}
              error={shilpa_foundation_receipt_payment_err?.particulars}
              handlechange={handlechange7}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />    

<TextInput
              mandatory={true}
              label={'Receipt'}  
              variant="standard"
              name="receipt"
              type="number"
              value={shilpa_foundation_receipt_payment?.receipt}
              error={shilpa_foundation_receipt_payment_err?.receipt}
              handlechange={handlechange7}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />   

<TextInput
              mandatory={true}
              label={'Payment'}  
              variant="standard"
              name="payment"
              type="number"
              value={shilpa_foundation_receipt_payment?.payment}
              error={shilpa_foundation_receipt_payment_err?.payment}
              handlechange={handlechange7}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />   

<TextInput
              mandatory={true}
              label={'Closing Available Balance'}  
              variant="standard"
              name="closing_available_balance"
              type="number"
              value={shilpa_foundation_receipt_payment?.closing_available_balance}
              error={shilpa_foundation_receipt_payment_err?.closing_available_balance}
              handlechange={handlechange7}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />   

<TextAreaInput1
              mandatory={true}
              label={'Remarks'}  
              variant="standard"
              name="remarks"
              type="text"
              value={shilpa_foundation_receipt_payment?.remarks}
              error={shilpa_foundation_receipt_payment_err?.remarks}
              handlechange={handlechange7}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
            }}

            />    

            <div className='flex border-t pt-2 mt-2 justify-end items-center'>
            <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setshilpafoundationPaymentReceiptModal(!shilpafoundationPaymentReceiptModal)} />   
            <h6 className='w-[10px]'></h6>
            <ButtonFilledAutoWidth btnName="Save" onClick={()=>submitstep(6)} />   
            </div>
            </Modal>
{/* 
            <h6 className='text-[11px] font-[600] mb-1 mt-2' >From Date</h6>   
            <div className='border border-slate-300 '>
            <input type='date' value={data?.from_date} onChange={(v) => {setdata({...data,from_date:v.target.value})}}  placeholder='' bordered={false} className='text-[12px] border-l-4 border-l-slate-600 px-1 py-1.5 focus:outline-none w-[100%]' /> 
            </div>

            <h6 className='text-[11px] font-[600] mb-1 mt-2' >To Date</h6>   
            <div className='border border-slate-300 '>
            <input type='date' value={data?.to_date} onChange={(v) => {setdata({...data,to_date:v.target.value})}}  placeholder='' bordered={false} className='text-[12px] border-l-4 border-l-slate-600 px-1 py-1.5 focus:outline-none w-[100%]' /> 
            </div> */}
             
            <div className='flex w-[100%]'>
              <div className='mt-2'>
              
              <div className='flex items-center'>
                <div className='w-[220px] mr-2'>
                <h6 className='text-[11px] font-[600] mb-1' >{'Department'}</h6>

                <Select 
                    
                    bordered={false}
                    options={departmentArr} 
                    disabled={!(roles?.includes('admin') || roles?.includes('finance_head'))}
                    placeholder="" 
                    value={data?.department !== '' ? data?.department : null} 
                    onChange={(e)=>setdata({...data,department:departmentArr?.find((f)=>f?.value ===e)})} 
                    size='small' 
                    className='border border-l-4 border-l-slate-600 w-[100%] border-slate-300 py-[2px] mr-2'
                  />
                </div>

                {(roles?.includes('admin') || roles?.includes('finance_head')) &&
                <div className='w-[220px]'>
                <h6 className='text-[11px] font-[600] mb-1' >{'Created Date'}</h6>
                <input type='date' className='border border-slate-300 px-1 w-full focus:outline-none focus:ring-0 text-[11px] py-[5px]' value={data?.createdAt} onChange={(e)=>{setdata({...data,createdAt:e.target.value})}} disabled={!(roles?.includes('admin') || roles?.includes('finance_head'))} />
                </div>}
              </div>

             

              {data?.department !== '' &&
              <>
               <div className='flex text-[12px] mt-5 font-[600] cursor-pointer'>
               <h6 onClick={()=>setstep(1)} className={`${step === 1 && 'bg-slate-600 text-white'} px-[10px] py-[5px] text-[11px]`}>Basic Info</h6>
               <h6 onClick={()=>setstep(2)} className={`${step === 2 && 'bg-slate-600 text-white'} px-[10px] py-[5px] text-[11px]`}>More Info</h6>
               <h6 onClick={()=>setstep(3)} className={`${step === 3 && 'bg-slate-600 text-white'} px-[10px] py-[5px] text-[11px]`}>Questionaries Info</h6>
             </div>
              {step === 1 &&
              <div className='border-t'>
                <div className='flex'>
                <div className='w-60 pr-5 mr-5 border-r '>
                {['Transaction Team','Project Team','FMS Team','HR Team','Fidelitus Gallery','Shilpa Foundation','Corp Team']?.includes(data?.department?.label) &&
                <div>
                

                {/* {['Project Team','FMS Team']?.includes(department[0]?.department_name) &&  */}
                {/* <TextInput 
                mandatory={true}
                label={'Turn Over'}  
                variant="standard"
                name="turn_over"
                type="number"
                value={data?.turn_over}
                error={error?.turn_over}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}
                /> */}
                {/* } */}


              {['Transaction Team','Project Team','FMS Team','HR Team','Fidelitus Gallery','Corp Team']?.includes(data?.department?.label)  &&
              <>
                <TextInput 
                mandatory={true}
                label={'Turn Over'}  
                variant="standard"
                name="turn_over"
                type="number"
                value={data?.turn_over}
                error={error?.turn_over}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}
                />

                <TextInput 
                mandatory={true}
                label={'Revenue'}  
                variant="standard"
                name="revenue"
                type="number"
                value={data?.revenue}
                error={error?.revenue}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}
                />

              <TextInput 
                mandatory={true}
                label={'Profit'}  
                variant="standard"
                name="profit"
                type="number"
                value={data?.profit}
                error={error?.profit}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}
                />

            <TextInput 
                mandatory={true}
                label={'Expense'}  
                variant="standard"
                name="expense"
                type="number"
                value={data?.expense}
                error={error?.expense}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}
                />

          {['Project Team','FMS Team']?.includes(data?.department?.label)  &&
          <TextInput 
                mandatory={true}
                label={'Vendor Expense'}  
                variant="standard"
                name="vendor_expenses"
                type="number"
                value={data?.vendor_expenses}
                error={error?.vendor_expenses}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}
                />}

                <TextInput 
                mandatory={true}
                label={'Net Profit'}  
                variant="standard"
                name="net_profit"
                type="number"
                value={data?.net_profit}
                error={error?.net_profit}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}
                />

              <TextInput 
                mandatory={true}
                label={'Outstanding'}  
                variant="standard"
                name="outstanding"
                type="number"
                value={data?.outstanding}
                error={error?.outstanding}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}
                />
              </>}

                {/* <div className='flex' onClick={()=>setactive(!active)}>
                  {!active ?
                  <BiCheckbox size={20} className='text-slate-200' /> : 
                  <BiCheckboxSquare size={20} className='text-slate-600' />}
                </div> */}

            
              {['Fidelitus Gallery']?.includes(data?.department?.label) && 
                <>
                 <TextInput 
                  mandatory={true}
                  label={'Total No of Painiting Count'}  
                  variant="standard"
                  name="total_no_of_painting"
                  type="number"
                  value={data?.total_no_of_painting}
                  error={error?.total_no_of_painting}
                  handlechange={handlechange}
                  placeholder=""
                  InputLabelProps={{
                      style: { color: '#fff', }, 
                  }}
                />
              
                <TextInput 
                  mandatory={true}
                  label={'Sold Painiting Count'}  
                  variant="standard"
                  name="paintings_sold_count"
                  type="number"
                  value={data?.paintings_sold_count}
                  error={error?.paintings_sold_count}
                  handlechange={handlechange}
                  placeholder=""
                  InputLabelProps={{
                      style: { color: '#fff', }, 
                  }}
                />
                <TextInput 
                mandatory={true}
                label={'New Painiting Count'}  
                variant="standard"
                name="new_paintings_added_count"
                type="number"
                value={data?.new_paintings_added_count}
                error={error?.new_paintings_added_count}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}
                />
                </>
              }
                </div>}

                {['Shilpa Foundation']?.includes(data?.department?.label) && 
                  <>
                    <TextInput 
                      mandatory={true}
                      label={'Fund Raised'}  
                      variant="standard"
                      name="fund_raised"
                      type="number"
                      value={data?.fund_raised}
                      error={error?.fund_raised}
                      handlechange={handlechange}
                      placeholder=""
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}
                    />
                    <TextInput 
                    mandatory={true}
                    label={'Fund Utlilized'}  
                    variant="standard"
                    name="fund_utilized"
                    type="number"
                    value={data?.fund_utilized}
                    error={error?.fund_utilized}
                    handlechange={handlechange}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}
                    />
                    <TextInput 
                    mandatory={true}
                    label={'Balance'}  
                    variant="standard"
                    name="balance"
                    type="number"
                    value={data?.balance}
                    error={error?.balance}
                    handlechange={handlechange}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}
                    />
                  </>
                }



                <TextAreaInput1 
                    mandatory={false}
                    label={'Remarks'}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={data?.remark}
                    error={error?.remark}
                    handlechange={handlechange}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>


                </div>

                {['Fidelitus Gallery','Shilpa Foundation']?.includes(data?.department?.label) && 
                <div className='w-60 pr-5 mr-5 border-r mt-2'>
                  <div className='flex items-center justify-between'>
                  <h6 className='text-[11px] font-[700]'>Events</h6>
                  <AiOutlinePlus onClick={()=>seteventsModal(!eventsModal)} className='cursor-pointer bg-slate-300 p-[2px]' />
                  </div>

                  
                  <div className='border mt-1'>
                  <div className='flex items-center'>
                    <h6 className='bg-slate-100 font-[800] w-[40%] border-r text-[10px] px-[5px] py-[4px]'>Event Name</h6>
                    <h6 className='bg-slate-100 font-[800] w-[40%] border-r text-[10px] px-[5px] py-[4px]'>Date</h6>
                    <h6 className='bg-slate-100 font-[800] w-[20%] text-[10px] px-[5px] py-[4px]'>Action</h6>
                  </div>
                  {new_events?.map((l,i)=>(
                  <div className='flex border-t'>
                    <h6 className='w-[40%] text-[10px] border-r px-[5px] py-[4px]'>{l?.event_name}</h6>
                    <h6 className='w-[40%] text-[10px] border-r px-[5px] py-[4px]'>{moment(l?.launched_date)?.format('LL')}</h6>
                    <h6 className='w-[20%] flex text-[10px] px-[5px] py-[4px]'>
                      <AiOutlineEdit size={12} className='mr-2' onClick={()=>{setevent({...l,index:i});seteventsModal(true)}} />
                      <AiOutlineDelete  size={12} onClick={()=>{setdeleteData({type:'new_events',index:i,data:l});setdeleteModal(true)}} />
                    </h6>
                  </div>))}
                  </div>
                  
                </div>}

                <div className='w-60 pr-5 mr-5 border-r mt-2'>
                  <div className='flex items-center justify-between'>
                  <h6 className='text-[11px] font-[700]'>Generated New Leads</h6>
                  <AiOutlinePlus onClick={()=>setclientModal(!clientModal)} className='cursor-pointer bg-slate-300 p-[2px]' />
                  </div>

                  
                  <div className='border mt-1'>
                  <div className='flex items-center'>
                    <h6 className='bg-slate-100 font-[800] w-[50%] border-r text-[10px] px-[5px] py-[4px]'>Client Name</h6>
                    <h6 className='bg-slate-100 font-[800] w-[50%] border-r text-[10px] px-[5px] py-[4px]'>Requirment</h6>
                    <h6 className='bg-slate-100 font-[800] w-[50%] text-[10px] px-[5px] py-[4px]'>Action</h6>
                  </div>
                  {new_leads?.map((l,i)=>(
                  <div className='flex  border-t'>
                    <h6 className='w-[50%] text-[10px] border-r px-[5px] py-[4px]'>{l?.client_name}</h6>
                    <h6 className='w-[50%] text-[10px] border-r px-[5px] py-[4px]'>{l?.requirement}</h6>
                    <h6 className='w-[50%] flex text-[10px] px-[5px] py-[4px]'>
                      <AiOutlineEdit size={12} className='mr-2' onClick={()=>{setlead({...l,index:i});setclientModal(true)}} />
                      <AiOutlineDelete  size={12} onClick={()=>{setdeleteData({type:'new_leads',index:i,data:l});setdeleteModal(true)}} />
                    </h6>
                  </div>))}
                  </div>
                  
                </div>   

                <div className='w-[600px] pr-5 mr-5 mt-2 border-r'>
                  <div className='flex items-center justify-between'>
                  <h6 className='text-[11px] font-[700]'>Expenses List</h6>
                  
                  <div className='flex'>
                  <AiOutlineFileExcel onClick={()=>setexcel_file_modal(1)}  className='cursor-pointer bg-slate-300 p-[2px] mr-2'/>
                  <AiOutlinePlus onClick={()=>setexpensesModal(!expensesModal)} className='cursor-pointer bg-slate-300 p-[2px]' />
                  </div>
                  </div>

                  
                  <div className='border mt-1'>
                  <div className='flex items-center'>
                    <h6 className='bg-slate-100 font-[800] w-[50%] border-r text-[10px] px-[5px] py-[4px]'>Type</h6>
                    <h6 className='bg-slate-100 font-[800] w-[50%] border-r text-[10px] px-[5px] py-[4px]'>Amount</h6>
                    <h6 className='bg-slate-100 font-[800] w-[50%] border-r text-[10px] px-[5px] py-[4px]'>Employee</h6>
                    <h6 className='bg-slate-100 font-[800] w-[50%] border-r text-[10px] px-[5px] py-[4px]'>Remarks</h6>
                    <h6 className='bg-slate-100 font-[800] w-[50%] text-[10px] px-[5px] py-[4px]'>Action</h6>
                  </div>
                  {expenses_bifurcation?.map((l,i)=>(
                  <div className='flex  border-t'>
                    <h6 className='w-[50%] text-[10px] border-r px-[5px] py-[4px]'>{l?.name}</h6>
                    <h6 className='w-[50%] text-[10px] border-r px-[5px] py-[4px]'>{l?.amount}</h6>
                    <h6 className='w-[50%] text-[10px] border-r px-[5px] py-[4px]'>{l?.user}</h6>
                    <h6 className='w-[50%] text-[10px] border-r px-[5px] py-[4px]'>{l?.remark}</h6>
                    <h6 className='w-[50%] flex text-[10px] px-[5px] py-[4px]'>
                      <AiOutlineEdit size={12} className='mr-2' onClick={()=>{setexpense({...l,index:i});setexpensesModal(true)}} />
                      <AiOutlineDelete  size={12} onClick={()=>{setdeleteData({type:'expenses_bifurcation',index:i,data:l});setdeleteModal(true)}} />
                    </h6>
                  </div>))}
                  </div>
                  
                </div> 

              
                </div>  
              </div>}

              {step === 2 &&
              <div className='border-t'>
                <div className='flex'>
                <div className='w-60 border-r pr-5 mr-5 mt-2'>
                  <div>
                    <h6 className='text-[11px] font-[700]'>Employee In / Out</h6>
 
                    <TextInput 
                      mandatory={true}
                      label={'Employee In'}  
                      variant="standard"
                      name="entry"
                      type="number"
                      value={data?.employee_info?.entry}
                      error={error?.employee_info?.entry}
                      handlechange={handlechange4}
                      placeholder=""
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}
                      />

                    <TextInput 
                      mandatory={true}
                      label={'Employee Out'}  
                      variant="standard"
                      name="exit"
                      type="number"
                      value={data?.employee_info?.exit}
                      error={error?.employee_info?.exit}
                      handlechange={handlechange4}
                      placeholder=""
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}
                      />  

                  </div>
                </div>  

                <div>

                {
                <div className='w-[550px]  pr-5 mr-5 border-r mt-2'>
                  <div className='flex items-center justify-between'>
                    <h6 className='text-[11px] font-[700]'>Outstanding Info </h6>
                    <AiOutlinePlus onClick={()=>setinvoiceModal(!invoiceModal)} className='cursor-pointer bg-slate-300 p-[2px]' />
                  </div>

                  
                  <div className='border mt-1'>
                  <div className='flex items-center'>
                    <h6 className='bg-slate-100 font-[800] w-[35%] border-r text-[10px] px-[5px] py-[4px]'>Client Name / Requirment</h6>
                    {/* <h6 className='bg-slate-100 font-[800] w-[15%] border-r text-[10px] px-[5px] py-[4px]'>Requirment</h6> */}
                    <h6 className='bg-slate-100 font-[800] w-[20%] border-r text-[10px] px-[5px] py-[4px]'>Invoice Amt</h6>
                    <h6 className='bg-slate-100 font-[800] w-[25%] border-r text-[10px] px-[5px] py-[4px]'>Amt Collected</h6>
                    <h6 className='bg-slate-100 font-[800] w-[25%] border-r text-[10px] px-[5px] py-[4px]'>Amt Outstanding</h6>
                    <h6 className='bg-slate-100 font-[800] w-[25%] text-[10px] px-[5px] py-[4px]'>Action</h6>
                  </div>
                  {invoice_info?.map((l,i)=>(
                  <div className='flex border-t'>
                    <h6 className='w-[35%] text-[10px] border-r px-[5px] py-[4px]'>{l?.client_name} / {l?.requirement} / <span className='font-[700]'>{l?.invoice_no}</span> / {moment(l?.date)?.format('LL')}</h6>
                    {/* <h6 className='w-[30%] text-[10px] border-r px-[5px] py-[4px]'>{l?.requirement}</h6> */}
                    <h6 className='w-[20%] text-[10px] border-r px-[5px] py-[4px]'>{Priceconstants(parseInt(l?.invoice_amt))}</h6>
                    <h6 className='w-[25%] text-[10px] border-r px-[5px] py-[4px]'>{Priceconstants(parseInt(l?.collected))}</h6>
                    <h6 className='w-[25%] text-[10px] border-r px-[5px] py-[4px]'>{Priceconstants(parseInt(l?.outstanding))}</h6>
                    <h6 className='flex font-[800] w-[25%] text-[10px] px-[5px] py-[4px]'>
                      <AiOutlineEdit size={12} className='mr-2' onClick={()=>{setinvoice({...l,date:l?.date === undefined ? '' : l?.date?.slice(0,10),index:i});setinvoiceModal(true)}} />
                      <AiOutlineDelete  size={12} onClick={()=>{setdeleteData({type:'invoice_info',index:i,data:l});setdeleteModal(true)}} />
                    </h6>
                  </div>))}
                  </div>
                  
                </div> }

                {['Digital Media','Fidelitus Gallery']?.includes(data?.department?.label) && 
                <div className='w-[300px] pr-5 mr-5 border-r mt-4'>
                  <div className='flex items-center justify-between'>
                    <h6 className='text-[11px] font-[700]'>Team Task info</h6>
                    <AiOutlinePlus onClick={()=>setdepartmentworkModal(!departmentworkModal)} className='cursor-pointer bg-slate-300 p-[2px]' />
                  </div>

                  
                  <div className='border mt-1'>
                  <div className='flex items-center'>
                    <h6 className='bg-slate-100 font-[800] w-[50%] border-r text-[10px] px-[5px] py-[4px]'>Name</h6>
                    <h6 className='bg-slate-100 font-[800] w-[50%] border-r text-[10px] px-[5px] py-[4px]'>Work</h6>
                    <h6 className='bg-slate-100 font-[800] w-[50%] border-r text-[10px] px-[5px] py-[4px]'>Expense</h6>
                    <h6 className='bg-slate-100 font-[800] w-[50%] border-r text-[10px] px-[5px] py-[4px]'>Action</h6>
                  </div>
                  {departemnt_work_arr?.map((l,i)=>(
                  <div className='flex border-t'>
                    <h6 className='w-[50%] text-[10px] border-r px-[5px] py-[4px]'>{l?.name}</h6>
                    <h6 className='w-[50%] text-[10px] border-r px-[5px] py-[4px]'>{l?.work}</h6>
                    <h6 className='w-[50%] text-[10px] border-r px-[5px] py-[4px]'>{Priceconstants(parseInt(l?.expense))}</h6>
                    <h6 className='w-[50%] flex text-[10px] px-[5px] py-[4px]'>
                      <AiOutlineEdit size={12} className='mr-2' onClick={()=>{setdepartment_work({...l,index:i});setdepartmentworkModal(true)}} />
                      <AiOutlineDelete  size={12} onClick={()=>{setdeleteData({type:'departemnt_work_arr',index:i,data:l});setdeleteModal(true)}} />
                    </h6>
                  </div>))}
                  </div>
                  
                </div>}

                {['Shilpa Foundation']?.includes(data?.department?.label) &&
                <div className='w-[700px]  pr-5 mr-5 border-r mt-4'>
                  <div className='flex items-center justify-between'>
                    <h6 className='text-[11px] font-[700]'>Payment Receipt </h6>
                    <AiOutlinePlus onClick={()=>setshilpafoundationPaymentReceiptModal(!shilpafoundationPaymentReceiptModal)} className='cursor-pointer bg-slate-300 p-[2px]' />
                  </div>

                  
                  <div className='border mt-1'>
                  <div className='flex items-center'>
                    <h6 className='bg-slate-100 font-[800] w-[10%] border-r text-[10px] px-[5px] py-[4px]'>Date </h6>
                    <h6 className='bg-slate-100 font-[800] w-[10%] border-r text-[10px] px-[5px] py-[4px]'>Particulars</h6>
                    <h6 className='bg-slate-100 font-[800] w-[10%] border-r text-[10px] px-[5px] py-[4px]'>Receipt</h6>
                    <h6 className='bg-slate-100 font-[800] w-[10%] border-r text-[10px] px-[5px] py-[4px]'>Payment</h6>
                    <h6 className='bg-slate-100 font-[800] w-[25%] border-r text-[10px] px-[5px] py-[4px]'>Closing/Available Balance</h6>
                    <h6 className='bg-slate-100 font-[800] w-[25%] border-r text-[10px] px-[5px] py-[4px]'>Remarks</h6>
                    <h6 className='bg-slate-100 font-[800] w-[10%] text-[10px] px-[5px] py-[4px]'>Action</h6>
                  </div>
                  {shilpa_foundation_receipt_payment_arr?.map((l,i)=>(
                  <div className='flex border-t'>
                    <h6 className='w-[10%] text-[10px] border-r px-[5px] py-[4px]'>{moment(l?.date)?.format('LL')}</h6>
                    <h6 className='w-[10%] text-[10px] border-r px-[5px] py-[4px]'>{l?.particulars}</h6>
                    <h6 className='w-[10%] text-[10px] border-r px-[5px] py-[4px]'>{Priceconstants(parseInt(l?.receipt))}</h6>
                    <h6 className='w-[10%] text-[10px] border-r px-[5px] py-[4px]'>{Priceconstants(parseInt(l?.payment))}</h6>
                    <h6 className='w-[25%] text-[10px] border-r px-[5px] py-[4px]'>{Priceconstants(parseInt(l?.closing_available_balance))}</h6>
                    <h6 className='w-[25%] text-[10px] border-r px-[5px] py-[4px]'>{l?.remarks}</h6>
                    <h6 className='flex font-[800] w-[10%] text-[10px] px-[5px] py-[4px]'>
                      <AiOutlineEdit size={12} className='mr-2' onClick={()=>{setshilpa_foundation_receipt_payment({...l,date:l?.date === undefined ? '' : l?.date?.slice(0,10),index:i});setshilpafoundationPaymentReceiptModal(true)}} />
                      <AiOutlineDelete  size={12} onClick={()=>{setdeleteData({type:'shilpa_foundation_receipt_payment',index:i,data:l});setdeleteModal(true)}} />
                    </h6>
                  </div>))}
                  </div>
                  
                </div> }
                </div>
               

                </div>
              </div>} 
              

              {step === 3 &&
              <div className='border-t w-[90%]'>

                <TextAreaInput1 
                      mandatory={false}
                      label={'Total Number of In House Headcount '}  
                      variant="standard"
                      name="remark"
                      type="text"
                      value={questionaries?.total_no_of_inhouse_headcount}
                      handlechange={(e)=>setquestionaries({...questionaries,total_no_of_inhouse_headcount:e.target.value})}
                      placeholder=""
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>

                <TextAreaInput1 
                      mandatory={false}
                      label={'Total Number of external/contractors Headcount(if applicable) '}  
                      variant="standard"
                      name="remark"
                      type="text"
                      value={questionaries?.total_no_of_external_contractor_if_applicable}
                      handlechange={(e)=>setquestionaries({...questionaries,total_no_of_external_contractor_if_applicable:e.target.value})}
                      placeholder=""
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>

                <div className='grid grid-cols-3'>

                  <div className='mt-2 border-r pr-4'>

                    <h6 className='text-[11px] bg-slate-100 p-1 font-[700]'>Risk Management and Compliance:</h6>

                    <TextAreaInput1 
                      mandatory={false}
                      label={'Any Risks identified '}  
                      variant="standard"
                      name="remark"
                      type="text"
                      value={questionaries?.risk_managent_compliance?.any_risk_identified}
                      handlechange={(e)=>handlechangequestionaries('questionaries','risk_managent_compliance','any_risk_identified',e)}
                      placeholder=""
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>

                    <TextAreaInput1 
                      mandatory={false}
                      label={'Any Mitigation plans in place '}  
                      variant="standard"
                      name="remark"
                      type="text"
                      value={questionaries?.risk_managent_compliance?.any_mitigation_plans_in_place}
                      handlechange={(e)=>handlechangequestionaries('questionaries','risk_managent_compliance','any_mitigation_plans_in_place',e)}
                      placeholder=""
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>

                    <TextAreaInput1 
                      mandatory={false}
                      label={'Compliance:Is it up to date '}  
                      variant="standard"
                      name="remark"
                      type="text"
                      value={questionaries?.risk_managent_compliance?.compliance_is_upto_date}
                      handlechange={(e)=>handlechangequestionaries('questionaries','risk_managent_compliance','compliance_is_upto_date',e)}
                      placeholder=""
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}/>

                  </div>

                  <div className='mt-2 border-r pl-4 pr-4'>

                  <h6 className='text-[11px] bg-slate-100 p-1 font-[700]'>Employee Engagment and Development:</h6>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Total Number of people hired in last quarter '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.employee_engagment_and_development?.total_no_of_people_hired_in_last_quarter}
                    handlechange={(e)=>handlechangequestionaries('questionaries','employee_engagment_and_development','total_no_of_people_hired_in_last_quarter',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Total number of people exited in Quarter '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.employee_engagment_and_development?.total_no_of_people_exited_in_last_quarter}
                    handlechange={(e)=>handlechangequestionaries('questionaries','employee_engagment_and_development','total_no_of_people_exited_in_last_quarter',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>


                  <TextAreaInput1 
                    mandatory={false}
                    label={'List down primary reasons for exits '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.employee_engagment_and_development?.list_down_primary_reason_for_exits}
                    handlechange={(e)=>handlechangequestionaries('questionaries','employee_engagment_and_development','list_down_primary_reason_for_exits',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'What plans are in place to reduce attrition '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.employee_engagment_and_development?.which_plans_are_in_place_to_reduce_attrition}
                    handlechange={(e)=>handlechangequestionaries('questionaries','employee_engagment_and_development','which_plans_are_in_place_to_reduce_attrition',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>


                  <TextAreaInput1 
                    mandatory={false}
                    label={'Are there any employee development and engagement programs?  '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.employee_engagment_and_development?.are_there_any_employee_development_and_engagement_programs}
                    handlechange={(e)=>handlechangequestionaries('questionaries','employee_engagment_and_development','are_there_any_employee_development_and_engagement_programs',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>  

                

                  </div>

                  <div className='mt-2 border-r pl-4 pr-4'>

                  <h6 className='text-[11px] bg-slate-100 p-1 font-[700]'>Customer and Stakeholder Satisfaction:</h6>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'What is your customer satisfaction rate '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.customer_and_stakeholder_satisfaction?.what_is_your_customer_satisfaction_rate}
                    handlechange={(e)=>handlechangequestionaries('questionaries','customer_and_stakeholder_satisfaction','what_is_your_customer_satisfaction_rate',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Any notable customer feedback received recently? '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.customer_and_stakeholder_satisfaction?.any_notable_customer_feedback_recieved_recently}
                    handlechange={(e)=>handlechangequestionaries('questionaries','customer_and_stakeholder_satisfaction','any_notable_customer_feedback_recieved_recently',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>


                  <TextAreaInput1 
                    mandatory={false}
                    label={'How would you rate your stakeholder relationship health? '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.customer_and_stakeholder_satisfaction?.how_would_you_rate_your_stakeholder_relationship_health}
                    handlechange={(e)=>handlechangequestionaries('questionaries','customer_and_stakeholder_satisfaction','how_would_you_rate_your_stakeholder_relationship_health',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Any customers lost due to lack of engagment '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.customer_and_stakeholder_satisfaction?.any_customer_lost_due_to_lack_of_engagement}
                    handlechange={(e)=>handlechangequestionaries('questionaries','customer_and_stakeholder_satisfaction','any_customer_lost_due_to_lack_of_engagement',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>


                 

                  </div>

                  <div className='mt-2 border-r pl-4 pr-4'>

                  <h6 className='text-[11px] bg-slate-100 p-1 font-[700]'>Planning and outlook: </h6>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'How is the market outlook for Quarter for you? '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.planning_and_outlook?.how_is_market_outlook_for_quarter}
                    handlechange={(e)=>handlechangequestionaries('questionaries','planning_and_outlook','how_is_market_outlook_for_quarter',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Any expansion plan into new markets '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.planning_and_outlook?.any_expansion_plan_into_new_market}
                    handlechange={(e)=>handlechangequestionaries('questionaries','planning_and_outlook','any_expansion_plan_into_new_market',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>


                  <TextAreaInput1 
                    mandatory={false}
                    label={'Any additional headcount hiring planned: list them '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.planning_and_outlook?.any_additional_headcount_hiring_planned}
                    handlechange={(e)=>handlechangequestionaries('questionaries','planning_and_outlook','any_additional_headcount_hiring_planned',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>




                  </div>

                  <div className='mt-2 border-r pl-4 pr-4'>

                  <h6 className='text-[11px] bg-slate-100 p-1 font-[700]'>Innovation and Growth: </h6>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Has your BU adopted technology? mention an example '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.innovation_and_growth?.has_your_bu_adapted_technology}
                    handlechange={(e)=>handlechangequestionaries('questionaries','innovation_and_growth','has_your_bu_adapted_technology',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Any new systems or process implemented '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.innovation_and_growth?.any_new_system_or_process_implemented}
                    handlechange={(e)=>handlechangequestionaries('questionaries','innovation_and_growth','any_new_system_or_process_implemented',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>


                  <TextAreaInput1 
                    mandatory={false}
                    label={'Any new systems or process planned for next quarter  '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.innovation_and_growth?.any_new_system_or_process_planned_for_next_quarter}
                    handlechange={(e)=>handlechangequestionaries('questionaries','innovation_and_growth','any_new_system_or_process_planned_for_next_quarter',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>




                  </div>

                  <div className='mt-2 border-r pl-4 pr-4'>

                  <h6 className='text-[11px] bg-slate-100 p-1 font-[700]'>Financial Health: </h6>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Avg total Gross Salaries paid in last quarter'}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.financial_health?.avg_gross_salary_paid_in_last_quarter}
                    handlechange={(e)=>handlechangequestionaries('questionaries','financial_health','avg_gross_salary_paid_in_last_quarter',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Avg Monthly rent paid in last quarter'}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.financial_health?.avg_monthly_rent_paid_in_last_quarter}
                    handlechange={(e)=>handlechangequestionaries('questionaries','financial_health','avg_monthly_rent_paid_in_last_quarter',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Avg  Operational cost (Consumables, Cabs etc) in Quarter'}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.financial_health?.avg_operation_cost}
                    handlechange={(e)=>handlechangequestionaries('questionaries','financial_health','avg_operation_cost',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>


                  <TextAreaInput1 
                    mandatory={false}
                    label={'Avg cost of vendor/services (payroll, legal, CA etc) in last quarter'}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.financial_health?.avg_cost_for_vendor_service_quarter}
                    handlechange={(e)=>handlechangequestionaries('questionaries','financial_health','avg_cost_for_vendor_service_quarter',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Avg Employee engagement expenses (team lunch etc) in last quarter'}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.financial_health?.avg_employee_engagement_expenses_quarter}
                    handlechange={(e)=>handlechangequestionaries('questionaries','financial_health','avg_employee_engagement_expenses_quarter',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Avg Fuel/Food or any other allowances paid in last quarters'}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.financial_health?.avg_fue_food_or_any_allowence_paid_quarter}
                    handlechange={(e)=>handlechangequestionaries('questionaries','financial_health','avg_fue_food_or_any_allowence_paid_quarter',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Total Incentive paid in last quarter'}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.financial_health?.total_incentive_paid_in_last_quarter}
                    handlechange={(e)=>handlechangequestionaries('questionaries','financial_health','total_incentive_paid_in_last_quarter',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>


                  <TextAreaInput1 
                    mandatory={false}
                    label={'Total statutory payment paid last quarter(TDS, GST etc)'}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.financial_health?.total_statutory_payment_paid_in_last_quarter}
                    handlechange={(e)=>handlechangequestionaries('questionaries','financial_health','total_statutory_payment_paid_in_last_quarter',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Total Quarterly Target set for entire BU '}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.financial_health?.total_quarterly_target_set_for_entire_bu}
                    handlechange={(e)=>handlechangequestionaries('questionaries','financial_health','total_quarterly_target_set_for_entire_bu',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Total Invoices raised till last quarter individully'}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.financial_health?.total_invoice_raised}
                    handlechange={(e)=>handlechangequestionaries('questionaries','financial_health','total_invoice_raised',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>    

                  <TextAreaInput1 
                    mandatory={false}
                    label={'Profit Variance (Total revenue generated - total expense paid)'}  
                    variant="standard"
                    name="remark"
                    type="text"
                    value={questionaries?.financial_health?.profit_variance}
                    handlechange={(e)=>handlechangequestionaries('questionaries','financial_health','profit_variance',e)}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                  }}/>
          


                  


                  </div>
                                    
                
               

                </div>
              </div>}  
              </>}

             </div> 
             </div>

          


          <div className='flex items-center justify-end mt-4 mb-10 border-t pt-2'>
              <ButtonOutlinedAutoWidth btnName="Cancel"  onClick={()=>navigate(-1)}/>
              <h6 className='w-[5px]'></h6>
              <ButtonFilledAutoWidth btnName="Save" onClick={submitData} />
          </div>
      </div>
    </div>
  )
}

export default ProfitLossListCE