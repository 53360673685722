import React,{useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import GoBack from '../../../components/back/GoBack';
import { Modal, Select } from 'antd';
import { TextAreaInput1, TextInput } from '../../../components/input';
import Uploader from '../../../components/Uploader';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import { CreateConveyanceVochuerService, UpdateConveyanceVochuerService, UploadConveyanceVochuerService } from '../../../services/FinanceFormServices/ConveyanceVochuerServices';
import { toast } from 'react-hot-toast'
import FinanceMenu from '../FinanceMenu';
import { BiCheckbox,BiCheckboxSquare } from 'react-icons/bi'
import ProfileMenu from '../../profile/ProfileMenu';
import { GetSearchService } from '../../../services/AuthServices';
import { useSelector } from 'react-redux';
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';
import { GetDepartmentService } from '../../../services/DepartmentServices';
import ErrorComponent from '../../../components/errorDesign/ErrorComponent';
import { CreateRevenueForecastService, UpdateRevenueForecastService, UploadRevenueForecastService } from '../../../services/FinanceFormServices/RevenueForecastServices';


function RevenueForecastCE() {
  const {state,pathname} = useLocation();
  const navigate = useNavigate();

  const roles = useSelector(state=>state.Auth.roles)

  const [step,setstep] = useState('')

  
  const path = pathname.split('/')[1]

  const [data,setdata] = useState({amount_collected:'',today_expenses:'',opening_balance:'',closing_balance:'',invoice_raised_info:[],new_leads_list:[],expenses_bifuration:[],department:'',bank_statement_pdf:''});  
  const [error,seterror] = useState({amount_collected:'',today_expenses:'',opening_balance:'',closing_balance:'',invoice_raised_info:'',new_leads_list:'',expenses_bifuration:'',department:'',bank_statement_pdf:''}); 
  const [user,setuser] = useState([])


  const [invoice_info,setinvoice_info] = useState({client_name:'',amount:'',requirment:'',remarks:''})
  const [lead_info,setlead_info] = useState({client_name:'',client_no:'',requirment:''})
  const [expenses_info,setexpenses_info] = useState({reason:'',amount:'',remarks:''})
  
  const [invoice_info_err,setinvoice_info_err] = useState({client_name:'',amount:'',requirment:'',remarks:''})
  const [lead_info_err,setlead_info_err] = useState({client_name:'',client_no:'',requirment:''})
  const [expenses_info_err,setexpenses_info_err] = useState({reason:'',amount:'',remarks:''})
  

  const [invoices,setinvoices] = useState([])
  const [leads,setleads] = useState([])
  const [expenses,setexpenses] = useState([])

  const [departments,setdepartments] = useState([])
  

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  function handlechange1(e){
    setexpenses_info({...expenses_info,[e.target.name]: e.target.value})
    setexpenses_info_err({...expenses_info_err,[e.target.name]: ''})
  }

  function handlechange2(e){
    setlead_info({...lead_info,[e.target.name]: e.target.value})
    setlead_info_err({...lead_info_err,[e.target.name]: ''})
  }

  function handlechange3(e){
    setinvoice_info({...invoice_info,[e.target.name]: e.target.value})
    setinvoice_info_err({...invoice_info_err,[e.target.name]: ''})
  }



  useEffect(()=>{
    if(state?._id !== undefined){

        let invoices_arr = []
        let leads_arr = []
        let expenses_arr = []

        let d = state 
        delete d.created_by 

        d?.invoice_raised_info?.map((d,i)=>{
            invoices_arr?.push({...d,id:i+1})
        })

        d?.new_leads_list?.map((d,i)=>{
            leads_arr?.push({...d,id:i+1})
        })

        d?.expenses_bifuration?.map((d,i)=>{
            expenses_arr?.push({...d,id:i+1})
        })

        setdata({
            ...data,
            ...state,
            department:{label:state?.department?.department_name,value:state?.department?._id},
            bill_date:state?.bill_date?.slice(0,10),
        })

        setleads(leads_arr)
        setexpenses(expenses_arr)
        setinvoices(invoices_arr)
    }
    getdepartment()

  },[state])

  async function getdepartment(){
    const response = await GetDepartmentService();
    let arr = []
    
    response.data.datas.forEach((d)=>{
    arr.push({label:d?.department_name,value:d?.id})
    })

    setdepartments(arr)
  }

  async function uploadfile(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadRevenueForecastService(fd)
    if(response?.status === 200){
      setdata({...data,[name]:response?.data?.data})
      seterror({...error,[name]:''})
    }
  }

  async function submitform(){
    if(!data.amount_collected){
        seterror({...error,amount_collected:'This field is required *'})
    }else if(!data.today_expenses){
      seterror({...error,today_expenses:'This field is required *'})
    }else if(!data.opening_balance){
      seterror({...error,opening_balance:'This field is required *'})
    }else if(!data.closing_balance){
        seterror({...error,closing_balance:'This field is required *'})
    }else if(!data.department){
        seterror({...error,department:'This field is required *'})
    }else{
        let sendData = {...data}
        sendData['department'] = data?.department?.value
        sendData['invoice_raised_info'] = invoices
        sendData['new_leads_list'] = leads
        sendData['expenses_bifuration'] = expenses

        delete sendData?.created_by
       
        if(state?._id === undefined || state?._id === null){
            const response = await CreateRevenueForecastService(sendData)
            if (response.status === 201) {
            resetform()
            toast.success('Revenue Forecast Created Successfully')
            }   
           
      }else{
        sendData["id"] = state?._id
        const response = await UpdateRevenueForecastService(sendData,state?._id)
        if (response.status === 200) {
          resetform()
          navigate(-1)
          toast.success('Revenue Forecast Update Successfully')
        }   
      }
    }
  }

  function resetform(){
    setdata({amount_collected:'',today_expenses:'',opening_balance:'',closing_balance:'',invoice_raised_info:[],new_leads_list:[],expenses_bifuration:[],department:'',bank_statement_pdf:''})
    seterror({amount_collected:'',today_expenses:'',opening_balance:'',closing_balance:'',invoice_raised_info:'',new_leads_list:'',expenses_bifuration:'',department:'',bank_statement_pdf:''})
    setleads([])
    setexpenses([])
    setinvoices([])
    setlead_info({client_name:'',client_no:'',requirment:''})
    setlead_info_err({client_name:'',client_no:'',requirment:''})
    setexpenses_info({client_name:'',client_no:'',requirment:''})
    setexpenses_info_err({client_name:'',client_no:'',requirment:''})
    setinvoice_info({client_name:'',client_no:'',requirment:''})
    setinvoice_info_err({client_name:'',client_no:'',requirment:''})
  }

  function submitArray(){

    if(step == 2){
        if(!lead_info?.client_name){
            setlead_info_err({...lead_info_err,client_name:"This Field is required*"})
        }else if(!lead_info?.client_no){
            setlead_info_err({...lead_info_err,client_no:"This Field is required*"})
        }else if(!lead_info?.requirment){
            setlead_info_err({...lead_info_err,requirment:"This Field is required*"})
        }else{
            if(lead_info?.id === undefined){
                if(leads?.length === 0){
                    setleads([{...lead_info,id:1}])
                    setstep('')
                    setlead_info({client_name:'',client_no:'',requirment:''})
                }else{
                    let oldData = [...leads]
                    let lastData = oldData.at(-1);
                    setleads([...leads,{...lead_info,id:lastData.id+1}])
                    setstep('')
                    setlead_info({client_name:'',client_no:'',requirment:''})
                }
            }else{
                let oldData = [...leads]
                let findIndex = oldData?.find((f)=>f?.id === lead_info?.id)
                oldData?.splice(findIndex,1,lead_info)
                setleads(oldData)
                setstep('')
                setlead_info({client_name:'',client_no:'',requirment:''})
            }
        }
    }

    if(step == 3){
        if(!expenses_info?.reason){
            setexpenses_info_err({...expenses_info_err,reason:"This Field is required*"})
        }else if(!expenses_info?.amount){
            setexpenses_info_err({...expenses_info_err,amount:"This Field is required*"})
        }else{
            if(expenses_info?.id === undefined){
                if(expenses?.length === 0){
                    setexpenses([{...expenses_info,id:1}])
                    setstep('')
                    setexpenses_info({reason:'',amount:'',remarks:''})
                }else{
                    let oldData = [...leads]
                    let lastData = oldData.at(-1);
                    setexpenses([...expenses,{...expenses_info,id:lastData.id+1}])
                    setstep('')
                    setexpenses_info({reason:'',amount:'',remarks:''})
                }
            }else{
                let oldData = [...expenses]
                let findIndex = oldData?.find((f)=>f?.id === lead_info?.id)
                oldData?.splice(findIndex,1,lead_info)
                setexpenses(oldData)
                setstep('')
                setexpenses_info({reason:'',amount:'',remarks:''})
            }
        }
    }

    if(step == 1){
        if(!invoice_info?.client_name){
            setinvoice_info_err({...invoice_info_err,client_name:"This Field is required*"})
        }else if(!invoice_info?.amount){
            setinvoice_info_err({...invoice_info_err,amount:"This Field is required*"})
        }else if(!invoice_info?.requirment){
            setinvoice_info_err({...invoice_info_err,requirment:"This Field is required*"})
        }else{
            if(invoice_info?.id === undefined){
                if(invoices?.length === 0){
                    setinvoices([{...invoice_info,id:1}])
                    setstep('')
                    setinvoice_info({client_name:'',amount:'',requirment:'',remarks:''})
                }else{
                    let oldData = [...invoices]
                    let lastData = oldData.at(-1);
                    setinvoices([...invoices,{...invoice_info,id:lastData.id+1}])
                    setstep('')
                    setinvoice_info({client_name:'',amount:'',requirment:'',remarks:''})
                }
            }else{
                let oldData = [...invoices]
                let findIndex = oldData?.find((f)=>f?.id === invoice_info?.id)
                oldData?.splice(findIndex,1,invoice_info)
                setinvoices(oldData)
                setstep('')
                setinvoice_info({client_name:'',amount:'',requirment:'',remarks:''})
            }
        }
    }

  }

  function removeData(l,type){
    if(type === 'lead'){
      let list = leads?.filter((f)=>f?.id !== l?.id)
      setleads(list)
    }else if(type === 'expenses'){
        let list = expenses?.filter((f)=>f?.id !== l?.id)
        setexpenses(list)
    }else if(type === 'invoice'){
        let list = invoices?.filter((f)=>f?.id !== l?.id)
        setinvoices(list)
    }
  }

  return (
    <div className='flex '>
        {/* {pathname !== '/conveyance_vochuer_create' &&
        <FinanceMenu />} */}
        
        <Modal open={step !== ''} closable={false} footer={false} width={300}>
            <div className=' bg-white'>
                 
                {step === 1 &&
                <>

                <h6 className='font-[700] text-[13px]'>Create or Edit Invoice Raised Info</h6>
                <h6 className='text-[12px] bg-slate-100 p-2 leading-[14px] text-gray-600'>Use the below form to create or update the invoice raised info</h6>
                

                <TextAreaInput1
                    label={'Client Name'}  
                    variant="standard"
                    name="client_name"
                    type="text"
                    mandatory={true}
                    error={invoice_info_err?.client_name}
                    value={invoice_info?.client_name}
                    handlechange={handlechange3}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}
                />

                <TextInput
                    label={'Amount'}  
                    variant="standard"
                    name="amount"
                    type="number"
                    mandatory={true}
                    error={invoice_info_err?.amount}
                    value={invoice_info?.amount}
                    handlechange={handlechange3}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}
                />

                <TextAreaInput1
                    label={'Requirment'}  
                    variant="standard"
                    name="requirment"
                    type="text"
                    mandatory={true}
                    error={invoice_info_err?.requirment}
                    value={invoice_info?.requirment}
                    handlechange={handlechange3}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}
                />
                </>}

                {step === 2 &&
                <>

                <h6 className='font-[700] text-[13px]'>Create or Edit New Leads List</h6>
                <h6 className='text-[12px] bg-slate-100 p-2 leading-[14px] text-gray-600'>Use the below form to create or update the new lead list info</h6>
                

                <TextAreaInput1
                    label={'Client Name'}  
                    variant="standard"
                    name="client_name"
                    type="text"
                    mandatory={true}
                    error={lead_info_err?.client_name}
                    value={lead_info?.client_name}
                    handlechange={handlechange2}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}
                />

                <TextInput
                    label={'Client No'}  
                    variant="standard"
                    name="client_no"
                    type="text"
                    mandatory={true}
                    error={lead_info_err?.client_no}
                    value={lead_info?.client_no}
                    handlechange={handlechange2}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}
                />

                <TextAreaInput1
                    label={'Requirment'}  
                    variant="standard"
                    name="requirment"
                    type="text"
                    mandatory={true}
                    error={lead_info_err?.requirment}
                    value={lead_info?.requirment}
                    handlechange={handlechange2}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}
                />
                </>}
                
                {step === 3 &&
                <>

                <h6 className='font-[700] text-[13px]'>Create or Edit Expenses Bifurcation</h6>
                <h6 className='text-[12px] bg-slate-100 p-2 leading-[14px] text-gray-600'>Use the below form to create or update the expenses bifurcation info</h6>
                

                <TextAreaInput1
                    label={'Reason'}  
                    variant="standard"
                    name="reason"
                    type="text"
                    mandatory={true}
                    error={expenses_info_err?.reason}
                    value={expenses_info?.reason}
                    handlechange={handlechange1}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}
                />

                <TextInput
                    label={'Amount'}  
                    variant="standard"
                    name="amount"
                    type="number"
                    mandatory={true}
                    error={expenses_info_err?.amount}
                    value={expenses_info?.amount}
                    handlechange={handlechange1}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}
                />

                <TextAreaInput1
                    label={'Remarks'}  
                    variant="standard"
                    name="remarks"
                    type="text"
                    mandatory={true}
                    error={expenses_info_err?.remarks}
                    value={expenses_info?.remarks}
                    handlechange={handlechange1}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}
                />
                </>} 

                


                <div className='border-t flex items-center justify-end pt-2 mt-2'>
                    <ButtonOutlinedAutoWidth onClick={()=>setstep('')} btnName="Cancel" />
                    <div className='w-[20px]'></div>
                    <ButtonFilledAutoWidth onClick={()=>submitArray()} btnName="Save" />
                </div>

            </div>
        </Modal>

         <div className={`min-w-44 ${path == 'profile' && 'min-w-[180px] pl-2'}`}>
              {path == 'profile' && <ProfileMenu />}
              {path == 'finance' && <FinanceMenu />}
            </div>

        <div>
        <div className={`w-full md:w-72 pb-10 lg:w-72 px-4 pt-5 ${pathname === '/conveyance_vochuer_create' && 'mx-auto'} `} >

           {pathname !== '/conveyance_vochuer_create' &&
            <GoBack /> }
            
            

            <h6 className='font-bold'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} Revenue Forecast</h6>
            <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Revenue Forecast</b> for your reference.</h6>
        



         


            <TextInput 
                label={'Amount Collected'}  
                variant="standard"
                name="amount_collected"
                type="text"
                mandatory={true}
                error={error.amount_collected}
                value={data.amount_collected}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            {/* <TextInput 
                label={'Added By'}  
                variant="standard"
                name="created_by"
                type="text"
                mandatory={true}
                error={error.created_by}
                value={data.created_by}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> */}

            <TextInput 
                label={'Today Expenses'}  
                variant="standard"
                name="today_expenses"
                mandatory={true}
                type="text"
                error={error.today_expenses}
                value={data.today_expenses}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                label={'Opening Balance'}  
                variant="standard"
                mandatory={true}
                name="opening_balance"
                type="text"
                error={error.opening_balance}
                value={data.opening_balance}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                label={'Closing Balance'}  
                variant="standard"
                mandatory={true}
                name="closing_balance"
                type="text"
                error={error.closing_balance}
                value={data.closing_balance}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            {/* <TextInput 
                label={'Invoice Raised Info'}  
                variant="standard"
                name="invoice_raised_info"
                type="text"
                error={error.invoice_raised_info}
                value={data.invoice_raised_info}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> */}

            

            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Bank Statement PDF</h6>
            <Uploader image={data?.bank_statement_pdf}  setimagefunc={(e)=>{uploadfile(e,'bank_statement_pdf')}}  removeimageuploadfunc = {()=>setdata({...data,bank_statement_pdf:''})}/>

            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Department</h6>

        <div className='border border-slate-300 rounded-[0px] border-l-4 border-l-slate-700 mr-[4px] py-[2px]'>
            <Select
                placeholder='Select Department'
                bordered={false}
                size='small'
                defaultValue={data?.department}
                value={data?.department}
                style={{ width: '100%' }}
                onChange={(v)=>setdata({...data,department:departments?.find((f)=>f?.value === v)})}
                options={departments}
            />
        </div>

        <ErrorComponent error={error?.department} />

        <div className='px-0'>

        <div className='w-[500px]'>
                <div className='text-[12px] flex items-center justify-between  mt-2'>
                <h6 className='font-[600]'>Invoices Info</h6>
                <AiOutlinePlus onClick={()=>setstep(1)} className='bg-slate-200 p-[2px] rounded-[2px]' />
                </div>

                <div className='border mt-2'>
                <div className='flex text-[11px] bg-slate-100 font-[600] flex items-center'>
                    <h6 className='border-r border-gray-300 p-1 min-w-[20%] max-w-[20%]'>Client Name</h6>
                    <h6 className='border-r border-gray-300 p-1 min-w-[15%] max-w-[15%]'>Amount</h6>
                    <h6 className='border-r border-gray-300 p-1 min-w-[30%] max-w-[30%]'>Requirment</h6>
                    <h6 className='border-r border-gray-300 p-1 min-w-[25%] max-w-[25%]'>Reamrks</h6>
                    <h6 className='p-1 min-w-[10%] max-w-[10%]'>Action</h6>
                </div>

                {invoices?.map((l,i)=>(
                <div key={i} className='flex text-[11px] border-t  flex '>
                    <h6 className='border-r border-gray-300 p-1 min-w-[20%] max-w-[20%]'>{l?.client_name}</h6>
                    <h6 className='border-r border-gray-300 p-1 min-w-[15%] max-w-[15%]'>{l?.amount}</h6>
                    <h6 className='border-r border-gray-300 p-1 min-w-[30%] max-w-[30%]'>{l?.requirment}</h6>
                    <h6 className='border-r border-gray-300 p-1 min-w-[25%] max-w-[25%]'>{l?.remarks}</h6>
                    <h6 className='p-1 min-w-[10%] flex items-center max-w-[10%]'>
                        <AiOutlineEdit className='mr-2' onClick={()=>{setinvoice_info(l);setstep(1)}} />
                        <AiOutlineDelete onClick={()=>removeData(l,'expense')} />
                    </h6>
                </div>
                ))}
                </div>
            </div>
            
            <div className='w-[500px]'>
                <div className='text-[12px] flex items-center justify-between  mt-2'>
                <h6 className='font-[600]'>New Lead List</h6>
                <AiOutlinePlus onClick={()=>setstep(2)} className='bg-slate-200 p-[2px] rounded-[2px]' />
                </div>

                <div className='border mt-2'>
                <div className='flex text-[11px] bg-slate-100 font-[600] flex items-center'>
                    <h6 className='border-r border-gray-300 p-1 min-w-[40%] max-w-[40%]'>Client Info</h6>
                    <h6 className='border-r border-gray-300 p-1 min-w-[50%] max-w-[50%]'>Requirment</h6>
                    <h6 className='p-1 min-w-[10%] max-w-[10%]'>Action</h6>
                </div>

                {leads?.map((l,i)=>(
                <div key={i} className='flex text-[11px] border-t  flex '>
                    <h6 className='border-r border-gray-300 p-1 min-w-[40%] max-w-[40%]'>{l?.client_name} / {l?.client_no}</h6>
                    <h6 className='border-r border-gray-300 p-1 min-w-[50%] max-w-[50%]'>{l?.requirment}</h6>
                    <h6 className='p-1 min-w-[10%] flex items-center max-w-[10%]'>
                        <AiOutlineEdit className='mr-2' onClick={()=>{setlead_info(l);setstep(2)}} />
                        <AiOutlineDelete onClick={()=>removeData(l,'lead')} />
                    </h6>
                </div>
                ))}
                </div>
            </div>

            <div className='w-[500px]'>
                <div className='text-[12px] flex items-center justify-between  mt-2'>
                <h6 className='font-[600]'>Expenses Bifurcation</h6>
                <AiOutlinePlus onClick={()=>setstep(3)} className='bg-slate-200 p-[2px] rounded-[2px]' />
                </div>

                <div className='border mt-2'>
                <div className='flex text-[11px] bg-slate-100 font-[600] flex items-center'>
                    <h6 className='border-r border-gray-300 p-1 min-w-[40%] max-w-[40%]'>Reason</h6>
                    <h6 className='border-r border-gray-300 p-1 min-w-[20%] max-w-[20%]'>Amount</h6>
                    <h6 className='border-r border-gray-300 p-1 min-w-[30%] max-w-[30%]'>Remarks</h6>
                    <h6 className='p-1 min-w-[10%] max-w-[10%]'>Action</h6>
                </div>

                {expenses?.map((l,i)=>(
                <div key={i} className='flex text-[11px] border-t  flex '>
                    <h6 className='border-r border-gray-300 p-1 min-w-[40%] max-w-[40%]'>{l?.reason}</h6>
                    <h6 className='border-r border-gray-300 p-1 min-w-[20%] max-w-[20%]'>{l?.amount}</h6>
                    <h6 className='border-r border-gray-300 p-1 min-w-[30%] max-w-[30%]'>{l?.remarks}</h6>
                    <h6 className='p-1 min-w-[10%] flex items-center max-w-[10%]'>
                        <AiOutlineEdit className='mr-2' onClick={()=>{setexpenses_info(l);setstep(3)}} />
                        <AiOutlineDelete onClick={()=>removeData(l,'expense')} />
                    </h6>
                </div>
                ))}
                </div>
            </div>

            <div className='mt-5'>
            <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? "UPDATE REVENUE FORECAST" : "ADD REVENUE FORECAST"}  onClick={submitform} />  
            </div>
        </div>
        </div>
    </div>
   </div> 
  )
}

export default RevenueForecastCE