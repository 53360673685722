import React, { useState,useEffect } from 'react'
import { GetStageService } from '../../../services/StageServices';
import {  ButtonFilledAutoWidth } from '../../../components/button';
import {  GetLeadOptionsService } from '../../../services/LeadOptionServices';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import {AiOutlineEdit,AiOutlineContacts} from 'react-icons/ai';  
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton, Tooltip } from '@material-ui/core';

function DailyTask() {

  const {pathname} = useLocation()
  const navigator = useNavigate()
  const path = pathname.split('/')[pathname.split('/').length - 1]

  const [stages,setstages] = useState([])
  const [page,setpage] = useState(1)

  const [singleData,setsingleData] = useState({followup_owner:'',department_id:'',contact_name:'',phone:'',email:'',designation:'',company_name:'',lead_source:'',industry:'',stage:'',website:'',requirment:'',address:'',message:'',important:''})
  const [error,seterror] = useState({followup_owner:'',department_id:'',contact_name:'',phone:'',email:'',designation:'',company_name:'',lead_source:'',industry:'',stage:'',website:'',requirment:'',address:'',message:'',important:''})
  const [data,setdata] = useState([])


  useEffect(()=>{
    getfollowupStages()
  },[])
  


  useEffect(()=>{
      getdata(page)
  },[path])

  useEffect(()=>{
      getdata(page)
  },[page,path])
  
  const getfollowupStages = async() => {
    const response = await GetStageService('FollowUp_Options')
    setstages(response.data.data.datas)
    getdata(response.data.data.datas,1)
  }

   async function getdata(){

     if(path === 'calls'){
        const response = await GetLeadOptionsService({id:'null',stage:'Calls',page:page})
        setdata(response.data)
     }else if(path === 'daily_tasks'){
        const response = await GetLeadOptionsService({id:'null',stage:'Daily Tasks',page:page})
        setdata(response.data)
     }else if(path === 'walk_ins'){
        const response = await GetLeadOptionsService({id:'null',stage:'Walk Ins',page:page})
        setdata(response.data)
     }else if(path === 'property_visit'){
        const response = await GetLeadOptionsService({id:'null',stage:'Property Visit',page:page})
        setdata(response.data)
     }else if(path === 'meeting'){
        const response = await GetLeadOptionsService({id:'null',stage:'Meetings',page:page})
        setdata(response.data)
     }else if(path === 'scouting'){
        const response = await GetLeadOptionsService({id:'null',stage:'Scouting',page:page})
        setdata(response.data)
     }
   }

  const gotocreate = () => {
    if(path === 'calls'){
        navigator('create',{state:{type:path,stage:stages?.find(s=>s?.name === 'Calls'),allStage:stages}})
    }else if(path === 'daily_tasks'){
        navigator('create',{state:{type:path,stage:stages?.find(s=>s?.name === 'Daily Tasks'),allStage:stages}})
    }else if(path === 'walk_ins'){
        navigator('create',{state:{type:path,stage:stages?.find(s=>s?.name === 'Walk Ins'),allStage:stages}})
    }else if(path === 'property_visit'){
        navigator('create',{state:{type:path,stage:stages?.find(s=>s?.name === 'Property Visit'),allStage:stages}})
    }else if(path === 'meeting'){
        navigator('create',{state:{type:path,stage:stages?.find(s=>s?.name === 'Meetings'),allStage:stages}})
    }else if(path === 'scouting'){
        navigator('create',{state:{type:path,stage:stages?.find(s=>s?.name === 'Scouting'),allStage:stages}})
    }
  }

  const gotoedit = (d) => {
    if(path === 'calls'){
        navigator('edit',{state:{type:path,stage:stages?.find(s=>s?.name === 'Calls'),data:d,allStage:stages}})
    }else if(path === 'daily_tasks'){
        navigator('edit',{state:{type:path,stage:stages?.find(s=>s?.name === 'Daily Tasks'),data:d,allStage:stages}})
    }else if(path === 'walk_ins'){
        navigator('edit',{state:{type:path,stage:stages?.find(s=>s?.name === 'Walk Ins'),data:d,allStage:stages}})
    }else if(path === 'property_visit'){
        navigator('edit',{state:{type:path,stage:stages?.find(s=>s?.name === 'Property Visit'),data:d,allStage:stages}})
    }else if(path === 'meeting'){
        navigator('edit',{state:{type:path,stage:stages?.find(s=>s?.name === 'Meetings'),data:d,allStage:stages}})
    }else if(path === 'scouting'){
        navigator('edit',{state:{type:path,stage:stages?.find(s=>s?.name === 'Scouting'),data:d,allStage:stages}})
    }
  }




  return (
    <div className='w-[98%]'>
       
        <div>
            <div className='flex items-center -mt-1 border-b justify-between pb-1'>
                <h6 className='font-[800] text-[13px]'>Total {path === 'calls' && 'Calls'} {path === 'daily_tasks' && 'Daily Tasks'} {path === 'walk_ins' && 'Walk Ins'} {path === 'property_visit' && 'Property Visit'} {path === 'meeting' && 'Meetings'} {path === 'scouting' && 'Scouting'}  ({data?.pagination?.total})</h6>

                <div className='flex items-center text-[12px]'>

                    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${data?.pagination?.totalPages === page ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                    <ButtonFilledAutoWidth btnName="Add New" onClick={()=>gotocreate()} />

                </div>
            </div>
            <div className='overflow-x-hidden h-[85vh] mb-[50px] overflow-y-scroll'>
            
            {data?.datas?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
            </div>}

            {path === 'daily_tasks' &&
            <div className="grid -mx-1 grid-cols-5 mt-3">
                {data?.datas?.map((d)=>(
                    <div  key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer' >
                        <div className='bg-yellow-100 p-2'>
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0  top-0'><AiOutlineEdit size={20} className='absolute bg-white p-1 right-1 top-0' onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        <h6 className='text-[11px] break-all' >Message : <span className='font-[600] text-[11px] line-clamp-2'>{d?.message}</span> </h6>
                        </div>
                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>

                </div>))}
            </div>
            }

            {path === 'calls' &&
            <div className="grid -mx-1 grid-cols-5 mt-3">
                    {data?.datas?.map((d)=>(
                    <div  key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer' >
                        <div className='bg-sky-100 p-2'>
                        <Tooltip title="Convert"><span className='absolute bg-white p-1 right-6 border-r border-slate-100 top-0'><AiOutlineContacts size={12}  onClick={()=>{navigator('/leads/contacts/create',{state:{type:'convert', data:d}})}} /></span></Tooltip>
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0  top-0'><AiOutlineEdit size={20} className='absolute bg-white p-1 right-1 top-0' onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        {(d?.contact_name !== null && d?.contact_name !== undefined) && <h6 className='text-[11px]' >Name : <span className='font-[600] text-[12px]'>{d?.contact_name} / {d?.phone}</span> </h6>}
                        {d?.email !== null && <h6 className='text-[11px]' >Email : <span className='font-[600] text-[12px]'>{d?.email}</span> </h6>}

                        <h6 className='text-[11px] font-[600]'>Company Info : </h6>

                        {d?.company_name !== null &&  <h6 className='text-[11px] break-all' ><span className='font-[500] text-[12px]'>{d?.company_name}</span> </h6>}
                        <h6 className='text-[11px] break-all ' >Message : <span className='font-[600] text-[11px] line-clamp-2'>{d?.message}</span> </h6>
                        </div>
                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>

                </div>))}
            </div>}

            {path === 'walk_ins' &&
            <div className="grid -mx-1 grid-cols-5 mt-3">
                    {data?.datas?.map((d)=>(
                    <div key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer' >
                        <Tooltip title="Convert"><span className='absolute bg-white p-1 right-5 border-r border-slate-100 top-0'><AiOutlineContacts size={12} onClick={()=>{navigator('/leads/contacts/create',{state:{type:'convert', data:d}})}} /></span></Tooltip>
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0  top-0'><AiOutlineEdit size={12} onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        <div className='mt-1'>
                        <div className='bg-teal-100 p-2'>
                        {d?.contact_name !== '' && <h6 className='text-[11px]' >Name : <span className='font-[600] text-[12px]'>{d?.contact_name} / {d?.phone}</span> </h6>}
                        {d?.email !== '' && <h6 className='text-[11px]' >Email : <span className='font-[600] text-[12px]'>{d?.email}</span> </h6>}

                        <h6 className='text-[11px] font-[600]'>Company Info : </h6>
                            {d?.company_name !=='' &&  <h6 className='text-[11px] break-all' ><span className='font-[500] text-[12px]'>{d?.company_name}</span> </h6>}
                            {d?.address !=='' && <h6 className='text-[11px] break-all' ><span className='font-[500] text-[12px]'>{d?.address}</span> </h6>}
                            
                            <div className='bg-teal-200 px-2 py-1.5'>
                            {d?.zoneInfo !== '' &&  <h6 className='text-[11px]' >Zone Info : <span className='font-[600] text-[12px]'>{d?.zoneInfo}</span> </h6>}
                            {d?.building_name !== '' &&  <h6 className='text-[11px]' >Building Name : <span className='font-[600] text-[12px]'>{d?.building_name}</span> </h6>}
                            {d?.floor_no !== '' &&  <h6 className='text-[11px]' >Floor No : <span className='font-[600] text-[12px]'>{d?.floor_no}</span> </h6>}
                            </div>
                            {d?.requirment !=='' &&  <h6 className='text-[11px] break-all font-[600]'>Remarks : </h6>}
                            {d?.requirment !=='' && <h6 className='text-[11px] break-all' ><span className='font-[500] text-[12px]'>{d?.requirment}</span> </h6>}

                        </div> 
                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>
                        </div>
                </div>))}
            </div>}

            {path === 'property_visit' &&
            <div className="grid -mx-1 grid-cols-5 mt-3">
                    {data?.datas?.map((d)=>(
                    <div key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer'>
                        <Tooltip title="Convert"><span className='absolute bg-white p-1 right-5 border-r border-slate-100 top-0'><AiOutlineContacts size={12}  onClick={()=>{navigator('/leads/landlords/create',{state:{type:'convert', data:d}})}} /></span></Tooltip>
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0 top-0'><AiOutlineEdit size={12}  onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        <div className='mt-1'>
                        <div className='bg-purple-100 p-2'>
                        {d?.contact_name !=='' && <h6 className='text-[11px]' >Name : <span className='font-[600] text-[12px]'>{d?.contact_name} / {d?.phone}</span> </h6>}
                        {d?.email !=='' && <h6 className='text-[11px]' >Email : <span className='font-[600] text-[12px]'>{d?.email}</span> </h6>}

                        <h6 className='text-[11px] font-[600]'>Property Info : </h6>
                        {d?.company_name !=='' &&  <h6 className='text-[11px] break-all' ><span className='font-[600] text-[12px]'>{d?.company_name}</span> </h6>}
                        {d?.address !=='' && <h6 className='text-[11px]' break-all ><span className='font-[400] text-[12px]'>{d?.address}</span> </h6>}
                        {d?.requirment !=='' && <h6 className='text-[11px]  font-[600]'>Remarks : </h6>}
                        {d?.requirment !=='' && <h6 className='text-[11px] break-all' ><span className='font-[400] text-[12px]'>{d?.requirment}</span> </h6>}

                        </div> 
                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>
                        </div>
                </div>))}
            </div>}

            {path === 'meeting' &&
            <div className="grid -mx-1 grid-cols-5 mt-3">
                    {data?.datas?.map((d)=>(
                    <div key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer'>
                        <Tooltip title="Convert"><span className='absolute bg-white p-1 right-5 border-r border-slate-100 top-0'><AiOutlineContacts size={12}  onClick={()=>{navigator('/leads/contacts/create',{state:{type:'convert', data:d}})}} /></span></Tooltip>
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0 top-0'><AiOutlineEdit size={12}  onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        <div className='mt-1'>
                        <div className='bg-rose-100 p-2'>
                        {d?.contact_name !=='' &&  <h6 className='text-[11px]' >Name : <span className='font-[600] text-[12px]'>{d?.contact_name} / {d?.phone}</span> </h6>}
                        {d?.email !=='' &&  <h6 className='text-[11px]' >Email : <span className='font-[600] text-[12px]'>{d?.email}</span> </h6>}

                        <h6 className='text-[11px] font-[600]'>Location : </h6>
                        {d?.address !=='' &&  <h6 className='text-[11px] break-all' ><span className='font-[500] text-[12px]'>{d?.address}</span> </h6>}
                        {d?.requirment !=='' &&  <h6 className='text-[11px] font-[600]'>Remarks : </h6>}
                        {d?.requirment !=='' &&  <h6 className='text-[11px] break-all' ><span className='font-[500] text-[12px]'>{d?.requirment}</span> </h6>}

                        </div> 
                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Stage  : <span className='font-[700]'>{d?.stage?.name}</span></h6>
                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>
                        </div>
                </div>))}
            </div>}

            {path === 'scouting' &&
            <div className="grid -mx-1 grid-cols-5 mt-3">
                    {data?.datas?.map((d)=>(
                    <div key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer'>
                        <Tooltip title="Convert"><span className='absolute bg-white p-1 right-5 border-r border-slate-100 top-0'><AiOutlineContacts size={12}  onClick={()=>{navigator('/leads/contacts/create',{state:{type:'convert', data:d}})}} /></span></Tooltip>
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0 top-0'><AiOutlineEdit size={12}  onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        <div className='mt-1'>
                        <div className='bg-rose-100 p-2'>
                        {d?.contact_name !=='' &&  <h6 className='text-[11px]' >Name : <span className='font-[600] text-[12px]'>{d?.contact_name} / {d?.phone}</span> </h6>}
                      
                        {d?.requirment !=='' &&  <h6 className='text-[11px] font-[600]'>Remarks : </h6>}
                        {d?.requirment !=='' &&  <h6 className='text-[11px] break-all' ><span className='font-[500] text-[12px]'>{d?.requirment}</span> </h6>}

                        </div> 
                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>
                        </div>
                </div>))}
            </div>}

            </div>
        </div>

    </div>
  )
}

export default DailyTask