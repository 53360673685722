import React, { useEffect, useState } from 'react'
import GoBack from '../../../components/back/GoBack'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { TextAreaInput1, TextInput } from '../../../components/input'
import DatabaseMenu from '../DatabaseMenu'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
// import { CreatePropertyTypeService, UpdatePropertyTypeService } from '../../../../services/database/databaseoptions/PropertyTypeServices'
import { CreateApprovedTypeService, GetApprovedTypeService, UpdateApprovedTypeService } from '../../../services/database/databaseoptions/ApprovedTypeServices'
import { Select } from 'antd'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import {IoClose} from 'react-icons/io5'
import { Tooltip } from '@mui/material'
import { GetPropertyZoneService } from '../../../services/database/databaseoptions/PropertyZoneServices'
import { GetPropertyAmenitiesService } from '../../../services/database/databaseoptions/PropertyAmenitiesServices'
import { FilterLocationBasedZoneService, GetLocationBasedZoneService } from '../../../services/database/databaseoptions/LocationBasedZoneServices'
import { GetPropertyCategoryService } from '../../../services/database/databaseoptions/PropertyCategoryServices'
import { GetPropertyTypeService } from '../../../services/database/databaseoptions/PropertyTypeServices'
import { CreateManagedOfficeService, GetManagedOfficeDetailService, UpdateManagedOfficeService, UploadManagedOfficeAttachmentService } from '../../../services/database/mainoptions/ManagedOfficeServices'
import { GetPropertyStatusService } from '../../../services/database/databaseoptions/PropertyStatusServices'
import { GetFurnishTypeService } from '../../../services/database/databaseoptions/FurnishTypeServices'
import ErrorComponent from '../../../components/errorDesign/ErrorComponent'
import { GetPropertyFacilityService } from '../../../services/database/databaseoptions/PropertyFacilityServices'

function ManagedOfficeCE() {
  
  const [data,setdata] = useState({main_image:'',image1:'',image2:'',image3:'',image4:'',property_category:'',managed_office_name:'',zone:'',location_of_property:'',longitude:'',latitude:'',google_coordinate_link:'',managed_office_address:'',landmark_to_property:'',seater_offered:'',rent_per_seat:'',no_of_car_parking_slots:'',car_parking_charges:'',security_deposit:'',escalation:'',lock_in_period:'',lease_term_tenure:'',oc_availability:false,power_and_backup:'',facility_type:'',amenities_available:false,amenities:[],status:'',primary_contact_name:'',primary_contact_no:'',secondary_contact_name:'',secondary_contact_no:''})  
  const [error,seterror] = useState({main_image:'',image1:'',image2:'',image3:'',image4:'',property_category:'',managed_office_name:'',zone:'',location_of_property:'',longitude:'',latitude:'',google_coordinate_link:'',managed_office_address:'',landmark_to_property:'',seater_offered:'',rent_per_seat:'',no_of_car_parking_slots:'',car_parking_charges:'',security_deposit:'',escalation:'',lock_in_period:'',lease_term_tenure:'',oc_availability:'',power_and_backup:'',facility_type:'',amenities_available:'',amenities:'',status:'',primary_contact_name:'',primary_contact_no:'',secondary_contact_name:'',secondary_contact_no:''})  

  const [step,setstep] = useState(1)

  const [category,setcategory] = useState([])
  const [basetypes,setbasetypes] = useState([])
  const [zones,setzones] = useState([])
  const [locations,setlocations] = useState([])
  const [status,setstatus] = useState([])
  const [amenities,setamenities] = useState([])
  const [approvedTypes,setapprovedTypes] = useState([])
  const [facility_type,setfacility_type] = useState([])


//   console.log("category",category)
//   console.log("zones",zones)
//   console.log("locations",locations)
//   console.log("amenities",amenities)
//   console.log("approvedTypes",approvedTypes)

  const navigate = useNavigate()
  const  {state} = useLocation()


  useEffect(()=>{
    getdataoptions()
  },[])

  useEffect(()=>{
    if(state !== undefined && state !== null){
        getdata()
    }
  },[state])


  async function getdata() {
    const response = await GetManagedOfficeDetailService(state)
    let d = response?.data?.datas[0]

    let amenities = []
    d?.amenities?.forEach((a)=>amenities?.push({label:a?.name,value:a?._id}))
    let passData = {
       ...d,
       _id:d?._id,
       main_image:d?.main_image,
       image1:d?.image1,
       image2:d?.image2,
       image3:d?.image3,
       image4:d?.image4,
    //    base_type:d?.base_type?._id !== undefined ? {label:d?.base_type?.name,value:d?.base_type?._id} : '',
       property_category:d?.property_category?._id !== undefined ? {label:d?.property_category?.name,value:d?.property_category?._id} : '',
       managed_office_name:d?.managed_office_name,
       zone:d?.zone?._id !== undefined ? {label:d?.zone?.name,value:d?.zone?._id} : '',
       location_of_property:d?.location_of_property?._id !== undefined ? {label:d?.location_of_property?.name,value:d?.location_of_property?._id} : '',
       longitude:d?.longitude,
       latitude:d?.latitude,
       google_coordinate_link:d?.google_coordinate_link,
       managed_office_address:d?.managed_office_address,
       landmark_to_property:d?.landmark_to_property,
       seater_offered:d?.seater_offered,
       rent_per_seat:d?.rent_per_seat,
       no_of_car_parking_slots:d?.no_of_car_parking_slots,
       car_parking_charges:d?.car_parking_charges,
       security_deposit:d?.security_deposit,
       escalation:d?.escalation,
       lock_in_period:d?.lock_in_period,
       lease_term_tenure:d?.lease_term_tenure,
       possession_type:d?.possession_type,
       facility_type:d?.facility_type?._id !== undefined ? {label:d?.facility_type?.name,value:d?.facility_type?._id} : '',

       amenities_available:d?.amenities_available,
       amenities:amenities,
       remarks:d?.remarks,
      
       
       
    //    power_and_backup:d?.power_and_backup,
    //    total_built_up_area:d?.total_built_up_area,
    //    no_of_floors:d?.no_of_floors,
    //    per_floor_area:d?.per_floor_area,
    //    area_offered:d?.area_offered,
    //    floor_offered:d?.floor_offered,
    //    rent_per_sqft:d?.rent_per_sqft,

       maintenace_charges:d?.maintenace_charges,
       
    //    interior_details:d?.interior_details,

       oc_availability:d?.oc_availability,
       status:d?.status?._id !== undefined ? {label:d?.status?.name,value:d?.status?._id} : '',
       primary_contact_name:d?.primary_contact_name,
       primary_contact_no:d?.primary_contact_no,
       secondary_contact_name:d?.secondary_contact_name,
       secondary_contact_no:d?.secondary_contact_no
    }
    setdata(passData)
    console.log("response?.data",response?.data)
  }


  async function getdataoptions(){
    const response = await GetPropertyZoneService(1,'','','',1)
    const response1 = await GetPropertyAmenitiesService(1,'','','',1)
    const response2 = await GetApprovedTypeService(1,'','','',1)
    const response3 = await GetPropertyCategoryService(1,'','','',1)
    const response4 = await GetPropertyTypeService(1,'','','',1)
    const response5 = await GetPropertyStatusService(1,'','','',1)
    const response6 = await GetPropertyFacilityService(1,'','','',1)
    
    let arr = []
    let arr1 = []
    let arr2 = []
    let arr3 = []
    let arr4 = []
    let arr5 = []
    let arr6 = []

    response?.data?.datas?.forEach((d1)=>{
        arr.push({label:d1?.name,value:d1?._id})
    })
    response1?.data?.datas?.forEach((d1)=>{
        arr1.push({label:d1?.name,value:d1?._id})
    })
    response2?.data?.datas?.forEach((d1)=>{
        arr2.push({label:d1?.name,value:d1?._id})
    })
    response3?.data?.datas?.forEach((d1)=>{
        arr3.push({label:d1?.name,value:d1?._id})
    })
    response4?.data?.datas?.forEach((d1)=>{
        arr4.push({label:d1?.name,value:d1?._id})
    })
    response5?.data?.datas?.forEach((d1)=>{
        arr5.push({label:d1?.name,value:d1?._id})
    })
    response6?.data?.datas?.forEach((d1)=>{
        arr6.push({label:d1?.name,value:d1?._id})
    })

    setzones(arr)
    setamenities(arr1)
    setapprovedTypes(arr2)
    setcategory(arr3)
    setbasetypes(arr4)
    setstatus(arr5)
    setfacility_type(arr6)
  }

  async function getlocationbasedzone(id){
    const response = await FilterLocationBasedZoneService(id)
    let arr = []
    response?.data?.datas?.forEach((d1)=>{
        arr.push({label:d1?.name,value:d1?._id})
    })
    setlocations(arr)
  }


  async function handlechange(e){
        setdata({...data,[e.target.name]:e.target.value})
        seterror({...error,[e.target.name]:''})
  }

  async function uploadfilefunc(v,name){
   
    const fd = new FormData()
    fd.append('image',v); 
    const response = await UploadManagedOfficeAttachmentService(fd)
    if(response?.status === 200){
      setdata({...data,[name]:response?.data?.data})
      seterror({...error,[name]:''})
    }
  }

  async function handleSelect(v,type){
    if(type === 'zone'){
        getlocationbasedzone(v)
        let d = zones?.find((f)=>f?.value === v)
        setdata({...data,zone:d})
        seterror({...error,zone:''})
    }
    if(type === 'base_type'){
        let d = basetypes?.find((f)=>f?.value === v)
        setdata({...data,base_type:d})
        seterror({...error,base_type:''})
    }
    if(type === 'category'){
        let d = category?.find((f)=>f?.value === v)
        setdata({...data,property_category:d})
        seterror({...error,property_category:''})
    }
    if(type === 'status'){
        let d = status?.find((f)=>f?.value === v)
        setdata({...data,status:d})
        seterror({...error,status:''})
    }
    if(type === 'location_of_property'){
        let d = locations?.find((f)=>f?.value === v)
        setdata({...data,location_of_property:d})
        seterror({...error,location_of_property:''})
    }
    if(type === 'facility_type'){
        let d = facility_type?.find((f)=>f?.value === v)
        setdata({...data,facility_type:d})
        seterror({...error,facility_type:''})
    }
    if(type === 'amenities'){
        // console.log("v here anna",v)
        let arr = []
        v?.forEach((v1)=>{
           let d = amenities?.find((v2)=>v2?.value === v1)
           if(d !== null){
            arr.push(d)
           }
        })
        setdata({...data,amenities:arr})
    }
    
  }

  async function submitform(){
     if(step === 1){
        // console.log("data",data)
        if(!data?.managed_office_name){
            seterror({...error,managed_office_name:'This Field is required'})
        }else if(!data?.base_type){
            seterror({...error,base_type:'This Field is required'})
        }else if(!data?.property_category){
            seterror({...error,property_category:'This Field is required'})
        }else if(!data.zone){
            seterror({...error,zone:'This Field is required'})
        }else if(!data.location_of_property){
            seterror({...error,location_of_property:'This Field is required'})
        }else if(!data.status){
            seterror({...error,status:'This Field is required'})
        }else{
            setstep(2)
        }
     }else if(step === 2){
        if(!data.facility_type){
            seterror({...error,facility_type:'This Field is required'})
        }else{
           setstep(3)
        }
     }else if(step === 3){
       if(!data?.main_image){
        seterror({...error,main_image:'This Field is requried'})
       }else{
         let amenities_list = []
         let send_data = {...data}

         data?.amenities?.forEach((d)=>{
            amenities_list?.push(d?.value)
         })
         send_data['base_type'] = data?.base_type?.value
         send_data['property_category'] = data?.property_category?.value
         send_data['zone'] = data?.zone?.value
         send_data['location_of_property'] = data?.location_of_property?.value
         send_data['amenities'] = amenities_list
         send_data['facility_type'] = data?.facility_type?.value
         send_data['status'] = data?.status?.value

        if(state === undefined || state === null){
            const response = await CreateManagedOfficeService(send_data)
            if(response?.status === 201){
                resetform()
                toast.success("Managed Office Created Successfully")
            }
        }else{
            const response = await UpdateManagedOfficeService(send_data,state)
            if(response?.status === 200){
                resetform()
                navigate(-1)
                toast.success("Managed Office Updated Successfully")
            }
        }

        }
     }
  }

  async function resetform(){
    setdata({main_image:'',image1:'',image2:'',image3:'',image4:'',property_category:'',managed_office_name:'',zone:'',location_of_property:'',longitude:'',latitude:'',google_coordinate_link:'',managed_office_address:'',landmark_to_property:'',seater_offered:'',rent_per_seat:'',no_of_car_parking_slots:'',car_parking_charges:'',security_deposit:'',escalation:'',lock_in_period:'',lease_term_tenure:'',oc_availability:false,power_and_backup:'',facility_type:'',amenities_available:false,amenities:[],status:'',primary_contact_name:'',primary_contact_no:'',secondary_contact_name:'',secondary_contact_no:''})
    seterror({main_image:'',image1:'',image2:'',image3:'',image4:'',property_category:'',managed_office_name:'',zone:'',location_of_property:'',longitude:'',latitude:'',google_coordinate_link:'',managed_office_address:'',landmark_to_property:'',seater_offered:'',rent_per_seat:'',no_of_car_parking_slots:'',car_parking_charges:'',security_deposit:'',escalation:'',lock_in_period:'',lease_term_tenure:'',oc_availability:'',power_and_backup:'',facility_type:'',amenities_available:false,amenities:'',status:'',primary_contact_name:'',primary_contact_no:'',secondary_contact_name:'',secondary_contact_no:''})  
    setlocations([])
    setstep(1)
   }

   function openFile(v){
    let url = `${process.env.REACT_APP_BACKEND_IMAGE_URL}${v}`
    window.open(url,'_blank')
   }

  return (
    <div className='flex'>
        <div className='min-w-[180px] w-[180px] max-w-[180px]'>
        <DatabaseMenu /> 
        </div>
        <div className='ml-4 w-[50%] mt-4 pr-5 min-h-screen max-h-screen h-screen overflow-y-scroll'>
        <div>
        <GoBack />

        <div className='border-b  pb-2'>
        <h6 className='font-[800] mb-1'>Create / Edit Managed Office</h6> 
        <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the Managed office for the property function.</h6> 
        </div> 


        <div className='flex  border-b text-[11px]'>
           <h6 onClick={()=>setstep(1)} className={`w-[90px] cursor-pointer p-[4px] text-center ${step == 1 && 'bg-slate-600 text-white'} font-[600]`}>Basic Info</h6>
           <h6 onClick={()=>setstep(2)} className={`w-[90px] cursor-pointer p-[4px] text-center ${step == 2 && 'bg-slate-600 text-white'} font-[600]`}>More Info</h6>
           <h6 onClick={()=>setstep(3)} className={`w-[90px] cursor-pointer p-[4px] text-center ${step == 3 && 'bg-slate-600 text-white'} font-[600]`}>Building Image</h6>
        </div>    

        {step === 1 && 
        <div className='flex items-start'>
            <div className='w-[48%] mr-[2%]'>


            <TextInput 
                mandatory={true}
                label={'Managed Office Name'}  
                variant="standard"
                name="managed_office_name"
                type="text"
                value={data.managed_office_name}
                error={error.managed_office_name}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextAreaInput1 
                mandatory={false}
                label={'Managed Office Address'}  
                variant="standard"
                name="managed_office_address"
                type="text"
                value={data.managed_office_address}
                error={error.managed_office_address}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> 

                <TextInput 
                mandatory={false}
                label={'LandMark To Property'}  
                variant="standard"
                name="landmark_to_property"
                type="text"
                value={data.landmark_to_property}
                error={error.landmark_to_property}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> 

            <TextInput 
                mandatory={false}
                label={'Longitude'}  
                variant="standard"
                name="longitude"
                type="text"
                value={data.longitude}
                error={error.longitude}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                mandatory={false}
                label={'Latitude'}  
                variant="standard"
                name="latitude"
                type="text"
                value={data.latitude}
                error={error.latitude}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

            <TextInput 
                mandatory={false}
                label={'Google Coordinate Link'}  
                variant="standard"
                name="google_coordinate_link"
                type="text"
                value={data.google_coordinate_link}
                error={error.google_coordinate_link}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

                <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Status*</h6>
                <Select 
                    multiple={true}
                    bordered={false}
                    value={data.status}
                    options={status}
                    onChange={(v)=>handleSelect(v,'status')}
                    style={{fontSize:'12px',height:'32px'}}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>

                <ErrorComponent error={error?.status} />



            </div>

            <div className='w-[48%] ml-[2%]'>
                {/* <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Base Type*</h6>
                <Select 
                    bordered={false}
                    value={data?.base_type}
                    options={basetypes}
                    onChange={(v)=>handleSelect(v,'base_type')}
                    style={{fontSize:'12px',height:'32px'}}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>

                <ErrorComponent error={error?.base_type} /> */}

                <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Property Category*</h6>
                <Select 
                    bordered={false}
                    value={data?.property_category}
                    options={category}
                    onChange={(v)=>handleSelect(v,'category')}
                    style={{fontSize:'12px',height:'32px'}}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>

                <ErrorComponent error={error?.property_category} />

                <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Zone*</h6>
                <Select 
                    bordered={false}
                    value={data?.zone}
                    options={zones}
                    onChange={(v)=>handleSelect(v,'zone')}
                    style={{fontSize:'12px',height:'32px'}}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>

                <ErrorComponent error={error?.zone} />

                <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Location of Property*</h6>
                <Select 
                    bordered={false}
                    value={data?.location_of_property}
                    options={locations}
                    onChange={(v)=>handleSelect(v,'location_of_property')}
                    style={{fontSize:'12px',height:'32px'}}
                    className='border-l-4 max-w-[400px] border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
               
                <ErrorComponent error={error?.location_of_property} />


                <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Amenities Available*</h6>
                <div onClick={()=>setdata({...data,amenities_available:!data?.amenities_available})}>
                {!data?.amenities_available ?   
                <BiCheckbox size={20} className='text-slate-300' /> : 
                <BiCheckboxSquare size={20} className='text-slate-700' /> }
                </div>

                {data?.amenities_available &&
                <>
                <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Amenities List*</h6>
                <div className='min-w-[100%] max-w-[100%]'>
                <Select 
                    mode={'multiple'}
                    bordered={false}
                    value={data?.amenities}
                    options={amenities}
                    onChange={(v)=>handleSelect(v,'amenities')}
                    style={{fontSize:'12px',minHeight:'32px',maxWidth:'100%'}}
                    className='w-full max-w-[400px] border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>
                </div>
                </>}
            </div>


        </div>}

        {step === 2 && 
        <div className='flex items-start'>
            <div className='w-[48%] mr-[2%]'>

            <TextInput 
                mandatory={false}
                label={'Seater Offered'}  
                variant="standard"
                name="seater_offered"
                type="text"
                value={data.seater_offered}
                error={error.seater_offered}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                mandatory={false}
                label={'Rent Per Seat'}  
                variant="standard"
                name="rent_per_seat"
                type="text"
                value={data.rent_per_seat}
                error={error.rent_per_seat}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>


            <TextInput 
                mandatory={false}
                label={'No. of Car Parking Slots'}  
                variant="standard"
                name="no_of_car_parking_slots"
                type="text"
                value={data.no_of_car_parking_slots}
                error={error.no_of_car_parking_slots}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextAreaInput1 
                mandatory={false}
                label={'Car Parking Charges'}  
                variant="standard"
                name="car_parking_charges"
                type="text"
                value={data.car_parking_charges}
                error={error.car_parking_charges}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

            <TextInput 
                mandatory={false}
                label={'Security Deposit'}  
                variant="standard"
                name="security_deposit"
                type="text"
                value={data.security_deposit}
                error={error.security_deposit}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                mandatory={false}
                label={'Escalation'}  
                variant="standard"
                name="escalation"
                type="text"
                value={data.escalation}
                error={error.escalation}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>      

            <TextInput 
                mandatory={false}
                label={'Lock-in Period'}  
                variant="standard"
                name="lock_in_period"
                type="text"
                value={data.lock_in_period}
                error={error.lock_in_period}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  



            <TextInput 
                mandatory={false}
                label={'Lease Term / Tenure'}  
                variant="standard"
                name="lease_term_tenure"
                type="text"
                value={data.lease_term_tenure}
                error={error.lease_term_tenure}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  


            </div>

            <div className='w-[48%] ml-[2%]'>

            <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Facility Type</h6>
                <Select 
                    multiple={true}
                    bordered={false}
                    value={data.facility_type}
                    options={facility_type}
                    onChange={(v)=>handleSelect(v,'facility_type')}
                    style={{fontSize:'12px',height:'32px'}}
                    className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'/>

                <ErrorComponent error={error?.facility_type} />

                
            <TextInput 
                mandatory={false}
                label={'Power & Backup'}  
                variant="standard"
                name="power_and_backup"
                type="text"
                value={data.power_and_backup}
                error={error.power_and_backup}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

<TextInput 
                mandatory={false}
                label={'Maintenance Charges'}  
                variant="standard"
                name="maintenace_charges"
                type="text"
                value={data.maintenace_charges}
                error={error.maintenace_charges}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

            <TextAreaInput1 
                mandatory={false}
                label={'Remarks'}  
                variant="standard"
                name="remarks"
                type="text"
                value={data.remarks}
                error={error.remarks}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

                
                <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>OC Availablility*</h6>
                <div onClick={()=>setdata({...data,oc_availability:!data?.oc_availability})}>
                {!data?.oc_availability ?   
                <BiCheckbox size={20} className='text-slate-300' /> : 
                <BiCheckboxSquare size={20} className='text-slate-700' /> }
                </div>

                <TextInput 
                mandatory={false}
                label={'Primary Contact Name'}  
                variant="standard"
                name="primary_contact_name"
                type="text"
                value={data.primary_contact_name}
                error={error.primary_contact_name}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> 

                <TextInput 
                mandatory={false}
                label={'Primary Contact No'}  
                variant="standard"
                name="primary_contact_no"
                type="text"
                value={data.primary_contact_no}
                error={error.primary_contact_no}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> 


               


           

            <TextInput 
                mandatory={false}
                label={'Secondary Contact Name'}  
                variant="standard"
                name="secondary_contact_name"
                type="text"
                value={data.secondary_contact_name}
                error={error.secondary_contact_name}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> 


                <TextInput 
                mandatory={false}
                label={'Secondary Contact No'}  
                variant="standard"
                name="secondary_contact_no"
                type="text"
                value={data.secondary_contact_no}
                error={error.secondary_contact_no}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

            </div>


        </div>}

        {step === 3 && 
        <div className='flex flex-wrap'>

        <div className='w-[48%] mt-2 relative mr-[2%]'>
        <h6 className='text-[11px] font-[600] mb-1' >Main Image</h6>
        {!(data.main_image === '' || data.main_image == null) && <h6 onClick={()=>openFile(data?.main_image)} className='underline text-[10px] absolute cursor-pointer right-0 top-0 font-[600]'>View File</h6>}
        {(data.main_image === '' || data.main_image == null) ?
            <form onClick={()=>document.querySelector(`.input-field`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-l-4 border-l-slate-700 border-slate-300 `}>
                <input type='file' onChange={({target:{files}})=>{
                files[0] && uploadfilefunc(files[0],'main_image')
                }} accept="*" className='input-field' hidden />
            </form> 
        :
            <div className='p-2 border border-slate-300 border-l-4 border-l-slate-700 relative flex flex-col  cursor-pointer'>
                <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,main_image:''})}/></Tooltip>
                <h6 className='text-[12px] truncate w-48 ml-0'>{data?.main_image}</h6>
            </div>
            
        }

        <ErrorComponent error={error?.main_image} />

        </div>


        <div className='w-[48%] mt-2 relative ml-[2%]'>
        <h6 className='text-[11px] font-[600] mb-1' >Image 1</h6>
        {!(data.image1 === '' || data.image1 == null) && <h6 onClick={()=>openFile(data?.image1)} className='underline text-[10px] absolute cursor-pointer right-0 top-0 font-[600]'>View File</h6>}
        {(data.image1 === '' || data.image1 == null) ?
            <form onClick={()=>document.querySelector(`.input-field1`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                <input type='file' onChange={({target:{files}})=>{
                files[0] && uploadfilefunc(files[0],'image1')
                }} accept="*" className='input-field1' hidden />
            </form> 
        :
            <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,image1:''})}/></Tooltip>
                <h6 className='text-[12px] truncate w-48 ml-0'>{data?.image1}</h6>
            </div>
            
        }
        </div>

        <div className='w-[48%] mt-2 relative mr-[2%]'>
        <h6 className='text-[11px] font-[600] mb-1' >Image 2</h6>
        {!(data.image2 === '' || data.image2 == null) && <h6 onClick={()=>openFile(data?.image2)} className='underline text-[10px] absolute cursor-pointer right-0 top-0 font-[600]'>View File</h6>}
        {(data.image2 === '' || data.image2 == null) ?
            <form onClick={()=>document.querySelector(`.input-field2`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                <input type='file' onChange={({target:{files}})=>{
                files[0] && uploadfilefunc(files[0],'image2')
                }} accept="*" className='input-field2' hidden />
            </form> 
        :
            <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,image2:''})}/></Tooltip>
                <h6 className='text-[12px] truncate w-48 ml-0'>{data?.image2}</h6>
            </div>
        }
        </div>

        <div className='w-[48%] mt-2 relative ml-[2%]'>
        <h6 className='text-[11px] font-[600] mb-1' >Image 3</h6>
        {!(data.image3 === '' || data.image3 == null) && <h6 onClick={()=>openFile(data?.image3)} className='underline text-[10px] absolute cursor-pointer right-0 top-0 font-[600]'>View File</h6>}
        {(data.image3 === '' || data.image3 == null) ?
            <form onClick={()=>document.querySelector(`.input-field3`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                <input type='file' onChange={({target:{files}})=>{
                files[0] && uploadfilefunc(files[0],'image3')
                }} accept="*" className='input-field3' hidden />
            </form> 
        :
            <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,image3:''})}/></Tooltip>
                <h6 className='text-[12px] truncate w-48 ml-0'>{data?.image3}</h6>
            </div>
        }
        </div>

        <div className='w-[48%] mt-2 relative mr-[2%]'>
        <h6 className='text-[11px] font-[600] mb-1' >Image 4</h6>
        {!(data.image4 === '' || data.image4 == null) && <h6 onClick={()=>openFile(data?.image4)} className='underline text-[10px] absolute cursor-pointer right-0 top-0 font-[600]'>View File</h6>}
        {(data.image4 === '' || data.image4 == null) ?
            <form onClick={()=>document.querySelector(`.input-field4`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border  border-slate-300 `}>
                <input type='file' onChange={({target:{files}})=>{
                files[0] && uploadfilefunc(files[0],'image4')
                }} accept="*" className='input-field4' hidden />
            </form> 
        :
            <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,image4:''})}/></Tooltip>
                <h6 className='text-[12px] truncate w-48 ml-0'>{data?.image4}</h6>
            </div>
        }
        </div>

        </div>}

        <div className='flex mt-2 justify-end border-t pt-2'>
            <ButtonOutlinedAutoWidth  btnName="Cancel" onClick={()=>navigate(-1)} />  
            <h6 className='w-[10px]'></h6>
            <ButtonFilledAutoWidth  btnName={step == 3 ? "Save" : "Next"} onClick={submitform} />  
        </div>    

        </div> 
    </div >
    </div>

  )
}

export default ManagedOfficeCE