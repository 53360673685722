import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateFTSVerticalMonthlyMeetingReviewService=async(data)=>{
    try {
        const result = await post(`api/fts_vertical_monthly_meeting_review/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFTSVerticalMonthlyMeetingReviewService=async(data,id)=>{
    try {
        const result = await put(`api/fts_vertical_monthly_meeting_review/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFTSVerticalMonthlyMeetingReviewGraphDataService=async(department,from_date,to_date)=>{
    try {
        const result = await get(`api/fts_vertical_monthly_meeting_review/get_report_data?department=${department}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFTSVerticalMonthlyMeetingReviewService=async(page,text,from_date,to_date,department,status)=>{
    try {
        const result = await get(`api/fts_vertical_monthly_meeting_review/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&department=${department}&status=${status}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFTSVerticalMonthlyMeetingReviewService=async(id)=>{
    try {
        const result = await deleteRequest(`api/fts_vertical_monthly_meeting_review/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadFTSVerticalMonthlyMeetingReviewDocsService=async(data)=>{
    try {
        const result = await postfd(`api/fts_vertical_monthly_meeting_review/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadFTSVerticalMonthlyMeetingReviewService=async(data)=>{
    try {
        const result = await postfd(`api/fts_vertical_monthly_meeting_review/upload_excel`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadFTSVerticalMonthlyMeetingReviewService=async(page,text,from_date,to_date)=>{
    try {
        const result = await get(`api/fts_vertical_monthly_meeting_review/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

