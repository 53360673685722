import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateRevenueForecastService=async(data)=>{
    try {
        const result = await post(`api/revenue_forecast/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateRevenueForecastService=async(data,id)=>{
    try {
        const result = await put(`api/revenue_forecast/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetRevenueForecastService=async(page,department,from_date,to_date)=>{
    try {
        const result = await get(`api/revenue_forecast/get?page=${page}&department=${department}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteRevenueForecastService=async(id)=>{
    try {
        const result = await deleteRequest(`api/revenue_forecast/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadRevenueForecastService=async(data)=>{
    try {
        const result = await postfd(`api/revenue_forecast/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadRevenueForecastService=async(page,text,from_date,to_date,step)=>{
    try {
        const result = await get(`api/revenue_forecast/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}