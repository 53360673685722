import { post,put,get,deleteRequest, postfd } from "../../../helpers/apihelpers";

export const UploadOfficeSpaceAttachmentService=async(data)=>{
    try {
        const result = await postfd(`api/database/office_space/upload_file`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateOfficeSpaceService=async(data)=>{
    try {
        const result = await post(`api/database/office_space/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateOfficeSpaceService=async(data,id)=>{
    try {
        const result = await put(`api/database/office_space/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateAllOfficeSpaceService=async(data,id)=>{
    try {
        const result = await put(`api/database/office_space/update_all/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetOfficeSpaceService=async(page,from_date,to_date,text,step,status,zone,location_based_zone,user)=>{
    try {
        const result = await get(`api/database/office_space/get?page=${page}&from_date=${from_date}&to_date=${to_date}&text=${text}&step=${step}&status=${status}&zone=${zone}&location_based_zone=${location_based_zone}&user=${user}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetOfficeSpaceDetailService=async(id)=>{
    try {
        const result = await get(`api/database/office_space/get/${id}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const DeleteOfficeSpaceService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/office_space/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAdminOfficeSpaceService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/office_space/delete_admin/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterOfficeSpaceService=async(id)=>{
    try {
        const result = await get(`api/database/office_space/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadOfficeSpaceExcelService=async(data)=>{
    try {
        const result = await postfd(`api/database/office_space/upload_excel`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteOfficeFloorOrUnitService=async(type,id)=>{
    try {
        const result = await post(`api/database/office_space/delete_floor_or_unit/${type}/${id}`,{});
        return result;
    } catch (err) {
        return err.response;
    }
}