import React, { useEffect, useState } from 'react'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@mui/material'
import FTSLeadMenu from './FTSLeadMenu';
import { GetFTSLeadsSharedByUsService } from '../../services/FTSLeadServices';
import { useNavigate } from 'react-router-dom';
import {AiOutlineEdit} from 'react-icons/ai';
import {useSelector} from 'react-redux'

function FTSLeadShared() {

  const navigate = useNavigate()
  const [page,setpage] = useState(1)
  const [step,setstep] = useState(3)
  const [leads,setleads] = useState({datas:[],pagination:{total:0,totalPages:0}})

  const roles = useSelector(state=>state.Auth.roles)
  

  const stages = [{label:'Pending',value:3},{label:'Progress',value:4},{label:'Rejected',value:2},{label:'Converted',value:1}]

  useEffect(()=>{
    getdata()
  },[page,step])

  async function getdata() {
    const response = await GetFTSLeadsSharedByUsService(page,step)
    setleads(response?.data)
  }

  async function resetfunc() {
    
  } 


  return (
    <div className='h-screen max-h-screen'>
        <div className='flex'>
            <div className={`min-w-44`}>
                <FTSLeadMenu />
            </div>
            <div  className="w-[98%] px-4 pt-4">
            <div>
          <div className='flex items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total Leads Shared From FTS ({leads?.pagination?.total})</h6>
            <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? leads?.datas?.length > 0 ? 1 : 0 :  (page - 1) * leads?.pagination?.limit } - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < leads?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
              </div>



       
            </div>
          </div>
          <div className='border-b flex items-center justify-start'>
            {stages?.map((s)=>(
              <h6 key={s?.value} onClick={()=>{setstep(s?.value);setpage(1)}} className={`font-[600] ${step === s?.value && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-max px-2 text-[11px] capitalize`}>{s?.label}</h6>
            ))}
          </div>
              <>
                    {leads?.datas?.length > 0 && 
                    <div className='max-h-[85vh] mt-2 border-t border-l border-r overflow-y-scroll'>
                    <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
                        <h6 className='sticky top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600 border-r'>Contact Person Info</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Created Department</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Assigned Department</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[15%]  px-2 py-1 font-[600] text-slate-600  border-r'>Remarks</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600  border-r'>Stage</h6>
                        <h6 className='sticky top-0 z-50  text-[12px] w-[10%]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Status</h6>
                    </div>
                  
                    {leads?.datas?.map((d,i)=>(
                    <div key={d?._id} className='flex z-50 border-b border-slate-200 '  >
                        <h6 className='text-[12px] font-[500] w-[5%]  px-2 py-1  border-r border-slate-200 '>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
                        <h6 className='text-[12px] font-[500] w-[15%] px-2 py-1 border-r border-slate-200'>{d?.fts_lead?.company_name}
                        {d?.zone !== undefined && <h6 className='text-[10px]'>Zone : {d?.fts_lead?.zone}</h6>}</h6>
                        <h6 className='text-[12px] font-[500] w-[15%] bg-gray-50  px-2 py-1 break-all border-r border-slate-200'>
                          <div>
                          <h6>{d?.fts_lead?.contact_name}</h6>  
                          <span>{d?.fts_lead?.phone} / {d?.fts_lead?.email}  </span>
                          </div>
                        </h6>
                        <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
                          {d?.fts_lead?.department_id?.department_name}
                          <h6 className='text-[10px]'>Created By : {d?.fts_lead?.lead_owner?.name}</h6>
                          <h6 className='text-[10px] font-[500]'>{d?.fts_lead?.previously_handled_by}</h6>
                        </h6>
                        <h6 className='text-[11px] font-[500] w-[15%]  px-2 py-1 font-[800] border-r border-slate-200'>
                        {d?.department_id?.department_name}
                        </h6>
                        <h6 className='text-[11px]  w-[15%]  px-2 py-1  border-r border-slate-200'>
                           Requirment :  <span className='font-[800]'>{d?.requirment}</span>  /
                           Remarks :  <span className='font-[800]'>{d?.remarks}</span>
          
                           {d?.converted == '1' && <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.lead_id?._id})} className='text-[10px] cursor-pointer font-[500]  mt-1 underline text-blue-500'>View Details</h6>}
          
                        </h6>   
                        <h6 className='text-[12px]  flex items-start break-word  font-[500] w-[10%] border-r px-2 py-1 border-slate-200 '>
                          {d?.lead_id?._id !== undefined && <span className='bg-slate-100 px-2 py-1 rounded'>{d?.lead_id?.stage?.name}</span>}
                        </h6>
          
                       
                        <h6 className='text-[12px]   font-[500] w-[10%]  px-2 py-1 truncate border-slate-200 '>
                           {d?.converted == '3' && <span className='bg-blue-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-blue-600 font-[700]'>Pending</span>}
                           {d?.converted == '4' && <span className='bg-violet-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-violet-600 font-[700]'>Progress</span>}
                           {d?.converted == '2' && <span className='bg-red-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-red-600 font-[700]'>Rejected</span>}
                           {d?.converted == '1' && <span className='bg-green-100 flex w-max items-center h-max px-[6px] py-[2px] rounded text-[10px] text-green-600 font-[700]'>Converted</span>}
                            {roles?.includes('admin') &&
                            <AiOutlineEdit className='mt-1 ml-1'  onClick={()=>navigate('/fts_leads_assign_remark/list/edit',{state:d})} />}
                        </h6>
                    </div>))}
                    </div>}
                    </>
          
                 
                    {leads?.datas?.length === 0 &&
                      <div className='grid place-items-center mt-20  items-center justify-center'>
                      <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' /> 
                      <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                      <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
                    </div>
                    }
          </div>
            </div>
        </div>    

    </div>
  )
}

export default FTSLeadShared