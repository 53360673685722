import React, { useEffect, useState } from 'react'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import DatabaseMenu from '../../DatabaseMenu';
import { DeletePlotTypeService, GetPlotTypeService } from '../../../../services/database/databaseoptions/PlotTypeServices';
import {AiOutlineDelete, AiOutlineEdit} from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../../components/button';
import moment from 'moment';
import { DatePicker,Modal } from 'antd';
import { IconButton, } from '@mui/material'
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { DeleteAdminPropertyTypeService, DeletePropertyTypeService, GetPropertyTypeService } from '../../../../services/database/databaseoptions/PropertyTypeServices';
import { DeleteAdminConversionTypeService, DeleteConversionTypeService, GetConversionTypeService } from '../../../../services/database/databaseoptions/ConversionTypeServices copy';

function ConversionTypeList() {

  const roles = useSelector(state=>state.Auth.roles)

  const navigate = useNavigate()
  const [data,setdata] = useState({})
  const [selected_data,setselected_data] = useState({})
  const [modal,setmodal] = useState(false)
  const [page,setpage] = useState(1)
  const [step,setstep] = useState(1)
  const [search,setsearch] = useState({from_date:'',to_date:'',from_date1:'',to_date1:'',text:''})

  useEffect(()=>{
    getdata()
  },[page])


  async function getdata(){
    const response = await GetConversionTypeService(page,search?.from_date1,search?.to_date1,search?.text,step)
    setdata(response?.data)
  }

  async function resetfunc(){
    setsearch({from_date:'',to_date:'',from_date1:'',to_date1:'',text:''})
    setpage(1)
    const response = await GetConversionTypeService(1,'','','',1)
    setdata(response?.data)
  }

  async function applyfilterfunction(){
    setpage(1)
    const response = await GetConversionTypeService(1,search?.from_date1,search?.to_date1,search?.text,step)
    setdata(response?.data)

  }

  async function deletefunc(){
    const response = await DeleteConversionTypeService(selected_data?._id)
    if(response?.status === 200){
      toast.success("Conversion Type Deleted Successfully!")
      getdata()
      setmodal(!modal)
    }
  }

  async function admindeletefunc(){
    const response = await DeleteAdminConversionTypeService(selected_data?._id)
    if(response?.status === 200){
      toast.success("Conversion Type Deleted Successfully!")
      getdata()
      setmodal(!modal)
    }
  }


  return (
    <div className='overflow-hidden h-screen max-h-screen'>
        <Modal open={modal} className='absolute top-0 left-[42%]' width={300} footer={false} closable={false}>
           <div>
             <h6 className='text-[12px] font-[700]'>Delete Conversion Type Data</h6>
             <h6 className='text-[10px] bg-slate-100 p-[4px] leading-[14px]'>Are you sure want to delete the selected conversion type data once deleted will not be retrieved</h6>
             
             <h6 className='text-[12px] mt-2'>Name : <span className='font-[700]'>{selected_data?.name}</span></h6>
             <h6 className='text-[12px]'>Sort : <span className='font-[700]'>{selected_data?.sort}</span></h6>

             <div className='flex mt-2 justify-end border-t pt-2'>
               <ButtonOutlinedAutoWidth  btnName="Cancel" onClick={()=>setmodal(false)} />  
               <h6 className='w-[10px]'></h6>
               <ButtonFilledAutoWidth  btnName="Save" onClick={step == 1 ? ()=>deletefunc() : ()=>admindeletefunc()} />  
              </div>    
           </div>
        </Modal>
        
        <div  className="w-[100%] flex">
            <div className='min-w-[180px] max-w-[180px]'>
            <DatabaseMenu />
            </div>
            <div  className='px-5 py-4 w-[100%] '>
              <div className='flex items-center justify-between border-b pb-2'>
                <h6 className='font-[700] text-[14px] '>Total Conversion Type List ({data?.pagination?.total})</h6>
                <div className='flex items-center'>
                  <div className='flex items-center text-[12px] mr-2'>
                
                    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                    <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                  </div>


                  <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 

                  <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 

                
                <div className='mr-2'>
                    

                  <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
              
                </div>      

              <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
            
              <div className='ml-2'>
              <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
              </div>
                </div>
            </div>

            {/* {roles?.includes('admin') &&
            <div className='flex  border-b text-[11px]'>
              <h1 onClick={()=>setstep(1)} className={`w-[70px] cursor-pointer p-[4px] text-center ${step == 1 && 'bg-slate-600 text-white'} font-[600]`}>Active</h1>
              <h1 onClick={()=>setstep(2)} className={`w-[70px] cursor-pointer p-[4px] text-center ${step == 2 && 'bg-slate-600 text-white'} font-[600]`}>In Active</h1>
            </div>} */}
            
            {data?.datas?.length === 0 &&
              <div className='grid place-items-center mt-20  items-center justify-center'>
              <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
              <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
              <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
            </div>
            }

            <div className='grid grid-cols-6 mt-2 gap-2'>
            {data?.datas?.map((d)=>(
              <div className='text-[11px] mb-[0px] relative border p-[10px]'>
                <div className='absolute cursor-pointer flex bg-white border-b border-l right-0 top-0'>
                  <AiOutlineEdit size={18} onClick={()=>navigate('edit',{state:d})} className='p-[4px] border-r' />
                  {['admin','db_head','db_manager','database_head'].filter(f => roles?.includes(f))?.length > 0 &&
                  <AiOutlineDelete size={18} onClick={()=>{setselected_data(d);setmodal(true)}} className='p-[4px]' />}
                </div>
                <h6>Name : <span className='font-[700]'>{d?.name}</span></h6>
                <h6>Sort : <span className='font-[700]'>{d?.sort}</span> </h6>
                <h6 className='bg-slate-100 text-[11px] p-[5px]'>CreatedAt : {moment(d?.createdAt)?.format('LLL')}</h6>
              </div>))}
            </div>
          
           
            </div>

        
      


      </div>
    </div>
  )
}

export default ConversionTypeList