import React, { useEffect, useState } from 'react'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton,Tooltip } from '@mui/material'
import FTSLeadMenu from './FTSLeadMenu';
import { GetFTSLeadsSharedToUsBasedUserService } from '../../services/FTSLeadServices';
import { useNavigate } from 'react-router-dom';
import {AiOutlineEdit} from 'react-icons/ai';
import {useSelector} from 'react-redux'
import moment from 'moment';
import { ButtonFilledAutoWidth } from '../../components/button';

function FTSLeadSharedToFTS() {

  const navigate = useNavigate()
  const [page,setpage] = useState(1)
  const [leads,setleads] = useState({datas:[],pagination:{total:0,totalPages:0}})

  


  useEffect(()=>{
    getdata()
  },[page])

  async function getdata() {
    const response = await GetFTSLeadsSharedToUsBasedUserService(page)
    setleads(response?.data)
  }

  async function resetfunc() {
    
  } 

  async function addData() {
    navigate('/fts_leads/list/create',)
    
  }


  return (
    <div className='h-screen max-h-screen'>
        <div className='flex'>
            <div className={`min-w-44`}>
                <FTSLeadMenu />
            </div>
            <div  className="w-[87%] px-4 pt-4">
            <div>
              <div className='flex items-center justify-between border-b pb-2'>
                <h6 className='font-[700] text-[14px] '>Total Leads Shared To FTS ({leads?.pagination?.total})</h6>
                <div className='flex items-center'>
                  <div className='flex items-center text-[12px]'>

                    <h6 className='mr-2 font-[600]'>{page == 1 ? leads?.datas?.length > 0 ? 1 : 0 :  (page - 1) * leads?.pagination?.limit } - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
                    <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < leads?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>

                    <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>addData()} />   
                    
                  </div>



          
                </div>
              </div>
              <>
                    {leads?.datas?.length > 0 &&
                      <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
                      <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                          <h6  className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'> SL NO </h6>
                          <h6 className='sticky top-0 z-50  text-[12px] w-[190px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                          <h6 className='top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Name</h6>
                          <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
                          <h6 className='sticky top-0 z-50  text-[12px] w-[200px]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
                          <h6 className='sticky top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created By</h6>
                          <h6 className='sticky top-0 z-50  text-[12px] w-[250px] px-2 py-1 font-[600] text-slate-600 border-r'>Requirment</h6>
                          <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created On</h6>
                          <h6 className='sticky top-0 z-50  text-[12px] w-[110px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
                      </div>
                    
                      {leads?.datas?.map((d,i)=>(
                      <div  key={d?._id} className={`flex z-50 h-full border-b border-slate-200 z-0`}  >
                          <h6 className='sticky top-0 z-30 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* leads?.pagination?.limit)} </h6>
                          <h6 className='text-[12px] font-[500] w-[190px] px-2 py-1 truncate border-r  border-slate-200'>{d?.company_name}</h6>
                          <h6 className='text-[12px] font-[500] w-[150px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.contact_name}</h6>
                          <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.phone}</h6>
                          <h6 className='text-[12px] font-[500] w-[200px]   px-2 py-1 break-all border-r  border-slate-200'>{d?.email}</h6>
                          <h6 className='text-[10px] font-[500] w-[150px] px-2 py-1 truncate border-r  border-slate-200 font-[700]'>{d?.lead_owner?.name}</h6>
                          <h6 className='text-[12px] font-[500] w-[250px] px-2 py-1 truncate border-r  border-slate-200'>
                            {d?.requirment}
                          </h6>
                          <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6>
                          <h6  className='text-[12px] w-[110px]  px-2 py-1 flex'>
                        
                            <Tooltip title="Edit">
                            <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('/fts_leads/list/edit',{state:d?._id})}/></span>
                            </Tooltip>
            
                        
                          
                            
            
                          
                          </h6>
                      </div>))}
                    </div>}
              </>
              {leads?.datas?.length === 0 &&
                <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' /> 
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
              </div>
              }
            </div>
            </div>
        </div>    

    </div>
  )
}

export default FTSLeadSharedToFTS